
import React from "react";
import SimpleFormFromArray from "../../components/formElements/SimpleFormFromArray"

export default function CreateMktPopup(paramData, callback) {
    let fieldObj = null;

    let state = {
        mktName: null,
        mktType: null,
        mktRelate: null,
        optionsSelected: [],
    }
    let stats = {
        show: false,
        data: "",
        id: null,
        name: ""
    }

    if (paramData.mode === "new"){
        fieldObj = {
            fields: {
                mktName: {
                    type: 'input',
                    label: 'Název: ',
                    required: true,
                },
                mktType: {
                    type: 'enum',
                    label: 'Typ: ',
                    options: 'prospect_list_type_dom',
                    required: true,
                },
                companyContact: {
                    type: 'multienum',
                    label: 'Přidat: ',
                    options: 'mkt_report_export_mode',
                    defaultValue: 'account',
                    required: true,
                },
            },

            buttons: {
                button: {
                    label: "Vytvořit",
                    icon: "icon-addIcon",
                    onClick: (returnData) => {
                        createMkt(returnData)
                    },
                }
            }
        }
    } else {
        fieldObj = {
            fields: {
                mktRelate: {
                    type: 'relate',
                    label: 'Marketingový seznam: ',
                    module: 'ProspectLists',
                    required: true,
                },
                mpanyContact: {
                    type: 'multienum',
                    label: 'Přidat: ',
                    options: 'mkt_report_export_mode',
                    defaultValue: 'account',
                    required: true,
                },
            },

            buttons: {
                button: {
                    label: "Přidat",
                    icon: "icon-addIcon",
                    onClick: (returnData) => {
                        createMkt(returnData)
                    },
                }
            }
        }
    }

    const createMkt = (options) => {
        state = options

        let records = JSON.parse(paramData.data.records)
        this.load()
        this.rest.post("createMkt", { records: records, options: options }, returnData => {
            this.unLoad()
            this.popupHide()

            if (returnData.status){
                stats.id = returnData.message.data.recordId
                stats.name = returnData.message.data.recordName
                stats.data = returnData.message.data.stats
                stats.show = true;
            }
            summaryPopup()
        });
    }

    const summaryPopup = () => {
        let popup = (
            <>
                <div className="reportWindowHeader">
                    <div className="reportWindowHeaderText">{paramData.mode === "new" ? "ZALOŽIT MKT ZÁZNAMY" : "PŘIDAT DO MKT ZÁZNAMY"}</div>
                </div>
                <div className="createMktPopup">
                    <div className="mktPopupGrid">
                        <p>Marketingový seznam: </p>
                        <a href={'#detail/ProspectLists/' + stats.id} target='_blank' >{stats.name}</a>
                        {(stats.data?.length > 0 
                            && (state.optionsSelected.length === 1 && state.optionsSelected[0] === "account") 
                            || state.optionsSelected.length >= 2) &&
                            <>
                                <p className="undeline">Firmy: </p>
                                <p className="undeline">&nbsp;</p>
                            </>
                        }
                        {stats.data?.length > 0 && state.optionsSelected.length === 1 
                            && state.optionsSelected[0] === "account_contacts" &&
                            <>
                                <p className="undeline">Kontakty firem: </p>
                                <p className="undeline">&nbsp;</p>
                            </>
                        }

                        {stats.data.map((item) => <>
                            {!item.separator ?
                                <>
                                    <a href={'#detail/Accounts/' + item.id} target='_blank' >{item.name}</a>
                                    <p>přidáno záznamů: {item.record_count}</p> 
                                </> : 
                                <>
                                    <p className="undeline">Kontakty firem: </p>
                                    <p className="undeline">&nbsp;</p>
                                </>
                            }
                        </>)}
                        {stats.data.length === 0 && <p>Nebyly přidány žádné záznamy.</p>}
                    </div>
                </div>
            </>
        );
        this.popup(popup);
    }
    
    let popup = (
        <>
            <div className="reportWindowHeader">
                <div className="reportWindowHeaderText">{paramData.mode === "new" ? "ZALOŽIT MKT" : "PŘIDAT DO MKT"}</div>
            </div>
            <div className="createMktPopup">
                <SimpleFormFromArray fields={fieldObj.fields} buttons={fieldObj.buttons} />
            </div>
        </>
    );
    this.popup(popup);

}

