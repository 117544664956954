import detailDefault from "../../detailDefault";
export default class detailacm_salesplan extends detailDefault{
    load(sAction,data){
        sAction.dataSet(data.prefix + "/fields/whole_year/def/visible", false);
        sAction.dataSet(data.prefix + "/fields/whole_year/def/tooltipClass", "WholeYearToolTip");
    }
    update(sAction,data){
        if(data.field == "month"){
            const record = sAction.dataGet(data.prefix+"/id");
            if(record){
                return;
            }
            if(data.value === "1"){
                sAction.dataSet(data.prefix + "/fields/whole_year/def/visible", true);
            }
            else{
                sAction.dataSet(data.prefix + "/fields/whole_year/def/visible", false);
                sAction.dataSet(data.prefix+"/fields/whole_year/value", 0);
                sAction.dataSet(data.prefix+"/changes/fields/whole_year", 0);
                sAction.dataSet(data.prefix + "/fields/whole_year/def/tooltip", null);
            }
        }
        if(data.field == "whole_year"){
            if(data.value == 1){
                sAction.dataSet(data.prefix + "/fields/whole_year/def/tooltip", sAction.translate("LBL_WHOLE_YEAR_WARNING", "acm_salesplan"));
            }
            else{
                sAction.dataSet(data.prefix + "/fields/whole_year/def/tooltip", null);
            }
        }
    }
}