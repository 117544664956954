import React from 'react'
import TickBox from "ROOT/src/components/formElements/TickBox";

export default function Pricing(props) {
    let data = props.data

    if(Object.keys(data).length === 0 || data.size === 0 || data.length === 0){
        return null
    }

    const getDiscountType = (discountType) => {
        if(discountType === "0"){
            return 'ABS'
        } else {
            return '%'
        }
    }

    console.log(data)

    return (
        <div className="pricingMapWrapper">
            <div className="pricingContainer">
                <div className="pricingHeader">
                    <div className="pricingHeaderContent">
                        <span className="calAdiconBoxTitle">Ceník</span>
                    </div>
                </div>
                <div className="pricingContent">
                    <table className="pricingTable">
                        <tbody>
                        <tr className="pricingLine">
                            <td className="pricingName">Název ceníku</td>
                            <td className="pricingValue">{data.price_list_name}</td>
                        </tr>
                        <tr className="pricingLine">
                            <td className="pricingName">Kód ceníku</td>
                            <td className="pricingValue">{data.price_list_code}</td>
                        </tr>
                        <tr className="pricingLine">
                            <td className="pricingName">Procentuální sleva</td>
                            <td className="pricingValue">{parseFloat(data.list_percent_discount)}</td>
                        </tr>
                        <tr className="pricingLine">
                            <td className="pricingName">Typ slevy</td>
                            <td className="pricingValue">{getDiscountType(data.item_discount_type)}</td>
                        </tr>
                        <tr className="pricingLine">
                            <td className="pricingName">Výsledná cena</td>
                            <td className="pricingValue">{parseFloat(data.price_after_discount)}</td>
                        </tr>
                        <tr className="pricingLine">
                            <td className="pricingName">Nepodléhá slevě dokladu</td>
                            <td className="pricingValue"><TickBox checked={!!parseInt(data.do_not_discount)} /></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
