import React from "react";
import PureComponent from "../../pure";

import sAction from 'sAction';

import CustomLine from "./CustomLine";
import Button from "../../formElements/Button";
import CustomLinesHeader from "./CustomLinesHeader";
import CustomLinesTotal from "./CustomLinesTotal";

export default class CustomDetailLines extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {index: 0, customLines: []};
        this.keyArray = {};
    }

    addCustomLine(def, moduleData) {
        var defaultData = {};
        def.get('fields').toJS().forEachObject((field) => {
            if (def.get('default') && def.get('default').get(field)) {
                defaultData[field] = def.get('default').get(field);
            } else if (moduleData.get(field) && moduleData.get(field).get && moduleData.get(field).get('default')) {
                defaultData[field] = moduleData.get(field).get('default')
            }
        });
        def.get('default').toJS().forEachObject((value, column) => {
            defaultData[column] = value;
        });
        sAction.dsClear();
        sAction.dsAdd("set", this.props.way + '/customData/customLines/lines/' + this.state.index, defaultData);
        sAction.dsAdd("set", this.props.way + '/changes/forceChange', true);
        sAction.dsProcess();
        this.setState({index: ++this.state.index,});
    }

    getTotal(moduleData, way, lines) {
        return (
            <CustomLinesTotal
                def={this.props.data.get('def')}
                way={way}
                lines={lines}
                moduleData={moduleData}
                info={this.props.data.get('info')}
            />
        );
    }

    moveElement(key, direction) {
        let way = this.props.way + '/customData/customLines/lines';

        let list = sAction.dataGet(way);
        const cnt = list.size;

        sAction.dsClear();

        if (direction === 'up') {
            if (key === 0 || key === '0') {
                return;
            }

            let pomProd = list.get(this.keyArray[key]);
            list = list.delete(this.keyArray[key]);
            list = list.insert(this.keyArray[key - 1], pomProd);

            sAction.dsAdd("set", way, list);
        } else if (direction === 'down') {
            if ((key + 1) >= cnt) {
                return;
            }

            let pomProd = list.get(this.keyArray[key]);
            list = list.delete(this.keyArray[key]);
            list = list.insert(this.keyArray[key + 1], pomProd);

            sAction.dsAdd("set", way, list);
        }

        sAction.dsAdd("set", "view/changes/forceChange", true);
        sAction.dsProcess();
    }

    openListView() {
        const module = this.props.data.get("info").get("module")
        const data = {
            module: module,
            selectedActive: false,
        };

        const relFieldName = this.props.data.get("info").get("module_rel_field")
        data.defaultFilter = [
            {
                module: module,
                operandType: "relate",
                parentId: this.props.id,
                relName: this.props.data.get("moduleData").get(relFieldName).get("relationship"),
                relationship: [this.props.data.get("moduleData").get(relFieldName).get("relationship")],
                type: "eq",
            },
        ]

        sAction.openRelatePopup(data, (returnData) => {
            window.open(`#detail/${module}/${returnData.id}`, '_blank')
        });
    }

    render() {
        const info = this.props.data.get('info');
        const way = this.props.way;
        const lines = this.props.data.get('lines');
        const def = this.props.data.get('def');
        const acl = this.props.acl;
        const readonly = this.props.readonly;
        const moduleData = this.props.data.get('moduleData');
        const timeline = this.props.data.get('timeline');
        const allLinesCount = this.props.data?.get("linesCount")
        const recordsLimit = this.props.data.get('info').get("recordsLimit")

        var index = 0;
        var customLines = [];
        if (lines) {
            lines.forEach((line, key) => {
                if (line.get('deleted') !== 1 && line.get('deleted') !== '1') {
                    this.keyArray[index] = key;
                    customLines.push(
                        <CustomLine
                            data={line}
                            def={def}
                            key={index}
                            lineKey={index}
                            moduleData={moduleData}
                            showError={this.props.data.get('showError')}
                            way={way}
                            canEdit={acl.get('edit')}
                            timeline={timeline}
                            info={info}
                            readonly={readonly}
                            moveElement={(key, direction) => {
                                this.moveElement(key, direction)
                            }}
                        />
                    );
                }
                index++;
            });
        }
        this.state.index = index;

        var totalLine = null;
        if (def.get('total') && def.get('total').toJS().length > 0) {
            totalLine = this.getTotal(moduleData, way, lines);
        }

        let headerPom = (
            <CustomLinesHeader
                def={def}
                moduleData={moduleData}
                info={info}
            />
        );

        return (
            <div className='detailCard detailViewTabContent detailQuoteProdComponent'>
                <div className='subpanelsContainer' data-way={this.props.way + '/customData/customLines'}>
                    <div className="qGroupHeaderContainer header">
                        <div className="title">
                            <span className={"detailViewHeaderIcon icon-" + info.get('module')}/>
                            <span className="detailViewName">
                                {sAction.translate('LBL_MODULE_NAME', info.get('module'))}
                            </span>
                        </div>
                    </div>
                    <div className="detailView">
                        <div className="detailViewTabContent customLines">
                            {headerPom}
                            {customLines}
                            {totalLine}
                            {acl.get('edit') && (
                                <>
                                    <div className='customLinesRowContainer flexRight viewActionPanelButtons'>
                                        <div style={{display: "flex", width: "auto"}}>
                                            {recordsLimit && allLinesCount > recordsLimit ? (
                                                <Button className="acmButton" onClick={() => this.openListView(info)}>
                                                    <div className={"actionPanelButtonIcon icon-listview"}/>
                                                    {sAction.translate('LBL_SHOW_ALL_CUSTOM_LINES')}
                                                </Button>
                                            ) : null}
                                            {!readonly ? (
                                                <Button className="acmButton"
                                                        onClick={() => this.addCustomLine(def, moduleData)}>
                                                    <div className={"actionPanelButtonIcon icon-addIcon"}/>
                                                    {sAction.translate('LBL_ADD_CUSTOM_LINE')}
                                                </Button>
                                            ) : null}
                                        </div>
                                    </div>
                                </>
                            )}
                            {recordsLimit && allLinesCount > recordsLimit ? (
                                <div className='customLinesRowContainer flexRight linesCountContainer'>
                                    <div>{`${sAction.translate("LBL_DISPLAYED")} ${lines.size} ${sAction.translate("LBL_DISPLAYED_FROM")} ${allLinesCount}`}</div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}