import React from 'react';
import moment from 'moment';

import PureComponent from '../pure';
import Button from '../formElements/Button';
import PropTypes from 'prop-types';
// import ButtonFloat from "../formElements/Button"
// import ButtonCircle from "../formElements/ButtonFloat"
// import CalSettingsForm from './calSettingsForm'

import MoreOptions from '../formElements/MoreOptions';

import sAction from 'sAction';

class CalHeader extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            startDate: moment(),
            cssSelector: this.props.calCssSelector,
            openPicker: false,
        };
    }

    fncView(view) {
        sAction.dataSet('calendar/calViewType', view);
    }

    handleCalToggleSharedView = (e) => {
        sAction.calToggleSharedView();
    };

    handleCalSettings = (e) => {
        sAction.popup('calSettingsForm', {setting: this.props.setting});
    };

    handleToggleFullScreen = (e) => {
        this.props.toggleFullScreen();
    };

    render() {
        const views = sAction.app_strings.calendar_initial_view;
        const viewsBtns = [];

        for (const view in views) {
            if (view) {
                let icon = '';
                switch (view) {
                    case 'dayGridMonth':
                        icon = <div className={'icon-calendar-month calHeaderIcon'}></div>;
                        break;
                    case 'timeGridWeek':
                        icon = <div className={'icon-calendar-week calHeaderIcon'}></div>;
                        break;
                    case 'timeGridDay':
                        icon = <div className={'icon-calendar-day calHeaderIcon'}></div>;
                        break;
                    case 'listACM':
                        icon = <div className={'icon-calendar-agenda calHeaderIcon'}></div>;
                        break;
                }

                const pom = view;

                const buttonClass = 'actionPanelButton calViewButton hoverLightBlue';

                // if (view == curView) {
                //   buttonClass += " activeView"
                // }

                if (view == 'agendaDay') {
                    <i className="fas fa-clipboard-list"></i>;
                }
                viewsBtns.push(
                    <Button
                        className={buttonClass}
                        key={view}
                        onClick={() => {
                            this.fncView(pom);
                        }}>{icon}{views[view]}
                    </Button>,
                );
                viewsBtns.push(<div key={`viewPanelButtonsDelimiter${view}`} className="viewPanelButtonsDelimiter"></div>);
            }
        }

        const setting = this.props.setting;

        let sharedLbl = sAction.translate('LBL_FC_SHARED', 'Calendar');
        if (this.props.sharedView) {
            sharedLbl = sAction.translate('LBL_FC_NOTSHARED', 'Calendar');
        }

        if (sAction.isMobile()) {
            return (<>
                <div key={'calHeader'}>
                    <div className="viewActionPanel calPanel">
                        <div key={'acmCalHeaderLeftMenu'} className="acmCalHeaderLeftMenu">
                            {viewsBtns}
                        </div>

                        <MoreOptions
                            options={[
                                {
                                    label: sharedLbl,
                                    icon: 'icon-CalSharedIcon',
                                    value: 'handleCalToggleSharedView',
                                },
                                {
                                    label: sAction.translate('LBL_SETTINGS', 'Calendar'),
                                    icon: 'icon-CalSettingsIcon',
                                    value: 'handleCalSettings',
                                },
                                {
                                    label: sAction.translate('LBL_EMAIL_SETTINGS_FULL_SCREEN'),
                                    icon: 'iconfas-maximize',
                                    value: 'handleToggleFullScreen',
                                },
                            ]}
                            onClick={(e, val) => this[val](e)}
                        >
                            <div className="icon-more" />
                        </MoreOptions>

                    </div>
                </div>
            </>);
        }

        return (
            <>
                <div key={'calHeader'}>
                    <div className="viewActionPanel calPanel">
                        <div key={'acmCalHeaderLeftMenu'} className="acmCalHeaderLeftMenu">
                            {/* <div className="icon-calendar calHeaderIcon"></div>*/}
                            {viewsBtns}
                            {/* <div className="viewPanelButtonsDelimiter"></div>*/}
                            <Button onClick={() => this.handleCalToggleSharedView()} className="calViewButton">
                                <div className="icon-CalSharedIcon calHeaderIcon"></div>
                                {sharedLbl}</Button>
                            <div key={'empty'}>
                            </div>
                        </div>
                        <div key={'acmCalHeaderCenterMenu'} className="acmCalHeaderCenterMenu">

                        </div>
                        <div key={'acmCalHeaderRightMenu'} className="acmCalHeaderRightMenu">
                            <Button className="calViewButton hoverGreen acmButton"
                                onClick={() => this.handleCalSettings()}>
                                <div className={'calHeaderIcon icon-CalSettingsIcon'}></div>
                                {sAction.translate('LBL_SETTINGS', 'Calendar')}
                            </Button>
                        </div>
                        <Button className="calViewButton" onClick={() => this.handleToggleFullScreen()}>
                            <span
                                id="fullScreenBtn"
                                className={'fullScreenIcon' + (this.props.fullScreen ? ' iconfas-minimize' : ' iconfas-maximize')}
                            ></span>
                        </Button>
                    </div>
                </div>
            </>
        );
    }
}

export default CalHeader;

CalHeader.propTypes = {
    setting: PropTypes.object,
    toggleFullScreen: PropTypes.func,
    sharedView: PropTypes.bool,
    calCssSelector: PropTypes.string,
    fullScreen: PropTypes.bool,
};
