export default function checkDIC(sendData) {
  if (!sendData.dic) {
    this.error(this.translate("LBL_CHECK_DIC_MSGERR_NODIC", "Accounts"), this.translate("LBL_CHECK_DIC_MSGERR_ENTERDIC", "Accounts"));
    return;
  }

  this.load();
  this.rest.post("detailapi/checkdic", sendData, data => {
    this.unLoad();

    let html = "";

    let address = (data.street || "") + " " + (data.city || "") + " " + (data.zipcode || "");

    if (data.state === "valid") {
      html = `<b>${this.translate("LBL_CHECK_DIC_MSG_VALID_REC", "Accounts")}</b>
        <br /><br />
        ${this.translate("LBL_CHECK_DIC_MSG_LIST_DATA", "Accounts")}:
        <br /><br />
        ${this.translate("LBL_ACCOUNT", "Accounts")} ${data.firma}
        <br />
        ${this.translate("LBL_ISIR_ADRESA", "Accounts")}: ${address}`;
    } else {
      html = this.translate("LBL_CHECK_DIC_MSG_NOTVALID_REC", "Accounts");
    }

    this.alert(html, this.translate("LBL_CHECK_DIC_MSG_TITLE", "Accounts"));
  });
}
