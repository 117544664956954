/**
 * Pokud zmenim slevu nebo kam aplikovat slevu potrebuju prepocitat slevu
 * @param way pr. "view/customData/productData/groups/0"
 * @param newValue
 * @param discountType pole na ktere budeme aplikovat slevu price_after_add_discount/discount_amount
 * @param lines radky skupiny nabidky
 */
export default function changeGroupDiscount(way, newValue, discountType, lines){
    this.dsClear()
    lines.forEach((line, lineIndex) => {
        this.dsAdd("set", `${way}/lines/${lineIndex}/${discountType}`, newValue);

        if (discountType === "price_after_add_discount") {
            this.dsAdd("set", `${way}/lines/${lineIndex}/discount_amount`, 0);
        } else if (discountType === "discount_amount") {
            let addVal
            if (parseInt(line.discount_select)) {
                let discount = (this.parseNumber(line.price_after_discount) / 100) * newValue;
                let tmpTotalPrice = this.parseNumber(line.price_after_discount) - discount;
                addVal = 100 * Math.abs((tmpTotalPrice - line.list_price) / line.list_price)
            } else {
                addVal = line.list_price - (line.price_after_discount - newValue)
            }
            this.dsAdd("set", `${way}/lines/${lineIndex}/price_after_add_discount`, addVal);
        }
    });
    this.dsProcess()
}