import React, { Component } from 'react'
import PureComponent from '../../pure'

import  sAction  from "sAction";

import confProd from "./productLineConfig";

class QGroupHeader extends PureComponent{

    render(){
        const conf = confProd.fields;
        var renderComp = [];
        conf.forEach( function(field){ 
            if(!field.visible){
                return;
            }
            renderComp.push(
                <div 
                    key={"header_"+field.name} 
                    style={{width: field.width+"%"}}>
                    {sAction.translate(field.lbl, field.lblMod)}
                </div>
            )
        });

        let width = "90px";
        if(this.props.readonly){
            width = "70px";
        }

        return (
            <div className="qProductLine qProductGroupHeader">
                <div style={{width: width}}></div>       
                {renderComp}
            </div>);
    }
}
export default QGroupHeader