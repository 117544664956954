import React from "react";
import PureComponent from "../pure";
import  sAction  from "sAction";
import TabSelect from '../formElements/TabSelect';

export default class ImportRelateHeaderSelect extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            module: this.props.relateDef?.module,
        }
        this.tabRef = React.createRef();

        this.setOptions();
    }
    
    setOptions() {
        sAction.load();
        let relateDef = this.props.relateDef;
        sAction.rest.get(`defs/${relateDef?.module}`, returnData => {
            sAction.unLoad();
            let options = [];
            returnData.forEachObject((def, value) => {
                if (!['relate', 'link'].includes(def.type)) {
                    options.push({
                        value: value,
                        label: sAction.translate(def.vname, relateDef?.module),
                    });
                }
            });
            this.setState({options: options, module: this.props.relateDef?.module, })
            this.tabRef.current.onOpen(null);
        });
    }

    onChange(item) {
        sAction.dsClear();
        sAction.dsAdd('set', `${this.props.way}/${this.props.index}/relatedField`, {value: item.value, label: item.label, module: this.props.relateDef?.module});
        sAction.dsProcess();
    }

    render() {
        return (
            <TabSelect
                containerClassName={this.props.containerClassName}
                search={true}
                open={this.state.isLoaded}
                numOfTabSelect={this.props.index}
                options={{Pole: (this.state.options || [])}}
                onChange={e => this.onChange(e)}
                container={"importView"}
                label={''}
                clearLabel={false}
                labelStyle={this.props.labelStyle}
                defaultValue={''}
                containerStyle={{
                    maxWidth: '50%',
                    display: 'inline-flex'
                }}
                change={this.change}
                ref={this.tabRef}
            />
        );
    }
}
