import React from "react";

import InputField from "../inputFieldClass";
import Select from "../../formElements/Select";
import  sAction  from "sAction";

export default class Enum extends InputField {
  componentDidMount() {

  }

  check(value) {
    var required = this.props.data.def.get("required");
    if (required == undefined) {
      required = false;
    }
    var ok = true;
    if (required === true && value === "") {
      ok = false;
      this.setState({ ok });
    }
    return ok;
  }
  cancel(e, path) {
    if (path) {
      sAction.dataSet(path + "/actEdit", false);
    }
    if((e.target.role !== 'option' || e.target.className.includes('Mui-selected')) && this.props.onBlur) {
      this.props.onBlur(this.props.data.value);
    }
  }
  save(e,param = {}) {
    const value = e.target.value;

    if (this.check(value)) {
      if (this.props.way === 'report' && this.props.onBlur) {
        this.props.onBlur(value)
        return
      }
      const data = {
        way: this.props.way,
        vname: this.props.data.def.get("vname"),
        name: this.props.data.name,
        value,
        ...param
      };

      if(e.keyCode == 13){
        data["cancelEdit"] = false;
      }

      this.saveField(data);
    }
  }
  getOptions(value){
    return sAction.app_strings[value];
  }
  render() {
    const data = this.props.data;
    let path = this.props.way + "/rows/" + this.props.rowIndex + "/records/" + this.props.recordIndex;
    // console.log(this.props)
    if (this.props.rowIndex === undefined || this.props.recordIndex === undefined) {
      path = this.props.way;
    }

    var optionsToSelect = [];
    let colors = null;
    if(data.def.get('customOptions')) {
      optionsToSelect = data.def.get('customOptions').toJS();
    } else {
      const options = this.getOptions(data.def.get("options"));
      if (data.def.get("colors")) {
        colors = sAction.app_strings[data.def.get("colors")];
      }

      for (var key in options) {
        if (options.hasOwnProperty(key)) {
          var value = options[key];
          optionsToSelect.push({ value: key, label: value });
        }
      }
    }

    const forceOpen = data.def.get("forceOpen") || this.props.listField || this.forceOpen;
    return (
      <Select
        options={optionsToSelect}
        defaultValue={data.value}
        onKeyDown={e => this.onKeyDown(e,null,false)}
        open={forceOpen === true ? forceOpen : false}
        autoFocus={forceOpen !== true ? true : forceOpen}
        onChange={e => this.save(e)}
        onClose={(e) => this.cancel(e, path)}
        colors={colors}
      />
    );
  }
}
