/**
 * otestuje psč, pokud je validní, propíše město, okres a kraj do adresy,
 * v případě více obcí, částí obce se stejným PSČ zobrazí chybu
 * @param {string} psc      psč, které má být vyhledáno
 * @param {string} prefix   umístění ve storu, pro které se hodnoty ukládají
 * @param {string} way      cesta ve storu k poli ze kterého je funkce volána
 */
export default function checkPSC(psc, prefix = 'view', way = 'view/fields/address_postalcode') {
    const fieldPrefix = way.match(/([a-z_]*)address_postalcode$/)[1];
    psc = psc.replace(/[^0-9]+/g, '');

    this.rest.fetchData('detailapi/searchpsc/' + psc, 'GET').then((returnData) => {
        if (returnData['data'].length > 1) {
            // pokud je více obcí u jednoho psč, uživatel musí vybrat pomocí autocomplete
            this.dataSet(prefix + '/canSave', false);
            this.dataSet(way + '/def/error', this.translate('LBL_ERROR_CHOOSE_PSC'));
            this.unLoad();
        } else {
            this.dataSet(way + '/actEdit', false);
            this.dataSet(way + '/def/error', false);

            const fields = [];
            returnData['data'][0].forEachObject((val, key) => {
                fields[fieldPrefix + key] = (key === 'address_postalcode')? `${val.slice(0, 3)} ${val.slice(3)}` : val;
            });

            this.updateFields(fields, prefix);
        }
    }).catch((error) => {
        this.dataSet(prefix + '/canSave', false);
        this.dataSet(way + '/def/error', error['text']);
        this.unLoad();
    });
}
