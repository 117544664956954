export default function deleteSubpanelRels(params){
    const subpanel = this.dataGet(params.prefix+"/..")
    const parentData = this.dataGet(params.prefix+"/../../..");
    var recModule = subpanel.def.getIn(["rel","relName"]);

    var relid = [];
    params.relid.forEach((record) => {
        relid.push(record.record)
    })
    params["relid"] = relid;

    params["relname"] = recModule
    params["id"] = parentData.id
    params["module"] = parentData.module
    params["deleted"] = 1
    this.load();
    this.rest.post("rels",params,returnData => {
        if(returnData.status == "ok"){
            /*const subData = {
                parentId : parentData.id,
                parentModule: parentData.module,
                module: subpanel.module,
                relName: params.relname,
                prefix: params.prefix.split("/")[0],
                way:params.prefix,
            }
            console.log(subData);
            this.reloadSubpanel(subData);*/
            this.route();
        }
    })
}