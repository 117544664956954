import React from 'react'
import PureComponent from '../../pure'
import sAction from "sAction";
import composeEmail from "ROOT/src/components/detailViewFields/Email/composeEmail";

class InvCandidateRow extends PureComponent {

    addInvitee(data, type, delInd) {
        let relPrefix = this.props.prefix + "/relatedInv/" + type
        let searchPrefix = this.props.prefix + "/searchInv/" + type
        data.email = data.email_address;
        sAction.dsClear();
        sAction.dsAdd("add", relPrefix, data);
        sAction.dsProcess();
        sAction.updateDetailCustom("delItem", { way: searchPrefix, index: delInd });
    }

    render() {
        const data = this.props.data;
        let icon = null;
        const ind = this.props.ind;
        const type = this.props.type;
        let name = <a href={`#detail/${type}/${data.id}`}>{data.name}</a>
        const isPopupOpen = sAction.getNewPopupId();
        let openRecordIcon = <div onClick={() => sAction.rightPanelDetail(type, data.id, {})} className="icon-eye listViewEyeIcon" />;
        if (isPopupOpen) {
            name = <a target={"_blank"} href={`#detail/${type}/${data.id}`}>{data.name}</a>
            openRecordIcon = <a href={`#detail/${type}/${data.id}`} target={'_blank'}><div className={"icon-newTab listViewEyeIcon"} /></a>
        }

        switch (type) {
            case "Users":
                icon = <div title={sAction.translate("LBL_AVATAR")} className="icon-avatar calModuleIcon" />
                break;
            case "Contacts":
                icon = <div title={sAction.translate("LBL_CONTACT_NAME")} className="icon-Contacts calModuleIcon" />
                break;
            case "Leads":
                icon = <div title={sAction.translate("LBL_LEAD")} className="icon-Leads calModuleIcon" />
                break;
            case "parentAccount":
                icon = <div title={sAction.translate("LBL_LEAD")} className="icon-Accounts calModuleIcon" />
                break;
        }

        return (
            <tr>
                <td className="threeDotsHelper">
                    {icon}
                </td>
                <td className="threeDotsHelper">{sAction.app_strings["dom_meeting_accept_status"][data.accept_status]}</td>
                <td title={data.name}>
                    {!this.props.prefix.includes('rightPanel') && openRecordIcon}
                    {name}
                </td>
                <td className="threeDotsHelper">
                    {data.title}
                </td>
                <td onClick={composeEmail(this.props.prefix)} className="threeDotsHelper">
                    <a>{data.email_address}</a>
                </td>
                <td className="threeDotsHelper">
                    {data.phone_work}
                    {data.phone_work != "" &&
                        <a className="invitesTableCallTo" href={"callto:" + data.phone_work} tabIndex="0" title={sAction.translate("LBL_CALL_TO")}><div style={{ display: "inline" }} className="icon-Calls"></div></a>
                    }
                </td>
                <td className="threeDotsHelper">
                    {data.phone_mobile}
                    {data.phone_mobile != "" &&
                        <a className="invitesTableCallTo" href={"callto:" + data.phone_mobile} tabIndex="0" title={sAction.translate("LBL_CALL_TO")}><div style={{ display: "inline" }} className="icon-Calls"></div></a>
                    }
                </td>
                <td colSpan="2">
                    <a onClick={() => this.addInvitee(data, type, ind)}><div title={sAction.translate("LBL_NEW_RECORD", "Contacts")} className="icon-AddIcon invAddIcon"></div></a>
                </td>
            </tr>
        )
    }
}
export default InvCandidateRow