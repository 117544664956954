import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {ClickAwayListener} from '@material-ui/core';
import sAction from 'sAction';
import ListViewSearchHistoryItem from './ListViewSearchHistoryItem';

const ListViewSearchHistory = (props) => {
    const setFilter = (filter) => {
        if (Object.prototype.hasOwnProperty.call(filter, 'operands')) {
            filter.operands.forEach((searchTerm) => {
                setFilter(searchTerm);
            });
        } else {
            sAction.addSearchContext(filter.value, props.module, props.prefix);
        }
    };

    const [searchHistory, setSearchHistory] = useState([]);
    useEffect(() => {
        setSearchHistory(sAction.getStorage('searchHistory/' + props.module));
    }, [props]);

    const clickHandler = (event) => {
        const view = props.prefix.split('/').slice(-1)[0];
        const index = event.target.getAttribute('value');

        sAction.clearSearchContext(props.module, props.prefix);
        setFilter(searchHistory[index].filter);
        const searchData = sAction.getStorage('searchContext')[`${props.module}_${view}`].searchData;
        sAction.listGlobalSearch(searchData, props.prefix);

        props.close();
    };

    const resultList = [];
    if (searchHistory?.length > 0) {
        const lineClass = 'searchHistoryLine';
        searchHistory.map((item, i) => {
            resultList.push(
                <ListViewSearchHistoryItem
                    tabIndex={i}
                    key={i}
                    className={lineClass}
                    filter={item.filter}
                    onClick={clickHandler} />,
            );
        });
        if (searchHistory.length % 20 === 0) {
            const loadMoreHandler = () => {
                sAction.load();
                sAction.loadSearchHistory(props.module, 20, true, () => {
                    setSearchHistory(sAction.getStorage('searchHistory/' + props.module));
                    sAction.unLoad();
                });
            };
            resultList.push(
                <div key='more'
                    className={lineClass}
                    onClick={loadMoreHandler}
                >
                    <div className="icon-history" />
                    {sAction.translate('LBL_LIST_SEARCH_HISTORY_LOAD_MORE')}
                </div>,
            );
        }
    } else {
        resultList.push(
            <div key='none'><i>{sAction.translate('LBL_LIST_SEARCH_HISTORY_EMPTY')}</i></div>,
        );
    }

    // potlačení komponenty ClickAwayListener při otevření
    const [awayListener, setAwayListener] = useState(false);

    const clickAwayHandler = () => {
        if (awayListener) {
            props.close();
        }
    };

    useEffect(() => {
        setTimeout(() => {
            setAwayListener(true);
        }, 30);
    }, []);

    return (
        <ClickAwayListener onClickAway={clickAwayHandler} >
            <div className="searchHistory">
                {resultList}
            </div>
        </ClickAwayListener>
    );
};

ListViewSearchHistory.propTypes = {
    module: PropTypes.string.isRequired,
    prefix: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
};

export default ListViewSearchHistory;
