export default function activateListViewFiltering(prefix) {
    const src = prefix+'/actFiltering';
    const value = this.dataGet(src);

    const defaultFilter = this.dataGet(prefix+'/defaultFilter');

    this.dsClear();
    this.dsAdd('set', src, !value);
    this.dsAdd('set', prefix+'/filter', []);
    if (value == true) {
        this.dsAdd('set', prefix+'/openFilterData', null);

        let canDeleteDefault = true;
        defaultFilter.forEach((it) => {
            if (it.canDelete !== true) {
                canDeleteDefault = false;
            }
        });

        if (canDeleteDefault) {
            this.dsAdd('set', prefix+'/defaultFilter', []);
        }
    }
    this.dsProcess();
    const list = this.getViewName() === 'import' ? this.setImportListFilter({prefix: prefix}) : null;
    this.reloadList(0, 1, prefix, null, null, list);
}
