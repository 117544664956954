import React from 'react';
import PureComponent from '../pure';
import Report from './Report';
import sAction from 'sAction';
import Loader from '../../components/loader';
import ReactReportContainer from 'ROOT/src/components/CustomReport/ReactReportContainer';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

export default class ReportContainer extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            reactReport: false,
            reportLoaded: false,
        };
    }

    componentDidMount() {
        const id = window.location.hash.split('/')[1];
        sAction.rest.fetchData('isReactReport', 'POST', id).then(({data}) => {
            if (data.type === 'react') {
                this.setState({
                    reactReport: true,
                    reportLoaded: true,
                    reportName: data.reportName,
                    reportLabel: data.label,
                });
                return;
            }

            sAction.loadCustomReport(id, this.props.way);
        });
    }

    render() {
        const data = this.props.data;
        const way = this.props.way;
        const report = this.state.reactReport || this.props.data?.get('reactReport') ?
            <ReactReportContainer name={this.state.reportName} label={this.state.reportLabel} way={way}/> :
            <Report data={data} way={way}/>;

        return (
            <>
                {this.state.reportLoaded || this.props.data?.get('reportLoaded') ? report : <Loader/>}
            </>
        );
    }
}

ReportContainer.propTypes = {
    data: ImmutablePropTypes.map,
    way: PropTypes.string,
};
