import React from "react";
import PureComponent from "../pure";
import sAction from "sAction";
import Loader from "../loader";
import propTypes from "prop-types";
import DropdownAnimation from "../animation/DropdownAnimation";


/**
 * Viz. wiki a propTypes
 */
export default class Autocomplete extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            resultData: null,
            resultArrowIndex: -1,
            loaded: false,
            open: true,
        };
        this.searchInterval = null;
        this.containerRef = React.createRef();
        this.translate = null;
    }

    keyUpHandler = (keyCode) =>{
        switch(keyCode){
            case 13: // enter
                let item = this.returnSelectedItem();
                this.props.autocompleteReturnItem(item);
            break;
            case 40:    //sipky dolu, nahoru
            case 38:
                this.changeArrowIndex(keyCode);
            break;
        }
    }

    // rozcestnik vyhledani dat
    search() {
        this.setState({loaded: false});
        switch (this.props.searchMethod) {
            case 'email':
                this.searchEmail();
                break;
            case 'relate':
                this.searchRelate();
                break;
            case 'formRelate':
                this.searchFormRelate();
                break;
            case 'parent':
                this.searchParent();
                break;
            case 'formParent':
                this.searchFormParent();
                break;
            case 'PSC':
                this.searchPSC();
                break;
            default:
                console.warn('zadejte zpusob hledani Autocomplete.js'); // debug purpose
                return;
        }
    }

    //vyhledani email dat
    searchEmail(){
        let sendData = {
            searchText: this.props.searchText,
            limit: 100,
        }
        sAction.rest.post("autocompleteEmail", sendData, (returnData) => {
            if(returnData["status"] && this._ismounted){
                this.setState({
                    resultData:  returnData["message"]["data"],
                    loaded: true,
                    resultArrowIndex: -1,
                });
            } else {
                this.setState({
                    resultData:  [],
                    loaded: true,
                    resultArrowIndex: -1,
                });
            }
        });
    }

    storeTranslates = (data) => {
        if(this.translate !== null){
            return;
        }
        const module = this.props.module;

        this.translate = [];
        data["translate"]?.forEachObject((label, vardef) => {
            let text = sAction.translate(label,module);
            if(text && text.endsWith(":")){
                text = text.slice(0, -1)   //odstraneni : v prekladu
            }
            this.translate[vardef] = text;
        });
    }

    searchRelate(){
        this.setState({ loaded: false})
        const value = this.props.searchText;
        const module = this.props.module;
        let getFields = ["name","id"];
        let searchFields = ["name"];

        if (value !== "" || value !== null) {
            const searchData = {
                fields: searchFields,
                getFields: getFields,
                value,
                module,
                autocomplete: true,
            };
            const defaultFilter = this.props.defaultFilter;
            if (defaultFilter) {
                searchData.defaultFilter = defaultFilter.toJS();
            }

            sAction.quickSearch(searchData, (receivedData) => {
                if(this._ismounted){
                    this.storeTranslates(receivedData)
                    this.setState({
                        resultArrowIndex: -1,
                        resultData: receivedData,
                        resultOpen: true,
                        loaded: true,
                    });
                } else {
                    this.setState({
                        resultData:  [],
                        loaded: true,
                        resultArrowIndex: -1,
                    });
                }
            });
        }
    }

    searchFormRelate() {
        this.setState({loaded: false})
        const value = this.props.searchText;
        const module = this.props.module;
        var searchFields = ["name"];
        var getFields = ["name", "id"];


        if(this.props.data.searchFields != undefined){
            searchFields = this.props.data.searchFields;
            getFields = getFields.concat(searchFields);
        }

        if (value !== "" || value !== null) {
            const searchData = {
                fields: searchFields,
                getFields: getFields,
                value,
                module,
                autocomplete: true,
                //defaultFilter: this.props.defaultFilter
            };
            var self = this;
            sAction.quickSearch(searchData, data => {
                this.storeTranslates(data)
                self.setState({
                    resultArrowIndex: -1,
                    resultData: data,
                    loaded: true
                });
            });
        }
    }

    searchParent(){
        this.setState({loaded: false})
        const value = this.props.searchText;
        const module = this.props.module;
        if (value !== "") {
            const searchData = {
                fields: ["name"],
                getFields: ["id", "name"],
                value,
                module,
                autocomplete: true,
            };
            var self = this;
            sAction.quickSearch(searchData, data => {
                this.storeTranslates(data)
                self.setState({
                    resultData: data,
                    loaded: true,
                });
            });
        }
    }

    searchFormParent(){
        this.setState({ loaded: false})

        const value = this.props.searchText;
        const module = this.props.module;
        var searchFields = ["name"];
        var getFields = ["name", "id"];
        if(this.props.data.searchFields != undefined){
            searchFields = this.props.data.searchFields;
            getFields = getFields.concat(searchFields);
        }

        if (value !== "" || value !== null) {
            const searchData = {
                fields: searchFields,
                getFields: getFields,
                value,
                module,
                autocomplete: true,
                defaultFilter: this.props.defaultFilter
            };
            var self = this;
            sAction.quickSearch(searchData, data => {
                this.storeTranslates(data)
                self.setState({
                    resultArrowIndex: -1,
                    resultData: data,
                    loaded: true
                });
            });
        }
    }

    searchPSC() {
        const psc = this.props.searchText.replace(/[^0-9]+/g, '');
        sAction.rest.fetchData('detailapi/searchpsc/' + psc, 'GET').then((returnData) => {
            const result = [];

            returnData['data'].forEach((value) => {
                result.push({
                    name: `${value['address_postalcode'].slice(0, 3)} ${value['address_postalcode'].slice(3)}\u2000${value['address_city']}`,
                    ...value});
            });

            this.setState({
                resultData: result,
                loaded: true,
                resultArrowIndex: -1,
            });
        }).catch(() => {
            this.setState({
                resultData: [],
                loaded: true,
                resultArrowIndex: -1,
            });
        });
    }

    // funkce pocka, nez uzivatel dopise text
    waitForSearch() {
        if (this.searchInterval != null) {
            clearInterval(this.searchInterval);
        }
        this.searchInterval = setInterval(() => {
            this.search();
            clearInterval(this.searchInterval);
        }, 300);
    }

    componentDidUpdate(prevProps, prevState) {
        // tento kód způsobuje, že není vždy vstup zaznamenán (pokud se uživatel trefí do okna, kdy se dotaz načítá), nevím je-li to na něco
        /* if(!this.state.loaded){
            return
        } */

        if (this.props.searchText !== prevProps.searchText) {
            if (!this.state.open) {
                this.setState({open: true}); // pokud je okno zavreny, a zmeni se text, vyrenderuje se okno
            }
            this.waitForSearch();
        }
    }

    componentWillUnmount(){
        this._ismounted = false;
        clearInterval(this.searchInterval);
    }

    componentDidMount() {
        this._ismounted = true;
        if(this.props.searchText){
            this.waitForSearch();
        }
    }

    // metoda, kdyz kliknu misi, vezme item a posle ho parentovi
    selectItem(item) {
        item.selectMethod = "click";
        this.props.autocompleteReturnItem(item);
    }

    getSearchResult() {
        const prefix = this.props.prefix;
        const module = this.props.module || null;
        const addButton = this.props.addButton ?? false;
        let searchResult = null;
        const selectedId = this.props.selectedId || null;

        if (this.state.loaded) {
            let resultList = [];
            if (addButton && sAction.hasAccess(module, "newRecord")) {
                resultList.push(
                    <div
                        tabIndex={resultList.length}
                        onClick={() => this.props.newRecord()}
                        key={resultList.length}
                        className={"quickSearchResultLine newRecord"}
                    >
                        {sAction.translate("LBL_CREATE_NEW_RECORD")}
                    </div>
                );
            }
            //Pokud se nenasly zadne zaznamy vyrendureje se hlaska s nenalazenymi zaznamy
            if (this.state.resultData.length < 1) {
                resultList.push(<div key="noRecords" className="quickSearchNoRecords" style={{height: "70%", top: "25px"}}>
                    <span>{sAction.translate("LBL_NO_MATCHES_FOUND")}</span>
                </div>)
            }
            this.state.resultData.forEach((item, index) => {
                var lineClass = "quickSearchResultLine";

                if (item.id === selectedId) {
                    lineClass += " select";
                }

                let refAttr = {}
                if((index - 1) === this.state.resultArrowIndex || (index + 1) === this.state.resultArrowIndex) {
                    refAttr = {
                        ref: this.containerRef
                    }
                }

                if (index === this.state.resultArrowIndex) {
                    lineClass += " arrowSelect";
                    refAttr = {
                        ref: this.containerRef
                    }
                }
                let infoList = [];
                let listInfoIndex = 0;  // kvuli vytvoreni unikatniho klice pro react
                for (const [translate_key, infoValue] of Object.entries(item)) {
                    if(['id','name'].includes(translate_key)){  // pokud je klic id nebo name  tak preskoc
                        continue;
                    }
                    if(infoValue){
                        infoList.push((<span className={"itemInfo"} key={`${index}aut${listInfoIndex}`} title={this.translate ? this.translate[translate_key] : ""}>{sAction.decodeHTMLEntities(infoValue)}</span>)) ;
                    } else {
                        infoList.push((<span className={"itemInfo"} key={`${index}aut${listInfoIndex}`} title={this.translate ? this.translate[translate_key] : ""}>{"---"}</span>));   // pokud chybi dana polozka
                    }
                    listInfoIndex++;
                }

                resultList.push(
                    <div tabIndex={index + 1} onClick={() => this.selectItem(item)} key={index + 1} className={lineClass} {...refAttr}>
                        <span className="quickSearchResultLineItem">
                            {sAction.decodeHTMLEntities(item.name) + " "}
                        </span>
                        <span className="quickSearchResultLineItem">
                                {infoList}
                        </span>

                    </div>
                );

            });
            searchResult = <div className="quickSearchResultContent" onKeyPress={(e) => this.handleKeyDown}>{resultList}</div>;
        } else {
            searchResult = <div className="quickSearchResultContent" ><div style={{height: "70%", top: "25px"}} className="quickSearchNoRecords">{<Loader/>}</div></div>;
        }

        return (
            <DropdownAnimation open={this.props.resultOpen} className="quickSearchResult">
                {searchResult}
            </DropdownAnimation>
        );
    }

    executeScroll = () => {
        if (this.containerRef.current) {
            this.containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
        }
    }

    changeArrowIndex(keyCode) {
        var resultArrowIndex = this.state.resultArrowIndex;
        if (keyCode === 40) {
            resultArrowIndex += 1;
            this.executeScroll()
        } else if (keyCode === 38) {
            resultArrowIndex -= 1;
            this.executeScroll()
        }

        if (resultArrowIndex < -1) {
            resultArrowIndex = -1;
        }
        if (this.state.resultData != null) {
            if (resultArrowIndex >= this.state.resultData.length) {
                resultArrowIndex = this.state.resultData.length;
            }
        }
        this.setState({ resultArrowIndex });
    }

    returnSelectedItem(){
        let arrowIndex = this.state.resultArrowIndex;
        if(arrowIndex !== -1){
            let item = this.state.resultData[arrowIndex]
            item.selectMethod = "keyboard";
            return item;
        }
        return null;
    }

    /**
     * Viz. wiki a propTypes
     */
    render() {
        let searchResult = null;
        const open = this.props.resultOpen;
        if (open && this.state.open) {
            searchResult = this.getSearchResult();
        } else {
            return null;
        }

        /* div autocompleteClose = okno ktere se zobrazi pres cely viewport, kdyz se na nej klikne, schova autocomplete  */
        return (
            <>
                <div className="autocompleteClose"
                    onClick={() => {
                        this.props.autocompleteReturnItem(null); // vracim item parentovy
                        this.setState({open: false});
                    }}
                />
                <div>
                    {searchResult}
                </div>

            </>
        );
    }
}

Autocomplete.propTypes = {
    ref: propTypes.any,// REQUIRED!!!   // reference na dany autocomplete
    resultOpen: propTypes.bool.isRequired,  // zda se ma zobrazit
    searchText: propTypes.string.isRequired,    // vyhledavany text
    searchMethod: propTypes.string.isRequired,  // vyber vyhledavaci funkce, lze implementovat vlastni

    autocompleteReturnItem: propTypes.func.isRequired,  // callback, ktera se zavola s vybraným elementem,
                                                        // nadřazená komponenta si definuje co s danou položkou uděla
    module: propTypes.string,   // modul ktery se prohledava,
    addButton: propTypes.bool, // chci tlačitko pro vytvoření nového záznamu
    newRecord: propTypes.func, // je povinna, pokud addButton == true, callback parenta, aby vytvořil danou položku
    selectedId: propTypes.string,   //
}
