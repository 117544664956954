import React from 'react';
import PropTypes from 'prop-types';
import Timeline from '../Timeline/Timeline';
import Select from '../formElements/Select';
import sAction from 'sAction';

export default class DetailTimeline extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            customValue: null,
        };
    }

    /**
     * @param {Event} e event
     * @param {string} value selected value
     * @desc function either calls this.changeState function, or sAction function in this.props.data.onClick
     */
    onSelect(e, value) {
        const way = this.props.way;
        const id = this.props.id;
        const module = this.props.module;

        const data = this.props.data;
        if (data.clickable && !this.props.readonly) {
            const action = data.onClick.get(value);
            if (action === undefined) {
                this.changeState(value);
            } else if (action !== null) {
                if (action.type === 'sAction') {
                    if (sAction[action.action] !== undefined) {
                        const param = {
                            way, id, module, stage: value, prefix: way,
                        };
                        sAction[action.action](param);
                    } else {
                        console.log('Tato akce není v sAction');
                    }
                }
            }
        }
    }

    /**
     * @desc if list type is list, calls this.changeStateBase, if it's custom, opens popup with form
     * @param {string} value
     */
    changeState(value) {
    const data = this.props.data;
    const listType = data.list.get('type');

        if (listType === 'list') {
            this.changeStateBase(value);
        } else if (listType === 'custom') {
            const listData = data.list.get('value');
            const pointData = listData.get(value);

            if (pointData.values.size > 1) {
                const buttons = [
                    {label: 'Zrušit', callback: () => sAction.popupHide()},
                    {
                        label: 'Pokračovat',
                        callback: () => {
                            this.changeStateCustom(this.state.customValue);
                        },
                    },
                ];

                const completeList = sAction.app_strings[data.list.get('name')];
                const selectValues = [];
                pointData.values.forEach((value) => {
                    selectValues.push({value: value, label: completeList[value]});
                });

                const quickForm = (
                    <div className="detailTimelineCustomForm">
                        <div>{sAction.translate('LBL_SELECT_STATE_TIMELINE')}</div>
                        <div>
                            <Select
                                options={selectValues}
                                myRef={this.customSelect}
                                onChange={(e) => this.setState({customValue: e.target.value})}
                            />
                        </div>
                    </div>
                );
                sAction.popup(quickForm, {header: 'Test', buttons});
            } else {
                this.changeStateCustom(pointData.values.get(0));
            }
        }
    }

    /**
     * @desc checks for empty value, then calls this.changeStateBase() and hides popup
     * @param {string} value
     */
    changeStateCustom(value) {
        if (value !== '') {
            this.changeStateBase(value);
            sAction.popupHide();
        }
    }

    /**
     * @desc saves clicked timeline option in related enum
     * @param {string} value
     */
    changeStateBase(value) {
        const data = this.props.data;
        const way = this.props.way;
        const field = this.props.field;
        const name = data.field;
        const type = field.def.get('type');
        const updateData = {
            way: `${way}/fields/${name}`,
            name,
            value,
            type,
        };
        sAction.saveField(updateData);
    }

    countNumberOfFullElements(list) {
        return Object.entries(list).filter(([key, value]) => key !== '').length;
    }

    render() {
        const data = this.props.data;
        const module = this.props.module;
        const field = this.props.field;
        const listType = data.list.get('type');
        const ignoreStates = this.props.ignoreStates

        let selectValue = null;

        const timeLineData = [];
        if (listType === 'list') {
            const list = this.props.customOptions ?? sAction.app_strings[this.props.options];
            if (this.props.customOptions) {
                // DM ignoreStates is not implemented in here as Alefbet is not using customOptions
                list.map((entry) => {
                    if (entry.get('value') !== '') {
                        timeLineData.push({
                            count: this.countNumberOfFullElements(list),
                            value: entry.get('value'),
                            label: entry.get('label'),
                            title: sAction.translate(data.titles.get(entry.get('value')), module),
                            clickAble: data.onClick.get(entry.get('value')) !== null,
                        });
                    }
                });
            } else {
                list.forEachObject((label, value) => {
                    if (value !== '') {
                        if(ignoreStates && ignoreStates?.includes(value)){
                            return;
                        }
                        timeLineData.push({
                            count: this.countNumberOfFullElements(list),
                            value: value,
                            label: label,
                            title: sAction.translate(data.titles.get(value), module),
                            clickAble: data.onClick.get(value) !== null,
                        });
                    }
                });
            }
            selectValue = field.value;
        } else if (listType === 'custom') {
            const list = data.list.get('value');
            list.entrySeq().forEach((k) => {
                if(ignoreStates && ignoreStates?.includes(k[0])){
                    return;
                }
                timeLineData.push({
                    value: k[0],
                    label: sAction.translate(k[1].label, module),
                    title: sAction.translate(data.titles.get(k[0]), module),
                    clickAble: data.onClick.get(k[0]) !== null,
                });

                const values = k[1].values;
                if (values.includes(field.value)) {
                    selectValue = k[0];
                }
            });
        }

        const defaultColor = sAction.param.defaultColor;
        let color = data.colors.get(selectValue);
        if (color === undefined) {
            color = defaultColor;
        }

        const selectValueData = {value: selectValue, color: color};

        return (
            <Timeline
                data={timeLineData}
                value={selectValueData}
                clickable={!this.props.readonly && data.clickable}
                onSelect={(e, value) => this.onSelect(e, value)}
            />
        );
    }
}

DetailTimeline.propTypes = {
    id: PropTypes.string,
    data: PropTypes.object.isRequired,
    module: PropTypes.string.isRequired,
    way: PropTypes.string.isRequired,
    field: PropTypes.object.isRequired,
    type: PropTypes.string,
    options: PropTypes.string.isRequired,
    customOptions: PropTypes.object,
};
