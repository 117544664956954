import React from "react";
import SimpleFormFromArray from "../../components/formElements/SimpleFormFromArray"

export default function simpleFormShowcase(params) {
    this.load();
    this.rest.post('getSimpleFormArrayShowcase', {}, returnData => {
        this.unLoad();
        if (returnData.status) {
            returnData.message.data?.buttons.forEachObject((val, key) => {
                returnData.message.data.buttons[key].onClick = eval(val.onClick);
            });
            let popup = (
                <>
                    <div className="reportWindowHeader">
                        <div className="reportWindowHeaderText">SHOWCASE</div>
                    </div>
                    <SimpleFormFromArray fields={returnData.message.data?.fields} buttons={returnData.message.data?.buttons} />
                </>
            );
            this.popup(popup);
        }
    });
}
