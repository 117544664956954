import React from "react";
import PureComponent from "../pure";
import  sAction  from "sAction";
import Loader from "../loader";
import Button from "../formElements/Button";

export default class AdminIframe extends PureComponent {
  constructor() {
    super();
    this.iframe = React.createRef();
    
  }
  componentDidMount(){
    var name = this.props.name;
    const way = this.props.way;
    var paramData = {};
    if(this.props.data != undefined){
      paramData = this.props.data.get("params");
    }
    sAction.loadAdminCustom(name,way+"/customData",paramData);
    var self = this;
    this.listener = function(evt){
      self.iframeMessage(evt);
    }
    window.addEventListener("message",this.listener)
  }
  componentDidUpdate(){
    const data = this.props.data

    var html = "";
    try{
      html = data.get("html");
    }catch(e){
      
    }
    this.iframe.current.contentWindow.document.open();
    this.iframe.current.contentWindow.document.write(html);

    if(!html){
    var name = this.props.name;
    const way = this.props.way;

    var paramData = {};
    if(this.props.data != undefined){
      paramData = this.props.data.get("params");
    }
    sAction.loadAdminCustom(name,way+"/customData",paramData);
    }
  }
  componentWillUnmount(){
    window.removeEventListener("message",this.listener);
  }
  iframeMessage(message){
    var data = message.data
    var name = this.props.name;
    const way = this.props.way
    
    if(data.action == "submit"){
      sAction.dataSet(way+"/customData/html","load");
      sAction.loadAdminCustom(name,way+"/customData",data.data);
    }else if(data.action == "href"){
      if(data.target == "_blank"){
        window.open(data.url,'_blank');
      }else{
        window.location = data.url;
      }
    }else if(data.action == "ajax"){
        sAction.adminCustomAjax(name,data,returnData => {
        this.iframe.current.contentWindow.postMessage(returnData, '*');
      })
    }else if(data.action == "ajax_silent"){
      sAction.load();
      sAction.rest.post(data.action_url, data, (returnData) => {
        sAction.unLoad();
        if (returnData && returnData.errorMessage) {
          sAction.error(returnData.errorMessage);
        } else if (typeof returnData !== "object" || (returnData.status !== "ok" && returnData.status !== true)) {
          sAction.error(sAction.translate("LBL_ACTION_FAILED"));
        }
      });
    }else if(data.action == "relate"){
      data = {
        module: data.module,
      };
      sAction.openRelatePopup(data, returnData => {
        sAction.popupHide();
        this.iframe.current.contentWindow.postMessage(returnData, '*');
      });
    }else if(data.action == "load"){
      sAction.load();
    }else if(data.action == "unLoad"){
      sAction.unLoad();
    } else if (data.action === "download") {
        sAction.downloadFile({
            id: "",
            name: data.file,
            module: "acm_coripoReports",
            params: {
                file: data.file
            }
        });
    }else if(data.action == "message"){
      sAction.adminMessage(data.data,returnData => {
        this.iframe.current.contentWindow.postMessage(returnData, '*');
      });
    }
  }
  render() {
    const data = this.props.data
    var load = false
    if(data == null){
      load = true
    }else{
      if(data.get("html") == "" || data.get("html") == "load" || data.get("html") == undefined){
        load = true
      }
    }

    return <div className="customReportContainer">
      <div className="customReport">
        {load == true && <Loader />}
        <iframe style={load == true ? {display:"none"}:null} ref={this.iframe} />
      </div>
    </div>;
  }
}
