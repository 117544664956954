import React from "react";
import ReactDOM from "react-dom";
import TabContainer from "./TabContainer";
import PureComponent from "../pure";
import { MenuItem } from "@material-ui/core";
import InputText from "./InputText";
import sAction from "sAction";

export default class SelectWindow extends PureComponent {
  constructor(props) {
    super(props);

    let defaultValue = "";
    if (
      this.props.defaultValue != "" &&
      this.props.defaultValue != null &&
      this.props.defaultValue != undefined
    ) {
      defaultValue = this.props.defaultValue;
    }
    const options = this.props.options;
    let activeTab = 0;
    let index = 0;
    options.forEachObject((value, key) => {
      if (this.props.activeTabName == key) {
        activeTab = index;
      }
      index += 1;
    });
    this.state = {
      activeTab: activeTab,
      label: "",
      value: defaultValue,
      defaultValue: defaultValue,
      filter: "",
      style: null,
      container: null,
    };
    this.input = React.createRef();
    this.parentMeasures = null;
  }



    onClose() {
    this.state.filter = "";
    this.props.onClose();
  }
  componentDidUpdate() {
    this.input.current ? this.input.current.focus() : null;
  }
  onChange(filter) {
    clearTimeout(this.interval);
    this.interval = setTimeout(() => {
      this.setState({ filter: filter });
    }, 300);
  }

  onClick(value, label, index, type) {
    this.setState({ label: label, value: value });
    this.props.onClick({ label: label, value: value, index: index, type: type, activeTab: this.state.activeTab});
  }

  changeActivetab(value) {
    this.setState({ activeTab: value });
  }

  getContent(activeTab) {
    const options = this.props.options[activeTab];
    let orderedOptions = options.sort((a, b) => {
      let aLabel = a.label || a.value;
      let bLabel = b.label || b.value;
      return aLabel.localeCompare(bLabel, "en", { sensitivity: "base" });
    });

    let output = [];
    orderedOptions.forEach((item, key) => {
      if (
        this.state.defaultValue != "" &&
        this.state.defaultValue == item.value
      ) {
        this.state.defaultValue = "";
        this.state.label = item.label;
      }
      if (item.label) {
        let label = item.label || item.value || "";
        if (
          this.state.filter === "" ||
          label.toLowerCase().includes(this.state.filter.toLowerCase())
        ) {
          output.push(
            <MenuItem
              key={key}
              value={item.value}
              onClick={(e) => this.onClick(item.value, item.label, item.index, item.type)}
              className={item.value == this.state.value ? "selected" : null}
            >
              {item.label || "{" + item.value + "}"}{" "}
              <span> ({item.value}) </span>
            </MenuItem>
          );
        }
      }
    });
    return output;
  }

    handleClickOutside = event => {
        if (this.props.open && !ReactDOM.findDOMNode(this)?.contains?.(event.target) && !this.props.checkNode(event.target)) {
            this.props.toggle(event);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside)
    }

  componentDidMount() {
      document.addEventListener('mousedown', this.handleClickOutside)
      const selectWindow = document.getElementsByClassName(
      "customSelectWindow"
    )[0];
    if (selectWindow) {
      const dropdownMeasures = Math.min(selectWindow.getBoundingClientRect(), 400);
      const containerBounds = this.props.container.getBoundingClientRect();

      if (containerBounds.right < dropdownMeasures.right) {
        const boundingRect = this.parentMeasures;
        const y = boundingRect.y + boundingRect.height;
        const left =
          boundingRect.left - (dropdownMeasures.width - boundingRect.width);
        const zIndex = this.props.isPopupOpen === null ? 10 : 510;
        const style = {
          top: y,
          left: left,
          zIndex: zIndex,
          minWidth: boundingRect.width,
          maxWidth: '400px',
        };

        this.setState({ style: style });
      }
    }
  }

  render() {
    const options = this.props.options;
    let tabs = [];
    options.forEachObject((value, key) => {
      tabs.push(key);
    });
    let renderMenu = (
      <TabContainer
        tabs={tabs}
        onChange={(e, value) => this.changeActivetab(value)}
        value={this.state.activeTab}
      />
    );
    let content = this.getContent(tabs[this.state.activeTab]);

    let left = null;
    let y = null;
    let width = null;
    let boundingRect;
    const parent = this.props.getParentElement();
    if (parent.current !== null) {
      boundingRect = this.parentMeasures = parent.current.getBoundingClientRect();
      width = boundingRect.width;
      left = boundingRect.left;
      y = boundingRect.y + boundingRect.height;
    }

    const container = this.props.container;
    let style = { top: y, left: left, minWidth: width, zIndex: 10, maxWidth: '400px' };
    if (this.props.isPopupOpen) {
      style.zIndex = 510;
    }

    let dropdown = ReactDOM.createPortal(
      <React.Fragment>
        <div className="customSelectWindow" style={this.state.style ?? style}>
          <div className="selectHeader">{renderMenu}</div>
          <div className="selectInput">
            <InputText
              onChange={(e) => this.onChange(e.target.value)}
              autoFocus={true}
              label={sAction.translate("LBL_REPORT_INPUT_FILTER")}
              myRef={this.input}
            />
          </div>
          <div className="selectContent">{content}</div>
        </div>
      </React.Fragment>,
      container
    );

    let label = this.state.label || null;
    return <React.Fragment>{dropdown}</React.Fragment>;
  }
}
