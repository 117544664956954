import React, {PureComponent} from "react";
import sAction from "sAction";
import DetailViewSpanValue from "ROOT/src/components/detailView/DetailViewSpanValue";
import DetailApiButtons from "ROOT/src/components/detailViewFields/Api/DetailApiButtons";

export default class ReportViewField extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            actEdit: false
        }

    }

    //Show je zde kvuli relatu aby se mohl delat rightpanel
    //TODO predelat aby nebyla duplicita kodu
    show(e, data) {
        e.stopPropagation();
        const prefix = this.props.prefix;
        const type = sAction.dataGet(prefix + "/type");
        if (type !== "rightPanel") {
            sAction.rightPanelDetail(data.module, data.id);
        }
    }


    showInfo(selector, help, header) {
        sAction.popper({
            selector: selector,
            content: help,
            header: header,
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
            },
            transformOrigin: {
                vertical: "top",
                horizontal: "left",
            },
        });
    }


    onBlur = (value) =>{
        if (this.props.data.def.get('type') !== 'bool' || this.props.way !== 'report') {
            this.setState(prevState => ({actEdit: !prevState.actEdit}));
        }
        this.props.onChange(this.props.data.name, value)
    }

    render() {
        const data = this.props.data;
        if (!data || !data.def) { // zamezi padu app
            console.error("DetailViewField.js - !data.def");
            return null;
        }

        const module = this.props.module;
        const labelValue = this.props.labelValue

        let errorMsg = null;

        var value;
        var type = data.def.get("type");
        if (type == undefined) {
            type = "varchar";
        }
        let fieldType = type.charAt(0).toUpperCase() + type.slice(1);

        var showDetailApi = data.def.get("showDetailApi");
        if (showDetailApi == undefined && type == "phone") {
            showDetailApi = "phoneApi";
        }
        var showApiButtons = showDetailApi && showDetailApi.indexOf("Api") !== -1;
        if (this.props.acl && this.props.acl.get("edit") == false) {
            showApiButtons = false;
        }
        if (!this.state.actEdit) {
            value = (
                <DetailViewSpanValue
                    prefix={this.props.prefix}
                    way={this.props.way}
                    rowWay={this.props.rowWay}
                    index={this.props.index}
                    edit={() => this.setState(prevState => ({
                        actEdit: !prevState.actEdit
                    }))}
                    data={data}
                    show={(e, data) => this.show(e, data)}
                    fieldType={fieldType}
                    boolField={sAction.fields["Bool"]}
                    readonly={false}
                    acl={this.props.acl}
                    module={module}
                    onBlur={this.onBlur}
                />
            );
        } else {
            if (showApiButtons) {
                if (showDetailApi !== "ApiStreet" && showDetailApi !== "phoneApi") {
                    fieldType = showDetailApi;
                }
            }
            var F = sAction.fields[fieldType];
            if (!F) {
                console.log("Field '" + fieldType + "' se nenašel");
                F = sAction.fields["Varchar"];
            }
            value = (
                <F
                    prefix={this.props.prefix}
                    way={this.props.way}
                    data={data}
                    rowWay={this.props.rowWay}
                    index={this.props.index}
                    module={this.props.module}
                    onBlur={this.onBlur}
                />
            );
        }

        let customClass = data.def.get("customClass")
        if (!customClass) {
            customClass = ""
        }

        const help = data.def.get("help") ?? false;

        return  (
            <div className={`detailViwRow ${customClass}`} style={this.props.extraStyle}>
                <div>
                    <div className="DetailviewLabel">
                        <label dangerouslySetInnerHTML={{__html: labelValue}}/>
                        {data.def.get("required") && <span className="required">* </span>}
                        {help && (<div id={data.name + "_help"}
                                       className="icon-info detailviewInfoIcon"
                                       onClick={(e) => this.showInfo("#" + data.name + "_help", help, labelValue)}/>
                        )}
                    </div>
                    <div className={data.edit ? "DetailViewValue pointerClass" : "DetailViewValue"}>
                        {showApiButtons ? (
                            <div className="detailApi__field">
                                {value}
                                {errorMsg}
                                <DetailApiButtons
                                    type={showDetailApi}
                                    prefix={this.props.prefix}
                                    name={data.name}
                                    value={data.value}
                                />
                            </div>
                        ) : (
                            <>
                                {value}
                                {errorMsg}
                            </>
                        )}
                        <input type="hidden" id={data.name + "_hidden"} value={data.value == null ? "" : data.value}/>
                    </div>
                </div>
            </div>
        );
    }
}
