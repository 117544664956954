export default function emailGetList(search, way, clear = true, append = false, load = true, callback = false) {
  if (load) this.load();

  const func = search.emailUIAction; // [getMessageList, getMessageListSugarFolders, searchAdvanced, getLastOpenFolder]

  this.rest.post("email/" + func, search, emails => {
    if (clear) this.dsClear();
    if (!append) this.dsAdd("set", way + "/emails", []);

    let activeEmailData = "";

    // typeof null je podle JS true
    if (typeof emails === "object" && emails !== null && "Email" in emails) {
      emails.Email.forEach((email, i) => {
        if (i===0 && email.type !== "draft") {
          activeEmailData = {
            mbox: email.mbox,
            uid: email.uid,
            ieId: email.ieId,
            seen: email.seen,
            way: email.way,
          };
        }
        email.way = way + "/emails/" + i;
        this.dsAdd("add", way + "/emails", email, "EmailList");
      });
    } else {
      emails = {};
    }

    if (func != "searchAdvanced" && search.way) {
      this.dsAdd(
        "set",
        search.way + "/unseen",
        parseInt(emails.UnreadCount || 0, 10)
      );
    }

    if (emails.mbox) {
      if (!emails.isImap) {
        this.dsAdd("set", way + "/selectedFolder/mbox", "sugar::Emails");
      } else {
        this.dsAdd("set", way + "/selectedFolder/mbox", emails.mbox);
      }
    }

    if (emails.ieId) {
      this.dsAdd("set", way + "/selectedFolder/ieId", emails.ieId);
    }

    let email_selectedFolder_way = (search.way) || this.getStorage("email_selectedFolder_way");

    this.dsAdd("set", way + "/selectedFolder/way", email_selectedFolder_way);

    this.dsAdd("set", way + "/selectedFolder/isImap", emails.isImap);

    this.dsAdd("set", way + "/layout", 2);
    this.dsAdd("set", way + "/search", search, "EmailSearch");

    this.dsAdd("set", "conf/load", false);


    if (!activeEmailData && !append) {
      this.dsAdd("set", way + "/activeEmail", {});
    }

    this.dsProcess();

    if (activeEmailData && !append) {
      //this.emailGetDetail(activeEmailData, way);
    }

    if (callback) {
      callback(this)
    }
  });
}
