import checkDuplicityPost from "../action/DetailView/checkDuplicityPost";

export default class detailDefault {
  load(sAction,data) {
    // call immediately after detailView is load
  }
  update(sAction,data) {
    // call immediately after detailView field is update
  }
  newSubRecord(sAction,data){
    // call immediately before route sub record 
  }
  beforeSave(sAction,data){
    // call bfore Record is save

    return true;
  }
  updateDetailLine(sAction, data){
      // Uprava radku detailu
  }

  checkDuplicity(sAction, data){
      // Kontrola, jestli obsah řádku již neexistuje u jiného záznamu v modulu
      // definováno ve vardefech
    sAction.checkDuplicityPost(data)
  }
}
