export default function searchInvitees(params, prefix) {
    var self = this;

    const updateStore = (invitees) => {
        const content = {
            invitees: invitees
        }
        const action = {
            type: "CALENDARSEARCHINVITEES",
            content: content,
            prefix: prefix
        }
        this.dsClear();
        this.dsAddCustom(action);
        this.dsProcess();
    }

    if (params.parent) {
        const def = params.parent.def;
        if (!def.parent_type_value || !def.id_value) {
            return;
        }
    }
    else if (!params.first_name && !params.email_address && !params.title && !params.phone_work && !params.phone_mobile) {
        const parent_type = this.dataGet(params.prefix + "/fields/parent_name/def/parent_type_value");
        const parent_id = this.dataGet(params.prefix + "/fields/parent_name/def/id_value");
        if (parent_type === "Accounts" && parent_id) {
            params.parent = {
                def: {
                    parent_type_value: parent_type,
                    id_value: parent_id,
                }
            }
        }
        else {
            let resp = {
                Users: [],
                Leads: [],
                Contacts: [],
            }
            updateStore(resp)
            return;
        }
    }
    
    this.rest.post("searchInvitees", params, updateStore, false);
}