import React, {useRef, useEffect} from 'react';
import {useSpring, animated} from 'react-spring';
import PropTypes from 'prop-types';

const TranslateAnimation = (props) => {
    const ref = useRef(null);
    const [style, animate] = useSpring(() => ({left: `${props.left}px`, top: `${props.top}px`}), []);

    useEffect(() => {
        animate({
            left: `${props.left}px`,
            top: `${props.top}px`,
        });
        console.log('useEffect');
    }, [style, props]);

    console.log(ref.current);

    return (
        <animated.div className={props.className} style={{...style}}>
            {props.children}
        </animated.div>
    );
};

TranslateAnimation.defaultProps = {
    left: 0,
    right: 0,
    className: '',
};

TranslateAnimation.propTypes = {
    left: PropTypes.number,
    top: PropTypes.number,
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default TranslateAnimation;
