import detailDefault from "../../detailDefault";

export default class detailAccounts extends detailDefault {
  load(sAction, data) {
    this.updateDetailApiVisibility(sAction, data);
    sAction.dsClear();
    sAction.dsAdd("set", data.prefix+"/fields/seeky_activity/def/type", "HTMLText");
    sAction.dsAdd("set", data.prefix+"/fields/seeky_activity/def/readonly", true);
    sAction.dsProcess();
  }

  update(sAction, data) {
    switch (data.field) {
      case "primary_address_country":
        this.updateDetailApiVisibility(sAction, data)
        break;
    }
  }

  //fce skryva/odkryva detail fieldy podle zeme, jestli je zeme CZECH REPUBLIC
  updateDetailApiVisibility(sAction, data) {
    let address;
    if (data.value) {
      address = data.value;
    } else {
      address = sAction.dataGet(data.prefix + "/fields/primary_address_country/value");
    }

    const updateFieldApiValue = (fieldName, fieldValue) => {
      if (sAction.dataGet(data.prefix + "/fields/"+fieldName) !== undefined) {
        sAction.dsAdd("set", data.prefix + "/fields/"+fieldName+"/def/showDetailApi", fieldValue);
      }
    }

    sAction.dsClear();
    if (!address || address === "CZECH REPUBLIC") {
      //zobrazi ikony
      updateFieldApiValue("account_name", "ApiName");
      updateFieldApiValue("ticker_symbol", "ApiICO");
      updateFieldApiValue("primary_address_street", "ApiStreet");
      updateFieldApiValue("alt_address_street", "ApiStreet");
      updateFieldApiValue("primary_address_postalcode", "ApiPSC");
      updateFieldApiValue("alt_address_postalcode", "ApiPSC");
    } else {
      //skryje ikony
      updateFieldApiValue("account_name", "");
      updateFieldApiValue("ticker_symbol", "");
      updateFieldApiValue("primary_address_street", "");
      updateFieldApiValue("alt_address_street", "");
      updateFieldApiValue("primary_address_postalcode", "");
      updateFieldApiValue("alt_address_postalcode", "");
    }
    sAction.dsProcess();
  }
}
