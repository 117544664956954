export default function checkRequiredAreMapped(mapping, additionalMapping, fields) {
    let requiredFields = [];
    fields.forEachObject((field, key) => {
        if (field?.required
            && field.name !== 'id'
            && !mapping.some(mappedField => mappedField.value === field.name)
            && !additionalMapping.some(mappedField => mappedField.field === field.name)
        ) {
            requiredFields.push(field.vname);
        }
    });

    return requiredFields;
}
