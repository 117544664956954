export default function toogleSubpanel(data,way,prefix){
        // console.log(data.toJS());
        var open = data.def.get("open");
        if(open === true){
            this.dataSet(way+"/def/open",false);
            this.saveSubpanelOrder(prefix);
        }else if(open === false || open == null){
           // this.dataSet(way+"/def/open",true);
            this.dataSet(way+"/def/load",true);
            const parentData = this.dataGet(prefix);
            const rel = data.def.get("rel");
            const subData = {
                parentId : parentData.id,
                parentModule: rel.get("module"),
                module: data.module,
                relName: rel.get("relName"),
                prefix: prefix,
                way:way,
                def: data.def,
            }
            this.reloadSubpanel(subData);
        }
}
