import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import czLocale from "date-fns/locale/cs";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import InputText from "./InputText";

import sAction from "sAction";

const localeMap = {
  cz: czLocale,
};

export default class AcmDate extends React.Component {
  constructor(props) {
    super();
    this.state = {
      value: this.entryFormat(props.value),
      open: true,
    };
    this.input = React.createRef();
    this.dateFormat = sAction.getDateFormat("datepicker");
  }
  componentDidMount(props) {
    if (this.props.autoOpen == true) {

      const button = this.input.current.parentElement.querySelector("button");
      if (button) {
        button.click();
      }
    }

    this.input.current.onkeyup = (e) => this.onKeyUp(e);
  }


  onKeyUp(e) {
    if (this.props.freeFormat && e.keyCode == 13) {
      var date = e.target.value;
      const dateSplit = date.split("..");
      dateSplit.forEach((actDate, index) => {
        const d = actDate.split(".");
        if (d.length == 3) {
          dateSplit[index] = d[2] + "-" + d[1] + "-" + d[0];
        }
      });
      date = dateSplit.join("..");
      this.props.onChange(date);
    }
  }
  forceOpenPicker(e){
  
    const parent = e.target.closest(".listviewHeaderInput")
    const button = parent.querySelector(".MuiButtonBase-root")
    button.click()

  }
  entryFormat(date) {
    if (!date) {
      return null;
    }
    return date;
  }
  onChange(date) {
    // Smazani pri kliknuti na "Smazat"
    if (date === null) {
      this.setState({
        value: null,
        open: false,
      });
      if (this.props.onChange !== undefined) {
        this.props.onChange("");
      }
    }

    // Pri nevalidnim datu se napise 'Invalid Date' a vsude by se pak propsalo - je potreba ho stornovat
    if (!date || date === "Invalid Date") {
      // Pokud se na rest posle null, hodnota se neupravi. Kvuli tomu je potreba poslat prazdny string, aby se datum smazalo
      date = "";
    }

    if (date) {
        let momentDate = moment(date);
        date = momentDate.format('YYYY-MM-DD');
      // const dateObj = new Date(date);
      // const month =
      //   dateObj.getMonth() < 9
      //     ? "0" + (dateObj.getMonth() + 1)
      //     : dateObj.getMonth() + 1;
      // const day =
      //   dateObj.getDate() < 10 ? "0" + dateObj.getDate() : dateObj.getDate();
      // const year = dateObj.getFullYear();
      //
      // if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
      //   date = year + "-" + month + "-" + day;
      // } else {
      //   // Pokud se na rest posle null, hodnota se neupravi. Kvuli tomu je potreba poslat prazdny string, aby se datum smazalo
      //   date = "";
      // }
    }

    if (date) {
      this.setState({
        // Prazdny string hodi invalid date, je potreba ulozit jako null
        value: date ? date : null,
        open: false,
      });
    }
    if (this.props.onChange !== undefined) {
      this.props.onChange(date);
    }
  }

  render() {
    let value = this.state.value;
    if (this.props.defaultValue) {
      value = this.props.defaultValue;
    }

    const dynamicAttr = {};
    if (this.props.freeFormat == true) {
      if(value != null){
      var date = value;
      const dateSplit = date.split("..");
      dateSplit.forEach((actDate, index) => {
        const d = actDate.split("-");
        if (d.length == 3) {
          dateSplit[index] = d[2] + "." + d[1] + "." + d[0];
        }
      });
      value = dateSplit.join("..");
    }
    }

    var className = "AcmDate " + this.props.className;
    if (this.props.freeFormat == true) {
      className += " hidden";
      dynamicAttr.inputValue = value
    }
    return (
      <>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap["cz"]}>
          <KeyboardDatePicker
            className={className}
            autoOk={true}
            clearable
            value={value}
            autoFocus={this.props.autoFocus ?? false}
            onChange={(date) => this.onChange(date)}
            onClose={() =>
              this.props.onClose != undefined ? this.props.onClose() : {}
            }
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
            cancelLabel={sAction.translate("LBL_DATEPICKER_CANCEL")}
            okLabel={sAction.translate("LBL_DATEPICKER_OK")}
            clearLabel={sAction.translate("LBL_DATEPICKER_CLEAR")}
            todayLabel={sAction.translate("LBL_DATEPICKER_TODAY")}
            showTodayButton={true}
            format={this.dateFormat}
            inputRef={this.input}
            label={this.props.label}
            invalidDateMessage={this.props.invalidMessage ? this.props.invalidMessage : ""}
            {...dynamicAttr}
          />
        </MuiPickersUtilsProvider>
        {this.props.freeFormat == true && (
          <div className="acmDateFreeFormat">
            <InputText
              key={value}
              label={this.props.label}
              defaultValue={value}
              onKeyUp={(e) => this.onKeyUp(e)}
            />
            <span
            onClick={(e) => this.forceOpenPicker(e)}
            >
            <svg
              className="MuiSvgIcon-root"
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"></path>
              <path fill="none" d="M0 0h24v24H0z"></path>
            </svg>
            </span>
          </div>
        )}
      </>
    );
  }
}
