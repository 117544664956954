/**
 * Kontrola na vyplnenost povinnych fieldu
 * vraci true/false podle toho, jestli byly vyplneny vsechny povinne polozky...
 * @param {string} prefix
 * @param {bool} saveAttempt
 * @returns {boolean}
 */

export default function requiredFieldsCheck(prefix, saveAttempt = true) {
    const fields = this.dataGet(`${prefix}/fields`);
    const tabs = this.dataGet(`${prefix}/tabs`);
    if (saveAttempt) {
        this.dsClear();
        this.dsAdd('set', `${prefix}/attemptedSave`, true);
        this.dsProcess();
    }
    return tabs.every((tab, index) => {
        return tab.hidden ? true : (tab.rows ?? []).every((row, index) => {
            return row?.get?.('type') === 'headerPanel' ? true : (row ?? []).every((field, index) => {
                if (row.visible === false || field === 'empty_row') {return true;}
                return !(fields.get(field).def.get('required') && (fields.get(field).value === "" || fields.get(field).value == null));
            });
        });
    });
}

