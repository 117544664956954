import React from 'react'
import PureComponent from '../../pure'
import TextField from "@material-ui/core/TextField";
import QProduct from './QProduct'
import QGroupTotal from './QGroupTotal'
import QGroupHeader from './QGroupHeader'
import Select from '../../formElements/Select'
import sAction from "sAction";
import Button from "../../formElements/Button";
import {Draggable, Droppable} from "react-beautiful-dnd";

class QProductGroup extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            show: true,
            descFocused: false,
            wayWithIndex: `${props.way}/${props.ind}`
        }

    }

    toggleDisplay() {
        this.setState({show: !this.state.show})
    }

    blurDescription(way, val) {
        this.setState({descFocused: false});
        this.valChange(way, val);
    }

    setDescription() {
        let group = sAction.dataGet(`${this.props.way}/${this.props.ind}`)
        group = group.set("descFocused", !group.descFocused)
        sAction.dataSet(`${this.props.way}/${this.props.ind}`, group)
    }

    valChange(fieldName, value){
        if(fieldName === "deal_tot"){
            value = sAction.parseNumber(value)

        }
        sAction.dataSet(`${this.state.wayWithIndex}/${fieldName}`, value)
    }

    /**
     * Nastavuje hodnoty pro celou skupinu, hodnoty chceme nastavit pouze pokud nastavene vsechny tri
     * jinak nevime co kam davat.
     * @param prevProps
     * @param prevState
     */
    componentDidUpdate(prevProps, prevState) {
        const data = this.props.data
        if (data.deal_tot && data.cenotvorba_discount_select && data.discount_field_select) {
            if (prevProps.data.cenotvorba_discount_select !== data.cenotvorba_discount_select) {
                sAction.changeDiscountSelect(this.state.wayWithIndex, data.cenotvorba_discount_select, data)
            } else if (prevProps.data.discount_field_select !== data.discount_field_select || prevProps.data.deal_tot !== data.deal_tot) {
                sAction.changeGroupDiscount(this.state.wayWithIndex, data.deal_tot, data.discount_field_select, data.lines)
            }
        }
    }

    setDiscount(value){
        if(!value){
            this.setState({discount: 0});
            return
        }

        this.setState({discount: parseFloat(value)});
    }

    render() {
        const data = this.props.data;
        const way = this.props.way;
        const ind = this.props.ind;
        const itway = way + "/" + ind;
        const currency_id = this.props.currency_id;
        const readonly = this.props.readonly;
        const isRightPanel = way && way.includes("rightPanel");

        const rate = this.props.rate;

        var name = data.name;

        let discountOptions = [];
        sAction.app_strings.discount_select_list.forEachObject((lbl, key) => {
            discountOptions.push({value: key, label: lbl});
        });

        let discount_field_select = []
        sAction.app_strings.discount_field_list.forEachObject((lbl, key) => {
            discount_field_select.push({value: key, label: lbl});
        });

        var pomClass = "";
        if (!this.state.show) {
            pomClass = " hidden";
        }
        const preKey = Date.now();

        var descClass = "productLineDescription";
        var descContClass = "descGroupContainer";
        //notEmpty
        var descComp = null;

        if (this.state.descFocused) {
            descComp = <TextField
                className={descClass}
                multiline={true}
                type="text"
                margin="normal"
                variant="outlined"
                rows={4}
                key={preKey + "_description"}
                onBlur={(ev) => this.blurDescription(itway + "/description", ev.target.value)}
                disabled={readonly}
                defaultValue={data.description}
                autoFocus={true}
            />
        } else {
            if (data.description.length > 0) {
                descContClass += " notEmpty";
            }
            descComp = <span>{data.description}</span>
        }

        var iconClass = "icon-quoteCloseGroup";
        if (!this.state.show) {
            iconClass = "icon-quoteOpenGroup";
        }

        return (
            <>
                <Draggable draggableId={`group-${ind}`} index={ind}>
                    {(provided) => (
                        <div className="qGroupContainer" {...provided.draggableProps} ref={provided.innerRef}>
                            <div className="qGroupComponent">
                                {/* - HEADER */}
                                <div className="qGroupHeaderContainer">
                                    <div className="qGroupHeader">
                                        <div className="qGroupHeaderLeft">
                                            {!readonly ? <div className="qProductLineButtonContainer">
                                                <div
                                                    className={"icon-Sortable prodLineIcon hoverBlue"} {...provided.dragHandleProps}
                                                    onMouseDown={() => {
                                                        document.activeElement.blur()
                                                    }}/>
                                            </div> : null}
                                            <div className="qGroupHeaderField">
                                                <div className="qGroupHeaderFieldLbl">
                                                    {sAction.translate("LBL_PRODUCT_GROUP", "Quotes")}:
                                                </div>
                                                <TextField
                                                    margin="normal"
                                                    variant="outlined"
                                                    id="name"
                                                    type="text"
                                                    defaultValue={name}
                                                    disabled={readonly}
                                                    key={preKey + "_name"}
                                                    onBlur={(ev) => sAction.updateDetailCustom("field", {
                                                        way: itway + "/name",
                                                        value: ev.target.value
                                                    })}
                                                    className="qGroupNameField"

                                                />
                                            </div>
                                            <div className="qGroupHeaderField qGroupHeaderFieldCenotvorba">
                                                <div className="qGroupHeaderFieldLbl">
                                                    {sAction.translate("LBL_SLEVA", "Quotes")}:
                                                </div>
                                                <TextField
                                                    margin="normal"
                                                    variant="outlined"
                                                    type="text"
                                                    defaultValue={sAction.formatNumber(data.deal_tot)}
                                                    disabled={readonly}
                                                    key={preKey + "_deal_tot"}
                                                    onBlur={(ev) => this.valChange("deal_tot", ev.target.value)}
                                                    onKeyDown={((ev) => {
                                                        if (ev.keyCode === 13) {
                                                            ev.target.blur();
                                                        }
                                                    })}
                                                    className="qGroupDiscountField"
                                                />
                                                <Select
                                                    open={false}
                                                    containerStyle={{width: '160px'}}
                                                    name="cenotvorba_discount_select"
                                                    options={discountOptions}
                                                    defaultValue={data.cenotvorba_discount_select}
                                                    disabled={readonly}
                                                    containerClassName="productGroupType qGroupDiscountField"
                                                    key={preKey + "_cenotvorba_discount_select"}
                                                    onChange={(ev) => this.valChange("cenotvorba_discount_select", ev.target.value)}
                                                />
                                                <Select
                                                    open={false}
                                                    containerStyle={{width: '160px'}}
                                                    name="discount_field_select"
                                                    options={discount_field_select}
                                                    defaultValue={data.discount_field_select}
                                                    disabled={readonly}
                                                    containerClassName="productGroupType qGroupDiscountField"
                                                    key={preKey + "discount_field_select"}
                                                    onChange={(ev) => this.valChange("discount_field_select", ev.target.value)}
                                                />
                                            </div>
                                            {(!readonly && !isRightPanel) &&
                                                <Button variant="text" className="acmCatProdBtn"
                                                        onClick={() => sAction.rightPanelProduct({way: itway + "/lines"})}>
                                                    <div className="icon-expandIcon buttonIcon"/>
                                                    {sAction.translate("LBL_PRODUCT_CATALOG", "Quotes")}
                                                </Button>
                                            }
                                            {!readonly &&
                                                <Button variant="text" className="hoverBlue acmDescGroupBtn"
                                                        onClick={() => this.setState({descFocused: true})}>
                                                    <div className="icon-editDesc buttonIcon"/>
                                                    {sAction.translate("LBL_ADD_GROUP_DESCRIPTION", "Quotes")}
                                                </Button>
                                            }
                                            <div className={"quoteHoverIcon " + iconClass}
                                                 onClick={() => this.toggleDisplay()}/>
                                        </div>
                                        {!readonly &&
                                            <Button
                                                className="acmDelGroupBtn hoverRed"
                                                variant="text"
                                                onClick={() => sAction.updateDetailCustom("delItem", {
                                                    way: way,
                                                    index: ind
                                                })}>
                                                <div className="buttonIcon icon-deleteIcon"/>
                                                {sAction.translate("LBL_DELETE_GROUP", "Quotes")}
                                            </Button>}
                                    </div>
                                </div>
                                {/* - BODY */}
                                <div className={"productGroupBody" + pomClass}>
                                    <QGroupHeader key={"header"} readonly={readonly}/>
                                    <Droppable droppableId={ind.toString()}>
                                        {provided => (
                                            <div className="qGroupLines"
                                                 ref={provided.innerRef} {...provided.droppableProps}>
                                                {data.lines.map((prod, index) => <QProduct data={prod} groupIndex={ind}
                                                                                           key={index}
                                                                                           ind={index}
                                                                                           way={itway + "/lines"}
                                                                                           readonly={readonly}
                                                                                           currency_id={currency_id}
                                                                                           rate={rate}
                                                                                           accountDiscount={this.props.accountDiscount}/>)}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </div>
                            </div>
                            <div className={descContClass}>
                                {descComp}
                            </div>

                            <QGroupTotal way={itway} data={data} ind={ind} readonly={readonly}/>
                        </div>
                    )}
                </Draggable>
            </>
        )
    }
}

export default QProductGroup
