import processCustomData from "ROOT/src/store/pbData/custom/detailView/processCustomData";
export default function fillDetailPredefinedFields(prefix,id, setAssignedUserVal = true) {
    const setAssignedUser = (id, prefix, setAssignedUserVal = true) => {
        // _____ set assgined_user
        if (!id && setAssignedUserVal) {
            const user = this.dataGet('conf/user');
            this.dsAdd('set', prefix + '/fields/assigned_user_name/value',
                user.name);
            this.dsAdd('set',
                prefix + '/fields/assigned_user_name/def/id_value',
                user.id);
            this.dsAdd('set', prefix + '/changes/fields/assigned_user_id',
                user.id);
        }
        // ______set assigend_user END
    };

    this.dsClear();

    if (prefix.includes('calendar/')){
        setAssignedUser(id, prefix, setAssignedUserVal);
        this.dsProcess();
        return; // v kalendari pri vytvareni eventu z externich potrebujeme doplnit uzivatele podle toho, komu kalendar patri
    }

  // _____ set assgined_user
  var fields = {};
  // ______set assigend_user END

  if (
    this.detailPredefinedFields != undefined &&
    this.detailPredefinedFields != null
  ) {
    let detail = this.dataGet(prefix);

    var relationShips = null;
    let customData = null;
    
    this.detailPredefinedFields.forEach(field => {
      if (field.type == "field") {
        this.dsAdd("set",prefix + "/fields/" + field.name + "/value",field.value);
        fields[field.name] = field.value;
      } else if (field.type == "parent") {

        this.dsAdd("set", prefix + "/fields/" + field.name + "/value", field.parentName);
        this.dsAdd("set",prefix + "/fields/" + field.name + "/def/id_value",field.parentId);
        this.dsAdd("set",prefix + "/fields/" + field.name + "/def/parent_type_value",field.parentModule);
        fields["parent_id"] = field.parentId;
        fields["parent_type"] = field.parentModule;

      } else if (field.type == "relate") {
        var relPom = field.rel.toJS();
        detail.fields.entrySeq().forEach(e => {
            const key = e[0];
            const value = e[1]; 
            console.log(relPom,value.def.toJS());
            if(value.def.get("type") == "relate" && value.def.get("id_name") == relPom.id_name){
              this.dsAdd("set", prefix + "/fields/" + value.name + "/value", field.value);
              this.dsAdd("set",prefix + "/fields/" + value.name + "/def/id_value",field.id);
              fields[value.def.get("id_name")] = field.id;
            }else if(value.def.get("id_name") == "billing_account_id" && relPom.module == "Accounts"){
              this.dsAdd("set", prefix + "/fields/" + value.name + "/value", field.value);
              this.dsAdd("set",prefix + "/fields/" + value.name + "/def/id_value",field.id);
              fields[value.def.get("id_name")] = field.id;
            }
        })
        relPom.relname = relPom.relName;
        relPom.id = field.id;
        this.dsAdd("set", prefix+"/changes/relationships", relPom);

      } else if (field.type === "relate_simple") {
        this.dsAdd("set", prefix + "/fields/" + field.fieldName + "/value", field.value.name);
        this.dsAdd("set", prefix + "/fields/" + field.fieldName + "/def/id_value", field.value.id);
        fields[field.name] = field.value.id;
      } else if(field.type === "email"){
        this.dsAdd("set",prefix + "/fields/" + field.name + "/value",field.value);
        this.dsAdd("set",prefix + "/fields/" + field.name + "/def/emails",field.value);
        fields[field.name] = true;
      } else if(field.type === "Ss"){
        let changes = "";
        if(field.value){
          // podoba v /prefix/changes/fields/ss_field
          changes = field.value.join("|");
        }
        if(changes){
          this.dsAdd("set",prefix + "/fields/" + field.name + "/value",field.value);
          this.dsAdd("set",prefix + "/fields/" + field.name + "/def/selected",field.value);
          fields[field.name] = changes;
        }

      } else if(field.type === "hoursMinutes"){
        this.dsAdd("set",prefix + "/fields/" + field.name + "/value",field.value);
        this.dsAdd("set",prefix + "/fields/" + field.name + "/def/minutesValue",field.valueMinutes);
        fields[field.name] = field.value;
      } else if (field.type === "customData") {
        customData = {};
        field.value.forEachObject((value, key) => {
          if (key === 'invitees') {
            //deleting search invitees as only related invitees as predefined make sense
            value.searchInv = {Users: [], Contacts: [], Leads: []};
          }
          customData[key] = value;
        })
      }
    });
    
    this.dsAdd("set", prefix + "/changes/fields", fields);
    this.detailPredefinedFields = null;

    if(customData){
      // pred nastavenim customDat, je potreba mit nastavene fieldy na detailu
      this.dsProcess();
      // aby se spravne pocitali pocitalo napr na Quotes aby se spocitala dan na radcich
      detail = this.dataGet(prefix).toJS();   // aktualizace detailu
      detail.customData = customData;         // nastaveni customDat pro processCustomData

      // zmena struktury customDat na spravne typy (Record/map), pokud neni napsana premena, vraci fce null,
      // proto pokud mam CustomData a funkce vrati null, pouziji nezpracovane data
      customData = processCustomData(detail) ?? customData;
      this.dsAdd('set', `${prefix}/customData`, customData);  // ulozeni spravne struktury customDat
    }
  }
  setAssignedUser(id, prefix, setAssignedUserVal);

  this.dsProcess();
}
