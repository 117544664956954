import PropTypes from "prop-types";
import React from "react";
import PureComponent from "../../pure";

import  sAction  from "sAction";

import Radio from "../../formElements/Radio";
import { EmailPopupButtons } from "../email/shared/EmailPopupButtons";
import InputText from "../../formElements/InputText";
import Select from "../../formElements/Select";


/**
 * zobrazuje popup okno na upravení widgetu
 */
export default class HomeWidgetEdit extends PureComponent {
  constructor(props) {
    super(props);

    this.nameRef = React.createRef();
    this.urlRef = React.createRef();
    this.measure = "";
    this.state = {
      widgetSize: 450,
      widgetAutoSize: true,
      recordsPerPage: sAction.dataGet(this.props.way + "/data/options/recordsPerPage") || 10,
    };

    this.initialRecordsPerPage = this.state.recordsPerPage;

    let defaultValue = sAction.dataGet(this.props.way + "/data/options/height");
    if (defaultValue) {
      this.state.widgetSize = parseInt(defaultValue);
      this.state.widgetAutoSize = false;
    }
  }

  handleChange = ({ target: { name, value } }) => {
    if (name === "widgetSize" && value > 3000) {
      value = 3000;
    }
    else if (name === "recordsPerPage" && value > 100) {
      value = 100;
    }

    this.setState({ [name]: value });
  };

  // ulozeni zmen v popupu
  savePopup = () => {
    sAction.popupHide();

    const name = this.nameRef.current.value;
    let { widgetSize, widgetAutoSize, recordsPerPage } = this.state;

    sAction.dsClear();
    // zmena velikosti boxu
    if (widgetAutoSize) {
      sAction.dsAdd("set", this.props.way+"/data/options/height", "");
    } else {
      if (widgetSize < 200) {
        widgetSize = "200";
      }
      sAction.dsAdd("set", this.props.way + "/data/options/height", `${widgetSize}px`);
    }

    // prejmenovani widgetu
    sAction.dsAdd("set", this.props.way + "/name", name);

    // iframe - ulozeni URL
    if (this.props.type === "iframe") {
      const url = this.urlRef.current.value;
      sAction.dsAdd("set", this.props.way + "/data/url", url);
    }

    // pocet zaznamu na stranku
    recordsPerPage = parseInt(recordsPerPage);
    if (recordsPerPage < 10) {
      recordsPerPage = 10;
    }
    sAction.dsAdd("set", this.props.way + "/data/options/recordsPerPage", recordsPerPage);

    sAction.dsProcess();

    // ulozeni layoutu
    sAction.homeLayoutSave();

    if (this.initialRecordsPerPage != recordsPerPage) {
      sAction.homeWidgetRefresh("view", this.props.way);
    }
  };

  render() {
    const { widgetSize, widgetAutoSize } = this.state;
    const type = this.props.type;
    return (
      <React.Fragment>
        <div className="acmPopupHeader">
          {sAction.translate("LBL_EDIT_WIDGET", "Home")}
        </div>
        {/* Upravit widget */}
        <div className="acmPopupContent">
          <table className="w100">
            <tbody>
              <tr>
                <th>
                  {sAction.translate("LBL_ENTER_NAME", "Home") /* Název */}:
                </th>
                <td>
                  <InputText
                    autoFocus
                    id="name"
                    type="text"
                    inputRef={this.nameRef}
                    className="withBorder"
                    defaultValue={this.props.name}
                    fullWidth
                    onKeyUp={ev => {
                      if (ev.key === "Enter") {
                        ev.preventDefault();
                        this.savePopup();
                      }
                    }}
                  />
                </td>
              </tr>
              {type === "iframe" && (
                <tr>
                  <th>
                    URL:
                  </th>
                  <td>
                    <InputText
                      autoFocus
                      id="url"
                      type="text"
                      inputRef={this.urlRef}
                      className="withBorder"
                      defaultValue={sAction.dataGet(this.props.way+"/data/url")}
                      fullWidth
                      onKeyUp={ev => {
                        if (ev.key === "Enter") {
                          ev.preventDefault();
                          this.savePopup();
                        }
                      }}
                    />
                  </td>
                </tr>)}
              <tr>

                {type === "view" ? (<>
                  <th>{sAction.translate("LBL_RECORDS_PER_PAGE", "Users") /* Záznamů na stránku*/}:</th>
                <td>
                  <input
                    className="form-control-sm datetimehours pageOrderInput"
                    type="number"
                    min={10}
                    max={1000}
                    step="1"
                    value={this.state.recordsPerPage}
                    onChange={this.handleChange}
                    name="recordsPerPage"
                  />
                </td></>) :
                  (<>
                    <th>
                  {sAction.translate(
                    "LBL_ENTER_WIDGET_SIZE",
                    "Home"
                  ) /* Výška */}
                  :
                </th>
                <td style={{ padding: "10px 0" }}>
                  <Radio
                    tabIndex={0}
                    onChange={value =>
                      this.handleChange({
                        target: {
                          name: "widgetAutoSize",
                          value: true
                        }
                      })
                    }
                    checked={widgetAutoSize}
                  />
                  {sAction.translate("LBL_AUTO_SIZE", "Home")}
                  <Radio
                    tabIndex={0}
                    onChange={checked =>
                      this.handleChange({
                        target: {
                          name: "widgetAutoSize",
                          value: false
                        }
                      })
                    }
                    checked={!widgetAutoSize}
                  />
                  {sAction.translate("LBL_OWN_SIZE", "Home")}
                  {!widgetAutoSize && (<>
                    <input
                      className="form-control form-control-sm datetimehours calTimeInput"
                      type="number"
                      min={200}
                      max={3000}
                      step="10"
                      value={widgetSize}
                      onChange={this.handleChange}
                      name="widgetSize"
                      style={{ marginLeft: 12, height: 40 }}
                      onKeyUp={ev => {
                        if (ev.key === "Enter") {
                          ev.preventDefault();
                          this.savePopup();
                        }
                      }}
                    />{" px"}</>)}
                </td></>)}
              </tr>
            </tbody>
          </table>
        </div>
        <EmailPopupButtons onSave={this.savePopup} />
      </React.Fragment>
    );
  }
}

HomeWidgetEdit.propTypes = {
  way: PropTypes.string.isRequired, //"view/tabs/0/cols/0/rows/0"
  name: PropTypes.string.isRequired
};
