import React from 'react'
import PureComponent from '../pure'

import sAction from "sAction"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import listPlugin from "@fullcalendar/list"
import interactionPlugin, { Draggable } from "@fullcalendar/interaction"
import moment from "moment"
import AcmDate from "../formElements/AcmDate"
import PropTypes from 'prop-types'

/**
 * Uitečné podpůrné funkce pro práci s kalendářem:
 * sAction.clearCalDetailStore() - procisti data co se zobrazuji v popupu
 * sAction.calPlanReload('all') - donuti prenacist kalendar / externi eventy / vse ('all' / 'calendar' / 'external')
 */

class CalBody extends PureComponent {
  constructor(props) {
    super(props)

    this['calRef' + [props.user]] = React.createRef()

    this.defaultView = 'dayGridMonth'
    this.useOrderLines = false // Lines in meeting - Applied in project "Plas"
    this.getMonthsBefore = 1
    this.getMonthsAfter = 1
    this.getDaysBefore = 14
    this.getDaysAfter = 14

    this.state = {
      calViewType: this.props.data.calViewType ?? this.defaultView, //this.props.data.calViewType
      rerender: false,
      ready: false,
      openPicker: false,
      userID: props.userID,
      calRef: 'calRef' + [props.user],
      prefix: 'calendar',
      calendarEvents: [],
      events: [],
      loaded: false,
      loadedDates: {
        from: null,
        to: null
      },
      loadedDatesInit: {
        from: null,
        to: null
      }
    }

    this.mapLoaded = {}
    this.mapLoadedInit = {}
    this.updateTimeout = null
  }

  // calendarRef = React.createRef();
  componentDidMount() {
    this.calendarInit(true)
  }

  calendarInit(changeView = false) {
    // sAction.load()
    if (!this[this.state.calRef].current) {
        console.warn('>> calendarInit ... no ref', this.state.calRef, 'user_id:', this.state.userID);
        sAction.error(sAction.translate('LBL_CALENDAR_INIT_ERROR', 'Calendar') + 'user_id: ' + this.state.userID);
        return;
    }
    const calendarApi = this[this.state.calRef].current.getApi()
    const currDate = calendarApi.getDate()
    const from = moment(currDate).add(-1 * this.getMonthsBefore, 'months').add(-1 * this.getDaysBefore, 'days').format('YYYY-MM-DD')
    const to = moment(currDate).add(this.getMonthsAfter, 'months').endOf('month').add(this.getDaysAfter, 'days').format('YYYY-MM-DD')

    sAction.calInit(this.props.cssSelector, this.props.way, this.props.userID, this.props.language, from, to, (data) => {

      // pridava eventy podle mapy loaded eventu stejne tak calendarUpdateEvents, kalendar se hromdne nemaze jenom dpluje podle toho co uzivatel nacte
      // kdyz pridava jeden novy item tak nastavi flag v mape na false -> uz byl nacteny ale je potreba prerenderovat
      // jinak flag true -> uz byl nacteny a je v kandari ignorovat
      // mapy jsou dve mapLoaded a mapLoadedInit, mapLoadedInit se promazava pri reloadu kalendare protoze init data se chovai divne a nezustanou oprodi addEvent dat
      let lastIdAdded = null
      let addedCount = 0
      for(let i = 0; i < data.length; i++) {
        if (this.mapLoaded[data[i].id] && this.mapLoaded[data[i].id] === true || this.mapLoadedInit[data[i].id] && this.mapLoadedInit[data[i].id] === true) {
          data.splice(i, 1)
          i--
        } else {
          this.mapLoadedInit[data[i].id] = true
          lastIdAdded = data[i].id
          addedCount++
        }
      }

      if (addedCount === 1) {
        this.mapLoaded[lastIdAdded] = false 
        this.mapLoadedInit[lastIdAdded] = false 
      }

      if (changeView) {
        let view = this.props.way ? sAction.dataGet(this.props.way + '/data/options/initView') : this.props.data.setting.get('initView')
        this.changeView(view) // Do not judge me
      }

      this.setState({
        calendarEvents: data, // nemuze byt pres API, jelikoz by to po renderu zmizelo
        rerender: false,
        ready: true,
        loadedDatesInit: {
          from: from,
          to: to
        },
        loadedDates: {
          from: from,
          to: to
        }
      })
      sAction.unLoad() // teprve nyni je vse nacteno podle nastaveni uzivatele
      this.setState({ loaded: true })
    })

    this.setExternalEvents()
  }

  calendarUpdateEvents(from, to) { // jede pres API, takze nevynucuje prerenderovani
    let getFrom = moment(from).format('YYYY-MM-DD')
    let getTo = moment(to).format('YYYY-MM-DD')

    setTimeout(() => {
      sAction.calInit(this.props.cssSelector, this.props.way, this.props.userID, this.props.language, getFrom, getTo, (data) => {
        const calendarApi = this[this.state.calRef].current.getApi()
        // calendarApi.removeAllEvents()

        // pridat jenom nove eventy co jesete nejsou nactene a nemazat cely kalendar 
        data.forEachObject(val => {
          // toto trva asi 12ms nez prida event takze pri 300 eventech to trva asi 3 600ms 
          // a je to blocking takze jakmile zacne pridavat eventy tak calendar zamrzne asi s tim nic neudelame
          // setTimeout 0 to aspon rozdělí trochu že se nezasekne cely kalendar
          setTimeout(() => {
            if (this.mapLoaded[val.id] === undefined && this.mapLoadedInit[val.id] === undefined) {
              calendarApi.addEvent(val)
              this.mapLoaded[val.id] = true
            }
          }, 0)
        })

        sAction.unLoad() // teprve nyni je vse nacteno podle nastaveni uzivatele
        setTimeout(() => {
          this.setState({
            loaded: true,
            loadedDates: {
              from: moment(getFrom) > moment(this.state.loadedDates.from) ? getFrom : this.state.loadedDatesInit.from,
              to: moment(getTo) < moment(this.state.loadedDates.to) ? getTo : this.state.loadedDatesInit.to
            }
          })
        }, 250)
      })
    }, 0)
  }

  DatePickerHandle(date) {
    let calendarApi = this[this.state.calRef].current.getApi()
    calendarApi.gotoDate(moment(date).format('YYYY-MM-DD'))
  }

  pickADate = () => {
    // TODO tohle taky neni zrovna fajn reseni
    document.getElementById("clickMeIfYouDare" + this.state.userID).querySelector('.AcmDate').querySelector('button').click()
  }

  handleEventAdd = (arg) => {
    this.handleDayClick()
  }

  handleEventReceive = (arg) => {
    arg.event.remove() // aby v kalendari nezustal vyset jinak nastylovany event, dokud ho nepotvrdi nebo i potom

    this.setEventFieldsBeforeReceive(arg.event, this.state, (setData) => {
      sAction.popup("calQuickCreate", {
        onClose: sAction.clearCalDetailStore,
        prefix: this.state.prefix/*, event : arg.event._def*/
      })
    })
  }

  setEventFieldsBeforeReceive = (event, state, cb) => {
    let calendarApi = this[this.state.calRef].current.getApi()
    let eventStart = ''

    if (sAction.dataGet('calendar/calViewType') === this.defaultView
      || //TODO zjistit, jestli by nestacilo jen jedno z toho, nebo neco jineho podle ceho to poznavat
      calendarApi.currentDataManager.state.currentViewType === this.defaultView) {
      eventStart = moment(event.start).format('YYYY-MM-DD')
      eventStart = eventStart + ' ' + moment().format('HH:mm:ss') // aby jsme v mesicnim pohledu nevytvareli eventy na pulnoc
    } else {
      eventStart = moment(event.start).format('YYYY-MM-DD HH:mm:ss')
    }

    let meetingType = 'montaz'
    let durationHours = '1'
    let durationMinutes = '0'

    // neodradkovavat, pridalo by to tam \n
    let address = ''
    address += event.extendedProps.address.street ?? ''
    address += event.extendedProps.address.city ? `, ${event.extendedProps.address.city}` : ''
    address += event.extendedProps.address.postalCode ? `, ${event.extendedProps.address.postalCode}` : ''
    address += event.extendedProps.address.state ? `, ${event.extendedProps.address.state}` : ''

    let title = (this.useOrderLines && event.extendedProps?.orderLines && event.extendedProps.orderLines[0]?.olName)
      ? '[M] ' + event._def.title + ' - ' + event.extendedProps.orderLines[0].olName
      : '[M] ' + event._def.title

    sAction.dsClear()
    /**
     * Musi se nastavovat pro kazdy pohled zvlast. Jsou tam ruzna pole a ruzne podminky
     * - Mohlo by se trosku predelat, ale to by trvalo priliz dlouho... az bude cas...
     * */

    // Meetings
    sAction.dsAdd("set", this.state.prefix + "/meetingView/fields/name/value", title)
    sAction.dsAdd("set", this.state.prefix + "/meetingView/changes/fields/name", title)
    sAction.dsAdd("set", this.state.prefix + "/meetingView/fields/duration_hours/value", durationHours)
    sAction.dsAdd("set", this.state.prefix + "/meetingView/changes/fields/duration_hours", durationHours)
    sAction.dsAdd("set", this.state.prefix + "/meetingView/fields/meeting_type/value", meetingType)
    sAction.dsAdd("set", this.state.prefix + "/meetingView/changes/fields/meeting_type", meetingType)
    sAction.dsAdd("set", this.state.prefix + "/meetingView/fields/duration_hours/def/minutesValue", durationMinutes)
    sAction.dsAdd("set", this.state.prefix + "/meetingView/changes/fields/duration_minutes", durationMinutes)
    sAction.dsAdd("set", this.state.prefix + "/meetingView/fields/date_start/value", eventStart)
    sAction.dsAdd("set", this.state.prefix + "/meetingView/changes/fields/date_start", eventStart)
    sAction.dsAdd("set", this.state.prefix + "/meetingView/fields/location/value", address)
    sAction.dsAdd("set", this.state.prefix + "/meetingView/changes/fields/location", address)

    sAction.dsAdd("set", this.state.prefix + "/meetingView/customData/oID", event.extendedProps.id)

    sAction.dsAdd("set", this.state.prefix + "/meetingView/changes/fields/description", event.extendedProps.aContact)
    sAction.dsAdd("set", this.state.prefix + "/meetingView/fields/description/value", event.extendedProps.aContact)

    sAction.dsAdd("set", this.state.prefix + "/meetingView/fields/assigned_user_name/value", this.props.userName)
    sAction.dsAdd("set", this.state.prefix + "/meetingView/fields/assigned_user_name/def/id_value", state.userID)
    sAction.dsAdd("set", this.state.prefix + "/meetingView/changes/fields/relate/assigned_user_name", this.props.userName)
    sAction.dsAdd("set", this.state.prefix + "/meetingView/changes/fields/assigned_user_id", state.userID)

    sAction.dsAdd("set", this.state.prefix + "/meetingView/fields/parent_name/value", event.extendedProps.aName)
    sAction.dsAdd("set", this.state.prefix + "/meetingView/fields/parent_name/def/parent_type_value", 'Accounts')
    sAction.dsAdd("set", this.state.prefix + "/meetingView/fields/parent_name/def/id_value", event.extendedProps.aID)
    sAction.dsAdd("set", this.state.prefix + "/meetingView/changes/fields/parent_type", 'Accounts')
    sAction.dsAdd("set", this.state.prefix + "/meetingView/changes/fields/parent_id", event.extendedProps.aID)
    sAction.dsAdd("set", this.state.prefix + "/meetingView/changes/fields/relate/parent_name", event.extendedProps.aName)

    if (this.useOrderLines) sAction.dsAdd("set", this.state.prefix + "/meetingView/orderLines", event.extendedProps.orderLines)

    // Calls
    sAction.dsAdd("set", this.state.prefix + "/callView/fields/name/value", title)
    sAction.dsAdd("set", this.state.prefix + "/callView/fields/name/value", title)
    sAction.dsAdd("set", this.state.prefix + "/callView/fields/duration_hours/value", durationHours)
    sAction.dsAdd("set", this.state.prefix + "/callView/fields/duration_hours/value", durationHours)
    sAction.dsAdd("set", this.state.prefix + "/callView/fields/duration_hours/def/minutesValue", durationMinutes)
    sAction.dsAdd("set", this.state.prefix + "/callView/fields/duration_hours/def/minutesValue", durationMinutes)
    sAction.dsAdd("set", this.state.prefix + "/callView/fields/date_start/value", eventStart)
    sAction.dsAdd("set", this.state.prefix + "/callView/fields/date_start/value", eventStart)

    sAction.dsAdd("set", this.state.prefix + "/callView/fields/assigned_user_name/value", this.props.userName)
    sAction.dsAdd("set", this.state.prefix + "/callView/fields/assigned_user_name/def/id_value", state.userID)
    sAction.dsAdd("set", this.state.prefix + "/callView/changes/fields/relate/assigned_user_name", this.props.userName)
    sAction.dsAdd("set", this.state.prefix + "/callView/changes/fields/assigned_user_id", state.userID)

    sAction.dsAdd("set", this.state.prefix + "/callView/fields/parent_name/value", event.extendedProps.aName)
    sAction.dsAdd("set", this.state.prefix + "/callView/fields/parent_name/def/parent_type_value", 'Accounts')
    sAction.dsAdd("set", this.state.prefix + "/callView/fields/parent_name/def/id_value", event.extendedProps.aID)
    sAction.dsAdd("set", this.state.prefix + "/callView/changes/fields/parent_type", 'Accounts')
    sAction.dsAdd("set", this.state.prefix + "/callView/changes/fields/parent_id", event.extendedProps.aID)
    sAction.dsAdd("set", this.state.prefix + "/callView/changes/fields/relate/parent_name", event.extendedProps.aName)

    // Tasks
    sAction.dsAdd("set", this.state.prefix + "/taskView/fields/name/value", title)
    sAction.dsAdd("set", this.state.prefix + "/taskView/fields/name/value", title)
    sAction.dsAdd("set", this.state.prefix + "/taskView/fields/duration_hours/value", durationHours)
    sAction.dsAdd("set", this.state.prefix + "/taskView/fields/duration_hours/value", durationHours)
    sAction.dsAdd("set", this.state.prefix + "/taskView/fields/duration_hours/def/minutesValue", durationMinutes)
    sAction.dsAdd("set", this.state.prefix + "/taskView/fields/duration_hours/def/minutesValue", durationMinutes)
    sAction.dsAdd("set", this.state.prefix + "/taskView/fields/date_start/value", eventStart)
    sAction.dsAdd("set", this.state.prefix + "/taskView/fields/date_start/value", eventStart)

    sAction.dsAdd("set", this.state.prefix + "/taskView/fields/assigned_user_name/value", this.props.userName)
    sAction.dsAdd("set", this.state.prefix + "/taskView/fields/assigned_user_name/def/id_value", state.userID)
    sAction.dsAdd("set", this.state.prefix + "/taskView/changes/fields/relate/assigned_user_name", this.props.userName)
    sAction.dsAdd("set", this.state.prefix + "/taskView/changes/fields/assigned_user_id", state.userID)

    sAction.dsAdd("set", this.state.prefix + "/taskView/fields/parent_name/value", event.extendedProps.aName)
    sAction.dsAdd("set", this.state.prefix + "/taskView/fields/parent_name/def/parent_type_value", 'Accounts')
    sAction.dsAdd("set", this.state.prefix + "/taskView/fields/parent_name/def/id_value", event.extendedProps.aID)
    sAction.dsAdd("set", this.state.prefix + "/taskView/changes/fields/parent_type", 'Accounts')
    sAction.dsAdd("set", this.state.prefix + "/taskView/changes/fields/parent_id", event.extendedProps.aID)
    sAction.dsAdd("set", this.state.prefix + "/taskView/changes/fields/relate/parent_name", event.extendedProps.aName)

    sAction.dsProcess()
    cb(true)
  }

  handleEventUpdate = (arg) => {
    // sAction.load()
    let event = arg.event

    var fields = {
      "date_start": moment(event.start).format('YYYY-MM-DD HH:mm:ss'),
      "date_end": moment(event.end).format('YYYY-MM-DD HH:mm:ss'),
    }
    var params = {
      "module": event.extendedProps.act_type,
      "checkInvCollision": true
    }

    sAction.calSaveActivity(event.id, fields, params, this.reschedCallBack/*, revertFunc*/)
  }

  handleEventClick = (arg) => {
    // sAction.load()  // nevim why ale na mobilku to zhodi cely komponent "magic"
    let event = arg.event

    if (event.extendedProps.orderLines && this.useOrderLines) {
      sAction.dsClear()
      sAction.dsAdd("set", this.state.prefix + "/meetingView/orderLines", event.extendedProps.orderLines)
      sAction.dsProcess()
    }

    // aaaaaand open! (Claptrap) - otevřít popup s detailem
    sAction.displayQuickEdit({
      module: event.extendedProps.act_type,
      record: event._def.publicId,
      prefix: this.state.prefix,
      color: event.extendedProps.act_type + 'CalTitle', // I am sooo sorry
      header: event.title
    })
  }

  handleEventDidMount = (arg) => {
    let event = arg.event
    let element = arg.el
    let view = arg.view
    // mesicni pohled proste nechce brat v potaz nastaveni pozadi primo na eventu TODO: zjistit proc

    //get secondary collor

    let primaryCollor = event.extendedProps?.eventBackgroundColor
    let statusCollor = '#1D1DCC' // TODO posilat taky rovnou z BE
    switch (event.extendedProps?.status) {
      case 'Held':
        statusCollor = event.extendedProps.act_type === 'Calls' ? '#4ACC00' : '#FFC30B'
        break
      case 'Not Held':
      case 'NotHeld':
      case 'Failed':
      case 'Deferred':
        statusCollor = '#E92222'
        break
      case 'Confirmed':
      case 'Success':
      case 'Completed':
        statusCollor = '#4ACC00'
        break
      case 'Planned':
      case 'Progress':
        statusCollor = '#1D1DCC'
        break
      default:
    }

    // if(view.type == this.defaultView) {
    element.style.background = 'linear-gradient(135deg, ' + statusCollor + ' 0%, ' + statusCollor + 'CC 17%, ' + primaryCollor + ' 33%)'
    // }

    if (event.extendedProps.act_type == "Meetings") {
      element.classList.add('eventMeeting')
      element.querySelector('.fc-event-title')
        ?.insertAdjacentHTML('beforebegin', "<div class='calEventIcon icon-Meetings' />")
    } else if (event.extendedProps.act_type == "Calls") {
      element.classList.add('eventCall')
      element.querySelector('.fc-event-title')
        ?.insertAdjacentHTML('beforebegin', "<div class='calEventIcon icon-Calls' />")
    } else if (event.extendedProps.act_type == "Tasks") {
      element.classList.add('eventTask')
      element.querySelector('.fc-event-title')
        ?.insertAdjacentHTML('beforebegin', "<div class='calEventIcon icon-Tasks' />")
    }
    if (event.extendedProps.assigned_user_id && sAction.dataGet("conf/user/orders_planner") === '1') {
      element.querySelector('.fc-event-title')
        ?.insertAdjacentHTML('afterend',
          '<div class="fc-event-assignedUser">' +
          '<div class="hr-line-solid-no-margin"></div>' +
          '<div class=\'calEventIcon icon-Users\'></div><span style="font-size: 0.9em">'
          + event.extendedProps.assigned_user_name +
          '</span>' +
          '</div>' +
          '</div>')
    }
    if (/*view.type !== "month" &&*/ event.extendedProps.description) {
      element.querySelector('.fc-event-title')
        ?.insertAdjacentHTML('afterend', '<div class="hr-line-solid-no-margin"></div>' +
          '<span class="eventDescriptionText" style="font-size: 0.9em">' + event.extendedProps.description + '</span></div>')
    }

    /** !!! Ocicko skryto, ale u nekterych zakazniku se jeste pouziva a u nekterzch budoucich se pouzivat bude, takze nemazat !!!  */
    // var adicon = $('<div />', {class: "icon-Eye", style: "width:15px; height: 15px; float:right; position:relative; z-index:10; margin-right: -2px; margin-top:4px;"})
    //
    // adicon.click(function(ev) {
    //     ev.stopPropagation();
    //     $("#adicon_box").remove();
    //     self.calGetAdditionalInfo(ev, event);
    // });
    let calendarApi = this[this.state.calRef].current.getApi()

    if (calendarApi.getCurrentData().currentViewType === "listACM") {
      if (event.extendedProps.act_type === "Meetings") {
        element.querySelector("a").insertAdjacentHTML('afterbegin', "<div class='calEventIcon agendaEvent icon-Meetings' />")
      } else if (event.extendedProps.act_type === "Calls") {
        element.querySelector("a").insertAdjacentHTML('afterbegin', "<div class='calEventIcon agendaEvent icon-Calls' />")
      } else if (event.extendedProps.act_type === "Tasks") {
        element.querySelector("a").insertAdjacentHTML('afterbegin', "<div class='calEventIcon agendaEvent icon-Tasks' />")
      }
    }

    element.style.minHeight = "20px"
    if (view.type === "agendaDay" && !event.allDay) {
      element.style.maxWidth = "450px"
    }
    element.classList.add("calNoBorder")
    // ziskat tridy podle stavu
    element.classList.add(sAction.calGetClassName(event.extendedProps.act_type, event.extendedProps.status))
  }

  handleDatesSet = (dateInfo) => {
    if (!this.state.ready) return //jinak se tato funkce volala asi 3x při initu

    let getFrom = moment(dateInfo.start).add(-1 * this.getMonthsBefore, "months").startOf('month').add(-1 * this.getDaysBefore, 'days').format("YYYY-MM-DD")
    let getTo = moment(dateInfo.end).endOf('month').add(this.getMonthsBefore, "months").add(this.getDaysAfter, 'days').format("YYYY-MM-DD")

    if ((moment(this.state.loadedDates.from) < moment(dateInfo.end) && moment(this.state.loadedDates.from) > moment(dateInfo.start))
    || (moment(this.state.loadedDates.to) > moment(dateInfo.start) && moment(this.state.loadedDates.to) < moment(dateInfo.end))) {
      // out of loaded range
      this.setState({ loaded: false })
    }

    clearTimeout(this.updateTimeout)
    this.updateTimeout = setTimeout(() => {
      this.calendarUpdateEvents(getFrom, getTo)
    }, 800)

  }

  handleDayClick = (events) => {
    if (!events) return
    let date = moment(events.date)
    const params = {
      date: date,
      prefix: this.state.prefix
    }

    if (events.view.type == this.defaultView) {
      const now = moment()
      date.set({
        hour: now.get('hour'),
        minute: now.get('minute')
      })
    }

    this.setActivityInitData(sAction, params)
    sAction.popup("calQuickCreate", { prefix: this.state.prefix })
  }

  setExternalEvents = () => {
    let draggableEl = document?.getElementById("external-eventsNew")
    if (draggableEl) { // [INF] pokud jsme na jine zalozce nez kde ty external eventy, tak jsou external skryte
      new Draggable(draggableEl, {
        itemSelector: ".fc-event",
        eventData: function (eventEl) {
          let eventData = sAction.fromJSON(eventEl.getAttribute("data-calendar"))
          return {
            title: eventData.title,
            id: eventData.id,
            extendedProps: eventData
          }
        }
      })

      // aby se eventy neoznacili jako draggable 2x, pak pri presunuti do calendare vyskocili dva popupy
      // kdyz probehne zmena, tak se prerenderuji a budou mit znovu .external-eventsNew
      draggableEl.id = 'external-events'
    }
  }
  changeView = view => {
    const way = this.props.way ? this.props.way + '/data/options' : "calendar/setting" // ?widgety:norm
    const calendarApi = this[this.state.calRef]?.current?.getApi()
    const params = sAction.dataGet(way)
    const viewToSet = view ?? this.defaultView;
    calendarApi.changeView(viewToSet)
    calendarApi.scrollToTime(params.get("startIntervalHours") + ":" + params.get("startIntervalMinutes"))
    calendarApi.setOption('businessHours', {
      dow: [1, 2, 3, 4, 5], // daysOfWeek
      startTime: params.get("startIntervalHours") + ":" + params.get("startIntervalMinutes"), // start time
      endTime: params.get("endIntervalHours") + ":" + params.get("endIntervalMinutes") // end time
    })
    calendarApi.setOption('firstDay', params.get("fdow") ?? 1)
    calendarApi.setOption('weekNumbers', params.get("weekNumbers"))
    calendarApi.setOption('weekText', sAction.translate("FC_WEEK_NUMBERS", "Calendar"))
    calendarApi.setOption('allDayText', sAction.translate("FC_ALL_DAY", "Calendar"))
    calendarApi.setOption('weekends', params.get("showWeekends"))
  }

  reschedCallBack() {
    // Zatim nemazat, nevime jestli u specif. zmen se nebude hodit
    // sAction.unLoad()
    // sAction.calGetEvents(true, (data) => {
    // this.setState({calendarEvents:data})// TODO proverit jestli neni potreba - mozna kvuli agende
    // });
  }

  setActivityInitData(self, params) {
    const start = params.date.format("YYYY-MM-DD HH:mm:ss")
    const end = params.date.add(15, 'minutes').format("YYYY-MM-DD HH:mm:ss")

    self.dsClear()
    // priprava dat do popupu
    // automaticke pridavani prirazeneho uzivatele v predefinedFields v kalendari zruseno, kvuli sdilenym kalendarum
    sAction.dsAdd("set", this.state.prefix + "/meetingView/fields/assigned_user_name/value", this.props.userName)
    sAction.dsAdd("set", this.state.prefix + "/meetingView/fields/assigned_user_name/def/id_value", this.state.userID)
    sAction.dsAdd("set", this.state.prefix + "/meetingView/changes/fields/relate/assigned_user_name", this.props.userName)
    sAction.dsAdd("set", this.state.prefix + "/meetingView/changes/fields/assigned_user_id", this.state.userID)
    sAction.dsAdd("set", this.state.prefix + "/meetingView/fields/date_start/value", start)
    sAction.dsAdd("set", this.state.prefix + "/meetingView/changes/fields/date_start", start)
    sAction.dsAdd("set", this.state.prefix + "/meetingView/fields/duration_hours/value", '0')
    sAction.dsAdd("set", this.state.prefix + "/meetingView/fields/duration_hours/def/minutesValue", '15')
    sAction.dsAdd("set", this.state.prefix + "/meetingView/changes/fields/duration_minutes", '15')
    sAction.dsAdd("set", this.state.prefix + "/meetingView/changes/fields/duration_hours", '0')
    if (this.useOrderLines) sAction.dsAdd("set", this.state.prefix + "/meetingView/orderLines", [])

    sAction.dsAdd("set", this.state.prefix + "/callView/fields/assigned_user_name/value", this.props.userName)
    sAction.dsAdd("set", this.state.prefix + "/callView/fields/assigned_user_name/def/id_value", this.state.userID)
    sAction.dsAdd("set", this.state.prefix + "/callView/changes/fields/relate/assigned_user_name", this.props.userName)
    sAction.dsAdd("set", this.state.prefix + "/callView/changes/fields/assigned_user_id", this.state.userID)
    sAction.dsAdd("set", this.state.prefix + "/callView/fields/date_start/value", start)
    sAction.dsAdd("set", this.state.prefix + "/callView/changes/fields/date_start", start)
    sAction.dsAdd("set", this.state.prefix + "/callView/fields/duration_hours/value", '0')
    sAction.dsAdd("set", this.state.prefix + "/callView/fields/duration_hours/def/minutesValue", '15')
    sAction.dsAdd("set", this.state.prefix + "/callView/changes/fields/duration_minutes", '15')
    sAction.dsAdd("set", this.state.prefix + "/callView/changes/fields/duration_hours", '0')

    sAction.dsAdd("set", this.state.prefix + "/taskView/fields/assigned_user_name/value", this.props.userName)
    sAction.dsAdd("set", this.state.prefix + "/taskView/fields/assigned_user_name/def/id_value", this.state.userID)
    sAction.dsAdd("set", this.state.prefix + "/taskView/changes/fields/relate/assigned_user_name", this.props.userName)
    sAction.dsAdd("set", this.state.prefix + "/taskView/changes/fields/assigned_user_id", this.state.userID)
    sAction.dsAdd("set", this.state.prefix + "/taskView/fields/date_start/value", start)
    sAction.dsAdd("set", this.state.prefix + "/taskView/fields/date_due/value", end)
    sAction.dsAdd("set", this.state.prefix + "/taskView/changes/fields/date_start", start)
    sAction.dsAdd("set", this.state.prefix + "/taskView/changes/fields/date_due", end)

    let action = {
      type: "CALENDARINITACTIVITY",
      content: {
        invitees: {
          Users: [],
          Contacts: [],
          Leads: []
        }
      },
      prefix: params.prefix + "/invitees/relatedInv"
    }
    self.dsAddCustom(action)
    self.dsProcess()
  }

  render() {
    console.count('calBody - render')
    let self = this
    let reload = sAction.dataGet('calendar/reload')

    if (reload) {
      this.mapLoadedInit = {}
    }

    // console.log('[INFO] calBody render()', { calBodyOfUserID: this.state.userID, reload: reload, rerender: this.state.rerender })

    // reloadovat jenom kdyz uz existuje nejaky kalenar ref
    if ((reload || this.state.rerender) && this[this.state.calRef].current) {
      this.calendarInit()
    }

    if (this?.props?.data?.calViewType) {
      this.changeView(this.props?.data.calViewType)
    }
    var picker = <div className={'hidden'} id={'clickMeIfYouDare' + this.state.userID}><AcmDate
      autoOpen={false}
      value={new Date()}
      onChange={(d) => this.DatePickerHandle(d)}
    /></div>

    // pro alternativni zobrazeni sdileneho kalendare
    // let height = this.props.viewsNumber?'calc((95vh - 150px) / ' + this.props.viewsNumber+')':'';
    let height = '100%'
    let overflow = 'auto'
    if (this.props.viewsNumber === 1) {
      height = this.props.viewsNumber ? 'calc((100vh - 130px) / ' + this.props.viewsNumber + ')' : ''
      overflow = 'unset'
    }


    const calendarApi = this[this.state.calRef]?.current?.getApi()
    let userName = this.props.userName
    // let bodyclass =
    return (
      <div className={'calBodyContainer' + (calendarApi?.view.type === 'agendaDay'?' sidewise':'')} style={{
        height: height,
        overflow: overflow
      }}/* kvuli sdilenemu pohledu*/>


        {(userName && this.props.sharedView) && (
          <div className={'fcCalendarTitle'}>{userName}</div>
        )}
        <div id={this.props.cssSelector}
          className={" calBoard scrollUnifier " + this.props.classBoard + " " + this.props.cssSelector}
          style={{ overflow: 'auto' }}
        >
          {picker}

          {!this.state.loaded &&
            <div className="lineLoaderContainer">
              <div className="lineLoader"></div>
            </div>
          }

          <FullCalendar
            ref={this[this.state.calRef]}
            locale="cs"
            initialView={this.props?.data?.settings?.get('initView') ?? this.defaultView}

            headerToolbar={{
              left: 'today',
              center: 'prev,title,next',
              right: 'datepicker',
            }}
            customButtons={{
              datepicker: {
                text: 'Datum',
                click: function () {
                  self.pickADate()
                }
              },
              userName: {
                text: sAction.dataGet('calendar/settings/users/' + this.state.userID),
              }
              //...other buttons
            }}

            buttonText={{
              today: 'Dnes',
            }}
            buttonIcons={{
              datepicker: 'i icon-calendar-month calHeaderIcon',
            }}
            views={{
              dayGridMonth: {
                displayEventTime: false,
              },
              month: { // kvuli starym instancim
                type: 'dayGridMonth',
                displayEventTime: false,
              },
              timeGridWeek: {
                slotDuration: "00:15:00",
                slotLabelInterval: "01:00",
                displayEventTime: true,

                // TODO jak upravit a pak i pro den... v locale to vyplnene je
                // weekNumberFormat: { week:"short"},
                // weekText: "T"
              },
              agendaWeek: { // kvuli starym instancim
                type: 'timeGridWeek',
                slotDuration: "00:15:00",
                slotLabelInterval: "01:00",
                displayEventTime: true,
              },
              timeGridDay: {
                slotDuration: "00:15:00",
                slotLabelInterval: "01:00",
              },
              agendaDay: { // kvuli starym instancim
                type: 'timeGridDay',
                slotDuration: "00:15:00",
                slotLabelInterval: "01:00",
              },
              listACM: { // je potreba list plugin
                displayEventTime: true,
                type: 'listMonth',
                duration: { days: 31 },
                buttonText: 'Agenda'
              },
            }}

            editable={true}
            allDaySlot={false}
            eventStartEditable={true}
            eventDurationEditable={true}
            displayEventTime={false}
            nowIndicator={true}
            eventTextColor={'#000000'}
            eventBorderColor={'#555555'}
            // timeFormat={'H:mm'}
            slotLabelFormat={{
              hour: 'numeric',
              minute: '2-digit',
              omitZeroMinute: true,
              // meridiem: 'short' // am / pm
            }}
            // minTime={"00:00:00"}
            // maxTime={"24:00:00"}
            // contentHeight={"auto"}
            weekNumberFormat={{ week: "numeric" }}

            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}

            events={this.state.calendarEvents ?? ''}

            dateClick={this.handleDayClick}
            eventDidMount={this.handleEventDidMount}
            eventClick={this.handleEventClick}
            eventResize={this.handleEventUpdate}// aktualizace pri roztahnuti eventu po kalendari
            eventDrop={this.handleEventUpdate} // aktualizace pri presunu eventu po kalendari
            eventReceive={this.handleEventReceive} // pokud pritahnu novy externi event
            eventAdd={this.handleEventAdd} // pokud pritahnu novy externi event
            datesSet={this.handleDatesSet} // pokud pritahnu novy externi event
          />
        </div>
      </div>
    )
  }

}

export default CalBody

CalBody.propTypes = {
  data: PropTypes.object,
  user: PropTypes.object, // ale realne ho nestoane a funguje
  way: PropTypes.string,
  userID: PropTypes.string,
  userName: PropTypes.string,
  classBoard: PropTypes.string,
  cssSelector: PropTypes.string,
  viewsNumber: PropTypes.number,
  sharedView: PropTypes.bool,
  language: PropTypes.string,
}
