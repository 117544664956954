export default function initData(data) {
    console.log(data);
    this.app_strings = data.app_strings;
    this.moduleTranslate = data.mod_strings;
    this.currencies = data.currencies;
    this.userPreferences = data.user.preferences;
    this.userPreferences.date_format = this.userPreferences.date_format.split('');
    this.userPreferences.time_format = this.userPreferences.time_format.split('');
    this.userPreferences.is_admin = data.user.is_admin;
    this.userPreferences.orders_planner = data.user.orders_planner;
    this.userPreferences.is_superUser = data.user.is_superUser;
    this.userPreferences.acl = data.acl;
    this.plugins = data.user.plugins;
    this.develMode = data.develMode;
    this.clientDetailEdit = data.clientDetailEdit;
    this.temporaryData = {};
    this.dsClear();
    this.initMenu(data.menuModules, data.actionPanel, data.subMenuModules);
    this.dsAdd('set', 'conf/language', data.language);
    this.dsAdd('set', 'notifications/notice', parseInt(data.notifyCount));
    this.dsAdd('set', 'conf/user/id', data.user.id);
    this.dsAdd('set', 'conf/user/name', data.user.name);
    this.dsAdd('set', 'conf/user/user_name', data.user.user_name);
    this.dsAdd('set', 'conf/user/is_admin', data.user.is_admin);
    this.dsAdd('set', 'conf/user/orders_planner', data.user.orders_planner);
    this.dsAdd('set', 'conf/user/email', data.user.email1);
    this.dsAdd('set', 'conf/user/is_superUser', data.user.is_superUser);
    this.dsAdd('set', 'conf/user/loginAs', data.user.preferences.loginAs);
    this.dsAdd('set', 'conf/user/currency', data.user.preferences.currency);
    this.dsAdd('set', 'conf/user/records_per_page', data.user.records_per_page);
    this.dsAdd('set', 'conf/system/systemName', data.systemName);
    this.dsAdd('set', 'conf/system/logo', data.logo);
    this.dsAdd('set', 'conf/system/headerLogo', data.headerLogo);
    this.dsAdd('set', 'conf/front_version', data.softwareInfo.front_version);
    this.dsAdd('set', 'conf/back_version', data.softwareInfo.back_version);
    this.dsAdd('set', 'conf/softwareName', data.softwareInfo.softwareName);
    this.dsAdd('set', 'conf/recordsPerPage', data.recordsPerPage);
    this.dsAdd('set', 'conf/isAcmAdmin', data.isAcmAdmin);
    this.dsAdd('set', 'conf/user/competence_role', data.user.competence_role);
    if (data.user.preferences.loginAs) {
        this.dsAdd('set', 'conf/alertMessage/show', true);
        this.dsAdd('set', 'conf/alertMessage/message', this.translate('LBL_LOGIN_AS_ACTIVE'));
        this.dsAdd('set', 'conf/alertMessage/buttonAction', 'logoutAs');
        this.dsAdd('set', 'conf/alertMessage/buttonLabel', this.translate('LBL_LOGOUT'));
    }
}
