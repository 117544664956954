import React from "react";

export default function hashChangeEvent(){
    window.addEventListener("hashchange", (e) => {
      // ulozeni dashboardu - predevsim razeni sloupcu
      if (this.dataGet("conf/view") === "home") {
        this.homeLayoutSave();
      }

      if (this.doRoute === true) {
          if (this.needSave(e.oldURL) == false || sessionStorage.getItem("actCloseCreateNewPredfinedFields") === "1") {
            if (sessionStorage.getItem("actCloseCreateNewPredfinedFields") === "1") {
              sessionStorage.removeItem("actCloseCreateNewPredfinedFields")
            }
            var view = this.getViewName();
            this.route(false,null,view == "login" ? "home" : null);
          } else {
            this.doRoute = false;
            window.location.hash = "#" + e.oldURL.split("#")[1];

            const data = {
              // Potvrzení
              header: this.translate("LBL_RECORD_IS_NOT_SAVED_HEADER"),
              //  color:"red",
              buttons: [
                // Zrušit
                { label: (<><div className='icon-quill buttonIcon'> </div> {this.translate("LBL_CONTINUE_EDIT", "Home")}</>), callback: () => this.popupHide() },

                // Pokračovat
                { label: (<><div className='icon-door buttonIcon'> </div> {this.translate("LBL_LEAVE_WITHOUT_SAVE", "Home")}</>), callback: () => {
                    //tento callback se vola pri kliknuti na pokracovat kdyz neni zaznam ulozen
                    if (localStorage.getItem('changes-'+this.dataGet("view/module")+'-'+this.dataGet('conf/user/id')))
                    {
                      this.removeChangesFromLS(this.dataGet("view/module"), this.dataGet('conf/user/id'))
                    }
                    //je potreba procistit store od dat z popupu
                    this.dsAdd("set","conf/popup/show",false);
                    this.dsAdd("set","conf/popup/content",null);
                    this.dsAdd("set","conf/popup/data",{});
                    this.dsProcess();
                    this.dataSet("view/changes", { fields: [], files: [] });
                    this.setStorage('stopChanges',1)
                    const imagesToDelete = this.dataGet("view/imageActions/deleteOnLeave");
                    if (imagesToDelete) {
                      this.rest.delete("deleteImages/", imagesToDelete.toJS(), () => {})
                    }
                    window.location.href = e.newURL;
                  },
                  className: "hoverRed",
                },
              ],
            };

            this.popup(this.translate("LBL_RECORD_IS_NOT_SAVED"), data);
            setTimeout(() => {
              this.doRoute = true;
            }, 200);
          }
        }
      });
}
