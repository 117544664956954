export default function reloadPriceLists(quoteInfo, prefix) {
    this.load()
    this.rest.post("reloadPriceLists", {
        quoteInfo: quoteInfo,
        productData: this.dataGet(`${prefix}/customData/productData`)
    }, returnData => {
        if (returnData.status) {
            if (returnData.message.data.accountData) {
                const accountData = returnData.message.data.accountData
                const formattedIds = formatIds(accountData?.accountMap?.ids)

                this.dsClear();
                this.dsAdd("set", `${prefix}/changes/fields/account_discount`, accountData.discount)
                this.dsAdd("set", `${prefix}/fields/account_discount/value`, accountData.discount)

                this.dsAdd("set", `${prefix}/changes/fields/account_discount_selection`, accountData.discount_selection)
                this.dsAdd("set", `${prefix}/fields/account_discount_selection/value`, accountData.discount_selection)

                this.dsAdd("set", `${prefix}/changes/fields/account_price_lists`, formattedIds)
                this.dsAdd("set", `${prefix}/fields/account_price_lists/value`, formattedIds)
                this.dsAdd("set", `${prefix}/fields/account_price_lists/def/options/id`, accountData.accountMap.ids ?? '')
                this.dsAdd("set", `${prefix}/fields/account_price_lists/def/options/names`, accountData.accountMap.names ?? '')

                this.dsAdd("set", `${prefix}/changes/fields/number_of_price_lists`, accountData.numOfRecords)
                this.dsAdd("set", `${prefix}/fields/number_of_price_lists/value`, accountData.numOfRecords)
                this.dsProcess();
            }

            returnData.message.data.productData?.groups.forEach((group, groupKey) => {
                group?.lines.forEach((line, lineKey) => {
                    this.dataSet(`${prefix}/customData/productData/groups/${groupKey}/lines/${lineKey}`, line, "product")
                })
            })
        }
        this.unLoad()
    })
}

const formatIds = (ids) => {
    if (ids.length === 0) {
        return ''
    }

    return `^${ids.join("^,^")}^`
}