import React from "react";

import Relate from "../Relate/Relate";
import Select from "../../formElements/Select";
import Input from "../../formElements/InputText";
import  sAction  from "sAction";
import Autocomplete from "../../formElements/Autocomplete";
import Tooltip from "@material-ui/core/Tooltip";

export default class Parent extends Relate {
  constructor(props) {
    super(props);
    this.state = {
      state: "fine",
      value: props.data.value,
      id: "",
      resultOpen: false,
      selectModule: null,
      options: [{ value: "", label: "" }],
      defaultValue: "",
      keyCode: null,
    };
    this.input = React.createRef();
    this.autocomplete = React.createRef();
  }

  deleteValue = () => {
    this.selectItem({ id: "", name: "" });
    this.moduleChange('');
  };

  selectItem(item, doUpdate = true) {
    const data = {
      way: this.props.way,
      name: this.props.data.def.get("id_name"),
      fieldName: this.props.data.name,
      type: "relate",
      value: item,
    };
    this.saveField(data, doUpdate);
  }

  openPopupList() {
    var self = this;
    const data = {
      module: this.getParentModule(),
      selectedActive: false
    };
    sAction.openRelatePopup(data, returnData => {
      self.selectItem(returnData);
      sAction.popupHide();
    });
  }

  newRecord() {
    const data = this.props.data;
    let module = data.get('def').get('parent_type_value');
    if (!module) {
      return;
    }

    sAction.popupDetail({
      module,
      record: "",
      saveCallback: ret => {
        const item = {
          id: ret.record,
          name: ret.name,
        };
        const data = {
          way: this.props.way,
          name: this.props.data.def.get("id_name"),
          fieldName: this.props.data.name,
          type: "relate",
          value: item,
          cancelEdit:true,
        };
        this.setState({
          id: item.id,
          name: item.name,
        });
        this.saveField(data, true);
        sAction.unLoad();
      },
      exitCallback: (data) => {},
    });
  }
  moduleChange(val) {
    if (val !== this.getParentModule()) {
      const data = {
        way: this.props.way,
        name: this.props.data.def.get("id_name"),
        fieldName: this.props.data.name,
        type: "parent",
        value: { id: null, name: null },
        parent_type: val,
        cancelEdit: false
      };
      this.setState({
        id: "",
        value: ""
      });
      if(this.input?.current?.value){
        this.input.current.value = "";
      }
      this.saveField(data, true, true);
    }
  }
  getOptions() {
    const data = this.props.data;
    const storedOptions = data.def;
    if (storedOptions.get("customOptions")) {
      this.setState({
        options: storedOptions.get("customOptions").toJS(),
        defaultValue: this.getParentModule()
      });
    } else {
      sAction.load();
      sAction.getParentFieldOptions(this.props.module, returnData => {
        sAction.unLoad();
        var options = [];
        returnData.forEach(option => {
          options.push({
            value: option,
            label: sAction.app_strings["moduleList"][option]
          });
        });
        this.setState({
          options: options,
          defaultValue: this.getParentModule()
        });
        sAction.dataSet(this.props.way + "/def/customOptions", options);
      });
    }
  }
  componentWillUnmount() {
    clearInterval(this.searchInterval);
  }

  // React 18 update componentWillMount -> componentDidMount
  componentDidMount() {
    this.getOptions();
  }

  render() {
    const data = this.props.data;
    const newRecord = this.props.newRecord;
    const ok = this.state.ok;
    var inputClass = "form-control form-control-sm";
    var containerClass = "inputEditContainer";
    if (ok === false) {
      inputClass += " wrongInput";
    }
    let error = false;
    const keyCode = this.state.keyCode;
    if((keyCode == 13 || keyCode === 9) && this.input.current.value !== this.state.value){
      error = sAction.translate("LBL_SELECT_ITEM");
    }


    var value = data.value;
    if (newRecord && (value === "" || value === undefined)) {
      value = "";
    }

    const containerClassField = "inputContainer relateField";

    value = data.value;
    if (newRecord && (value === "" || value === undefined)) {
      value = "";
    }
    this.state.defaultValue = this.getParentModule();

    return (
      <div className={containerClass} data-fieldname={data.name}>
        <div className="detailViewParentModuleSelect">
          <Select
            onKeyDown={e => this.onKeyDown(e, "first")}
            options={this.state.options}
            onChange={e => this.moduleChange(e.target.value)}
            defaultValue={this.state.defaultValue}
            autoFocus={true}
          />
        </div>
        <div className={containerClassField}>
          <Input
            onKeyDown={e => this.onKeyDown(e, "last")}
            onKeyUp={event => this.keyUp(event)}
            onFocus={() => this.focus()}
            onBlur={e => this.onBlur(e)}
            myRef={this.input}
            className={inputClass}
            type="text"
            id={data.name}
            defaultValue={value}
            autoComplete="off"
          />
          {this.state.resultOpen ? <Autocomplete
              data={data}
              resultOpen={this.state.resultOpen}
              searchText={this.state.searchText}
              searchMethod={"parent"}
              autocompleteReturnItem={this.storeRelate}
              addButton={true}
              module={this.state.defaultValue}
              newRecord={this.newRecord}
              ref={this.autocomplete}
          /> : null}
        </div>
        <div className="buttonContainer">
          {!!error &&
            (
              <div
                tabIndex="-1"
                className="warningMessage inputEditButton"
              >
                <Tooltip title={error} arrow open>
                  <div className="icon-warning" />
                </Tooltip>
              </div>

            )}
          <div
            tabIndex="1"
            onClick={() => this.openPopupList()}
            className="inputEditButton"
          >
            <div className={"icon-detailSearch"} />
          </div>
          <div
            tabIndex="2"
            onClick={() => this.deleteValue()}
            className=" inputEditButton"
          >
            <div className={"icon-detailCancel"} />
          </div>
        </div>
      </div>
    );
  }
}
