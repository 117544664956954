import detailDefault from "../../detailDefault";
import sAction from "../../../action";
export default class detailOpportunities extends detailDefault{
    load(sAction, data) {
        sAction.dsClear();
        const sales_stage = sAction.dataGet(data.prefix+"/fields/sales_stage/value");
        if( sales_stage == "Closed Lost" ||
            sales_stage == "Closed canceled client" ||
            sales_stage == "Closed canceled me"
            ){
                sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/readonly", false);
                sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/required", true);
                sAction.dsAdd("set", data.prefix+"/fields/pripominky_k_uzavreni_c/def/readonly", false);
            }
            else{
                if(sales_stage == "Closed Won"){
                    sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/readonly", false);
                    //sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/visible", false);
                }
                else{
                    sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/readonly", true);
                }
                sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/required", false);
            }

            if(sAction.getModuleName() == "Contacts" && data.prefix != "view"){
                const accData = sAction.dataGet("view/fields/account_name");
                const name = accData.get("value");
                const id = accData.getIn(["def","id_value"])
                if(id){
                sAction.dsAdd("set", data.prefix+"/fields/account_name/value", name);
                sAction.dsAdd("set", data.prefix+"/fields/account_name/def/id_value", id);
                sAction.dsAdd("set", data.prefix+"/changes/fields/account_id", id);
                }
            }
            sAction.dsProcess();
            this.hide_sales_stage_option(sAction, data.prefix)

            this.checkForDMS(sAction,data);
    }
    checkForDMS(sAction,data){
        let id = sAction.dataGet(data.prefix+"/id");
        const params = {
            id: id,
        };
        sAction.rest.post("checkForDMS", params, (returnData) => {
            if (returnData.status === true) {
                if (returnData.message.data.url){
                    this.setUrl(data,returnData.message.data?.url, returnData.message.data?.name_repaired, returnData.message.data.recordID)
                }
                else{
                    // kdyz se zaklada nove, tak se to poprve vrati bez url, proto volame jeste jednou.
                    // Neresim na BE bez sleep, protoze to v mezicase musi MS vytvorit.
                    sAction.rest.post("checkForDMS", params, (returnData2) => {
                        this.setUrl(data,returnData2.message.data?.url, returnData2.message.data?.name_repaired, returnData.message.data.recordID)
                    });
                }
            } else {
                this.alert(returnData.errorMessage?.text);
            }
        }, false);

    }
    setUrl(data, url, name, recordId){
        if(sAction.dataGet(data.prefix + "/id") !== recordId){
            return
        }
        sAction.dsClear();
        // sAction.dsAdd("set", data.prefix+"/fields/dms_id_c/value", returnData.message.data?.name_repaired);
        sAction.dsAdd("set", data.prefix+"/fields/dms_id_c/value", url);
        sAction.dsAdd("set", data.prefix+"/fields/dms_id_c/linkName", name);
        sAction.dsProcess();
    }
    update(sAction,data) {
        if(data.field == "account_id"){
            const name = sAction.dataGet(data.prefix+"/fields/name/value");
            if(name == null || name == ""){
                const nameAcc = sAction.dataGet(data.prefix+"/fields/account_name/value");
                sAction.dsClear();
                sAction.dsAdd("set",data.prefix+"/fields/name/value",nameAcc);
                sAction.dsAdd("set",data.prefix+"/changes/fields/name",nameAcc);
                sAction.dsProcess();
            }
        }
        else if(data.field == "sales_stage"){
            if( data.value == "Closed Lost" ||
                data.value == "Closed canceled client" ||
                data.value == "Closed canceled me"
            ){
                sAction.dsClear();
                sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/readonly", false);
                sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/required", true);
                sAction.dsAdd("set", data.prefix+"/fields/pripominky_k_uzavreni_c/def/readonly", false);

                sAction.dsProcess();
            }
            else{
                sAction.dsClear();
                if(data.value == "Closed Won"){
                    sAction.dsAdd("set", data.prefix+"/fields/pripominky_k_uzavreni_c/def/readonly", false);
                }
                else{
                    sAction.dsAdd("set", data.prefix+"/fields/pripominky_k_uzavreni_c/def/readonly", true);
                }

                sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/required", false);
                sAction.dsProcess();

            }
        } else if (data.field === "opportunity_type" && data.value !== data.prevValue){
            sAction.dataSet(data.prefix + "/fields/sales_stage/value", "")
            this.hide_sales_stage_option(sAction, data.prefix);
        }

    }

    /*
    Prevzato z opp_edit.php ze stareho sugaru + novinka meni se moznosti timeline dle stavu
     */
    hide_sales_stage_option(sAction, prefix) {
        const oppType = sAction.dataGet(prefix + "/fields/opportunity_type/value")
        let oppOptions = [];
        let ignoreStates = []
        let firstOption = ''

        if (oppType === "analyza") {
            firstOption = "Prospecting"
            oppOptions = [
                {label: "Vstupní rozhovor", value: "Prospecting"},
                {label: "Nabídka", value: "Proposal/Price Quote"},
                {label: "Příprava smlouvy", value: "priprava_smlouvy"},
                {label: "Někdy / Možná", value: "Sleeping"},
                {label: "Vyhráli jsme", value: "Closed Won"},
                {label: "Prohráli jsme", value: "Closed Lost"},
            ]

            ignoreStates = ['Qualification',
                'indikativni_nabidka',
                'Perception Analysis',
                'Value Proposition',
                'Needs Analysis',
                'podpis_smlouvy',
                'Negotiation/Review',
                'sleeping_nakup_prodej',
                'balicek_informaci',
                'Imported',
                'jine',]

        } else if (oppType === "Zprostredkovani") {
            firstOption = "jine"
            oppOptions = [
                {label: "Jiné", value: "jine"},
                {label: "Vstupní rozhovor", value: "Prospecting"},
                {label: "Nabídka", value: "Proposal/Price Quote"},
                {label: "Jednání o nabídce", value: "Perception Analysis"},
                {label: "Jednání přerušena", value: "Negotiation/Review"},
                {label: "Sleeping nákup / prodej", value: "sleeping_nakup_prodej"},
                {label: "Vyhráli jsme", value: "Closed Won"},
                {label: "Prohráli jsme", value: "Closed Lost"},
            ]

            ignoreStates = ['Qualification',
                'indikativni_nabidka',
                'Value Proposition',
                'Needs Analysis',
                'priprava_smlouvy',
                'podpis_smlouvy',
                'balicek_informaci',
                'Sleeping',
                'Imported',]

        } else if (oppType === "Existing Business" || oppType === "New Business") {
            firstOption = "jine"
            oppOptions = [
                {label: "Jiné", value: "jine"},
                {label: "Vstupní rozhovor", value: "Prospecting"},
                {label: "Teaser / NDA", value: "Qualification"},
                {label: "Analýza transakce", value: "Needs Analysis"},
                {label: "Indikativní nabídka", value: "indikativni_nabidka"},
                {label: "Příprava transakční dokumentace", value: "Value Proposition"},
                {label: "Podpis smlouvy", value: "podpis_smlouvy"},
                {label: "Jednání přerušena", value: "Negotiation/Review"},
                {label: "Někdy / Možná", value: "Sleeping"},
                {label: "Vyhráli jsme", value: "Closed Won"},
                {label: "Prohráli jsme", value: "Closed Lost"},
            ]

            ignoreStates = ['Perception Analysis',
                'Proposal/Price Quote',
                'priprava_smlouvy',
                'sleeping_nakup_prodej',
                'balicek_informaci',
                'Imported',]
        }

        if (oppOptions.length > 0) {
            sAction.dsClear()
            sAction.dsAdd("set", prefix + '/fields/sales_stage/def/customOptions', oppOptions);
            sAction.dsAdd("set", prefix + '/ignoreTimeLineStates', ignoreStates);
            if(!sAction.dataGet(prefix + '/fields/sales_stage/value')) {
                sAction.dsAdd("set", prefix + '/fields/sales_stage/value', firstOption);
                sAction.dsAdd("set", prefix + 'changes/fields/sales_stage', firstOption);
            }
            sAction.dsProcess()
        }
    }
}
