export default function calSaveActivity(record, fields, params, callback, callbackFail = null){

    var req = {
        "record" : record,
        "fields" : fields,
        "params" : params
    }

    var self = this;
    self.rest.post("saveActivity", req, function(data){
        // if(data.retCode == "OK"){
        if(data.status){
            callback(self);
        }
        else{
            self.error(data.errorMessage.text);
            if(callbackFail !== null){
                callbackFail();
            }
        }
        
    });
    
}
