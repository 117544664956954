import React from 'react';

// material
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// ikony
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PureComponent from '../pure';

import sAction from "sAction";

/**
 * CHILDREN
 */
import HomeWidgetEdit from '../popup/home/HomeWidgetEdit';
import HomeWidgetEditCalendar from '../popup/home/HomeWidgetEditCalendar';

import MoreOptions from '../formElements/MoreOptions'

/**
 * @parent  src\components\home\HomeColumns.js
 * @props   name, way, index
 *
 * zobrazuje zahlavi boxu na HP, kde je titulek a tlacitka pro prejmenovani, aktualizaci a zavreni boxu
 */
export default class HomeWidgetHeader extends PureComponent {
  state = {
    anchorEl: null
  };

  // otevreni kontext. menu
  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  // zavreni kontext. menu
  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  // vymazani boxu
  handleDelete = () => {
    this.handleClose();

    sAction.homeWidgetDelete(this.props.way, this.props.index);
  };

  handleDownloadSVG = () => {
    this.handleClose();

    // SVG je zatim nadlidsky ukol z duvodu jeho komplikovanosti...

    // var svg = document.querySelector(".chart1 svg");

    // var xml = new XMLSerializer().serializeToString(svg);

    // // make it base64
    // var svg64 = btoa(xml);
    // var b64Start = "data:image/svg+xml;base64,";

    // // prepend a "header"
    // var image64 = b64Start + svg64;

    // console.log(image64);
    // //img.src = dataURI;

    // return;

    // lnk.download = "nazev.png";
    // lnk.href = canvas.toDataURL("image/png;base64"); //canvas
    // lnk.href = dataURI; //svg

    // //stazeni
    // document.body.appendChild(lnk); //jinak to nefunguje ve Firefoxu
    // lnk.click();
    // document.body.removeChild(lnk);

    // console.log(lnk);
  };

  // stazeni grafu pres Canvas
  handleDownloadCanvas = () => {
    this.handleClose();

    const canvas = document.querySelector(`.${this.props.chartId} canvas`);
    const lnk = document.createElement('a');
    lnk.download = `Graf ${this.props.name}.png`.replace(/[\\/:"*?<>|]/g, '_'); // replace pro jistotu odstrani z nazvu nepovolene znaky ve Windows
    lnk.href = canvas.toDataURL('image/png;base64'); // canvas

    // stazeni
    document.body.appendChild(lnk); // jinak to nefunguje ve Firefoxu
    lnk.click();
    document.body.removeChild(lnk);
  };

  // upraveni widgetu - popup
  handleEdit = () => {
    this.handleClose();

    const way = this.props.way + "/" + this.props.index;
    const type = this.props.type
    if (type == "calendar") {
      sAction.popup(<HomeWidgetEditCalendar way={way} name={this.props.name} />);
    }
    else {
      sAction.popup(<HomeWidgetEdit type={type} way={way} name={this.props.name} />);
    }

  };

  // aktualizace widgetu
  handleRefresh = () => {
    this.handleClose();

    const way = this.props.way + "/" + this.props.index;
    const type = this.props.type
    sAction.homeWidgetRefresh(type, way);
  };

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const options = [
      { label: sAction.translate('LBL_EDIT_WIDGET', 'Home'), value: "handleEdit", icon: "icon-addWidget" },
      { label: sAction.translate('LBL_CLEAR', 'Home'), value: "handleDelete", icon: "icon-deleteIcon" },
    ]
    if (this.props.chartId) {
      options.push({ label: sAction.translate('LBL_DOWNLOAD_CHART', 'Home'), value: "handleDownloadCanvas" })
    }
    options.push({ label: sAction.translate('LBL_REFRESH', 'Home'), value: "handleRefresh", icon: "icon-refresh" })

    return (
      <div className="homeCardHeader" data-name={`${this.props.way}/${this.props.index}`}>
        <div className="homeCardHeaderContent homeDragAndDropHolder">{this.props.name}</div>

        <div className="">
          <MoreOptions
            onRight={true}
            fromRight={100}
            onClick={(e, val) => this[val](e)}
            options={options}
          >
            <div className="MoreOptions icon-More" />
          </MoreOptions>
        </div>
      </div>
    );
  }
}
