//productSelector
import React from "react";
import PureComponent from "../../pure";


import ProductSelectorList from './productSelectorList';


import PtItem from "./ptItem";

import sAction from "sAction";


//ExpansionPanel
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

export default class ProductSelector extends PureComponent {

    constructor() {
        super();
        this.state = {
            showList: true,
            showGroups: true
        }
    }

    handleProdTemplSel(item, way, detailWay) {
        const searchData = {
            fields: ["id"],
            getFields: ["id", "name", "cost_price", "list_price", "discount_price", "currency_id", "description", "mft_part_num", "mft_part_num_int", "picture"],
            value: item.id,
            module: "ProductTemplates"
        };

        sAction.getProductTemplate(item.id, 'addProdLine', way);

        // sAction.quickSearch(searchData, data => {
        //     var line = data[0];
        //     if(data.length > 0){
        //         // const currency_id = sAction.dataGet(detailWay+"/customData/currency_id");
        //         const currency_id = sAction.dataGet(detailWay+"/fields/currency_id/value");
        //         line.cost_price = sAction.currencyConvert(line.cost_price, line.currency_id, currency_id);
        //         line.list_price = sAction.currencyConvert(line.list_price, line.currency_id, currency_id);
        //         var newItem = line;
        //         newItem.product_template_id = newItem.id;
        //         newItem.id = "";
        //         sAction.updateDetailCustom("addProdLine", {way: way, item:newItem});
        //     }
        // });
    }

    /*  categoryChange(id){
          const way = "rightPanel/data/listView";
          sAction.dataSet(way+"/listViewTree/activeCategory",id);
          sAction.reloadList(0,1,way);
          /*
          const way = this.props.way;
          sAction.dataSet(way+"/listViewTree/activeCategory",id);
          sAction.reloadList(0,1,way);
     
      }     */

    /*
    <DragAndDropCoripo
                containersClass=".favDragDropCont"
                elementsClass=".ptItem"
               // handleElementClass=".moveHolder"
                wayPrefix=""
               //moveStart={data => this.moveStart(data)}
                afterEnd={data => this.moveEnd(data)}
                moveObjectClass="subpanelMove"
                >*/

    render() {
        const data = this.props.data;
        var self = this;
        const detailWay = this.props.detailWay;

        var favComp = [];
        data.favorites.forEach(function (fav, ind) {
            favComp.push(<PtItem key={ind} data={fav} pomKey={ind} onClick={(selData) => self.handleProdTemplSel(selData, data.groupWay, detailWay)} />);
        });

        var topComp = [];
        data.top.forEach(function (top, ind) {
            topComp.push(<PtItem key={ind} data={top} pomKey={ind} onClick={(selData) => self.handleProdTemplSel(selData, data.groupWay, detailWay)} />);
        });

        var recentComp = [];
        data.recent.forEach(function (recent, ind) {
            recentComp.push(<PtItem key={ind} data={recent} pomKey={ind} onClick={(selData) => self.handleProdTemplSel(selData, data.groupWay, detailWay)} />);
        });


        var listClass = "";

        if (!this.state.showList) {
            listClass += " hidden";
        }
        var groupClass = "";
        if (!this.state.showGroups) {
            groupClass += " hidden";
        }

        let customFooter = <>
            <ExpansionPanel defaultExpanded={false} className="prodSelectoracmContainer">
                <ExpansionPanelSummary className="acmExpansionPanelSummary" expandIcon={<ExpandMoreIcon />}>
                    <div className="prodSelGroupTitle">{sAction.translate("LBL_SECTION_RECENT", "Quotes")}</div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="acmExpantionPanelDetails">
                    <div className={"prodSelectorGroupContainer" + groupClass} >
                        {/* <div className="favContainer">

                            <div className="prodSelGroup favDragDropCont" data-way="rightPanel/data/favorites">
                                <div className="prodSelGroupTitle">{sAction.translate("LBL_SECTION_FAVORITES", "Quotes")}</div>
                                <div className="prodSelGroupItemContainer">
                                    {favComp}
                                </div>
                            </div>
                        </div> */}
                        
                        <div className="prodSelGroup">
                            <div className="prodSelGroupItemContainer">
                                {recentComp}
                            </div>
                        </div>
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel defaultExpanded={false} className="prodSelectoracmContainer">
                <ExpansionPanelSummary className="acmExpansionPanelSummary" expandIcon={<ExpandMoreIcon />}>
                    <div className="prodSelGroupTitle">{sAction.translate("LBL_SECTION_TOP", "Quotes")}</div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="acmExpantionPanelDetails">
                    <div className={"prodSelectorGroupContainer" + groupClass} >
                        <div className="prodSelGroup">
                            <div className="prodSelGroupItemContainer">
                                {topComp}
                            </div>
                        </div>
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </>

        return (
            <div className="prodSelector">
                <div className={"prodSelectorList" + listClass}>
                    <ProductSelectorList data={data.listView} selectCallBack={(selData) => self.handleProdTemplSel(selData, data.groupWay, detailWay)} customFooter={customFooter} />
                </div>
            </div>
        );
    }
}