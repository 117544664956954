
import detailDefault from "../../detailDefault";
export default class detailQuotes extends detailDefault{
    load(sAction,data) {
        this.changeLossReasonVisibility(sAction, data)
    }
    update(sAction,data) {
        switch (data.field) {
            case "danova_sazba":
                sAction.dataSet(data.prefix + "/customData/productData/dph", data.value);
                break;
            case "currency_id":
                this.changeCurrencyValues(sAction, data);
                break;
            case "kurz":

                const currency_id = sAction.dataGet(data.prefix+"/fields/currency_id/value");
                if (currency_id && currency_id !== "-99") {
                    // kdyz se meni kurz v eurech napr. z 25 Kc na 20 Kc - nabidka se prepocita dle defaultni meny
                    sAction.dataSet(data.prefix+"/fields/kurz/value", data.prevValue);

                    // this.changeCurrencyValues(sAction, {prefix: data.prefix, value: "-99"});
                    sAction.saveField({
                        way: data.prefix + "/fields/currency_id",
                        name: "currency_id",
                        value: "-99"
                    }, true);

                    sAction.dataSet(data.prefix+"/fields/kurz/value", data.value);

                    // this.changeCurrencyValues(sAction, {prefix: data.prefix, value: currency_id});
                    sAction.saveField({
                        way: data.prefix + "/fields/currency_id",
                        name: "currency_id",
                        value: currency_id
                    }, true);
                }

                break;
            case "billing_account_id":
                if (data.value.id) {
                    sAction.reloadPriceLists({accountId: data.value.id, quoteId: sAction.dataGet(`${data.prefix}/id`)}, data.prefix);
                }
                break;
            case "cenotvorba_discount":
                this.updateGroups(sAction, data.prefix, data.value,
                    sAction.dataGet(`${data.prefix}/fields/cenotvorba_discount_select/value`), sAction.dataGet(`${data.prefix}/fields/discount_field/value`))
                break;
            case "cenotvorba_discount_select":
                this.updateGroups(sAction, data.prefix, sAction.dataGet(`${data.prefix}/fields/cenotvorba_discount/value`),
                    data.value, sAction.dataGet(`${data.prefix}/fields/discount_field/value`))
                break;
            case "discount_field":
                this.updateGroups(sAction, data.prefix, sAction.dataGet(`${data.prefix}/fields/cenotvorba_discount/value`),
                    sAction.dataGet(`${data.prefix}/fields/cenotvorba_discount_select/value`), data.value)
                break;
            case "quote_stage":
                this.changeLossReasonVisibility(sAction, data)

        }
    }

    /**
     * Kontrola před uložením
     Musí být vyplněn alespoň jeden produkt, nelze uložit nabídku bez produktu(textový, katalog)
     * Pokud je nektera cena z nabidky pod kompetencni cenou nesmime nechat ulozit
     * @param sAction
     * @param data
     * @returns {boolean}
     */
    beforeSave(sAction, data) {
        if (sAction.dataGet(`${data.prefix}/isUnderCompetencePrice`)) {
            sAction.error(sAction.translate("LBL_CANT_SAVE_COMPETENCE"));
            return false
        }

        let groups = sAction.dataGet(data.prefix + '/customData/productData/groups');

        if (groups) {
            groups = groups.toJS();
        }

        if (sAction.deviceType == "mobile") {
            return true;
        }

        let allowSave = false;
        groups.forEachObject((value, key) => {
            value['lines'].forEachObject((line, lKey) => {
                if (line['name']) {
                    allowSave = true;
                }
            });
        });
        if (!allowSave) {
            sAction.error(sAction.translate('LBL_SAVE_PREVENTED_MISSING_PRODUCT'));
        }
        return allowSave;
    }


    changeCurrencyValues(sAction, data) {
        // prepocitej produkty v nabidce podle zadaneho kurzu...

        if (!data.value && data.prevValue === "-99" || data.value === "-99" && !data.prevValue) {
            return;
        }

        if (!data.value) {
            data.value = "-99";
        }

        let kurz = 0;
        let kurz_field_value = sAction.dataGet(data.prefix + "/fields/kurz/value");
        if (kurz_field_value && parseFloat(kurz_field_value)) {
            kurz = kurz_field_value;
        }

        sAction.dataSet(data.prefix+"/customData/productData/currency_id", data.value);

        const groups = sAction.dataGet(data.prefix + "/customData/productData/groups");
        sAction.dsClear();
        let group_i = 0;
        for (let group of groups) {
            let line_i = 0;
            for (let line of group.lines) {

                let list_price = sAction.currencyConvert(line.list_price, data.prevValue, data.value, kurz);
                let cost_price = sAction.currencyConvert(line.cost_price, data.prevValue, data.value, kurz);
                let discount_amount = sAction.currencyConvert(line.discount_amount, data.prevValue, data.value, kurz);

                sAction.dsAdd("set", data.prefix + "/customData/productData/groups/" + group_i + "/lines/" + line_i + "/list_price", list_price);
                sAction.dsAdd("set", data.prefix + "/customData/productData/groups/" + group_i + "/lines/" + line_i + "/cost_price", cost_price);
                if (line.discount_select == 0) {
                    sAction.dsAdd("set", data.prefix + "/customData/productData/groups/" + group_i + "/lines/" + line_i + "/discount_amount", discount_amount);
                }
                line_i++;
            }
            group_i++;
        }
        sAction.dsProcess();
    }

    changeLossReasonVisibility(sAction, data){
        const lossStates = ["Closed Lost", "Closed canceled client", "Closed canceled me"]
        if(lossStates.includes(sAction.dataGet(`${data.prefix}/fields/quote_stage/value`))){
            sAction.dataSet(`${data.prefix}/fields/loss_reason/def/visible`, true);
            return
        }

        sAction.dataSet(`${data.prefix}/fields/loss_reason/def/visible`, false);
    }

    /**
     * Pokud je nektera cena z nabidky pod kompetencni cenou nesmime nechat ulozit
     * @param sAction
     * @param data
     * @returns {boolean}
     */

    updateGroups(sAction, prefix, discount, discountType, discountField){
        if(discount && discountType && discountField){
            const groups = sAction.dataGet(`${prefix}/customData/productData/groups`)
            groups.forEach((value, index) => {
                sAction.dsClear()
                sAction.dsAdd("set", `${prefix}/customData/productData/groups/${index}/deal_tot`, discount)
                sAction.dsAdd("set", `${prefix}/customData/productData/groups/${index}/cenotvorba_discount_select`, discountType)
                sAction.dsAdd("set", `${prefix}/customData/productData/groups/${index}/discount_field_select`, discountField)
                sAction.dsProcess()
            })
        }
    }
}

