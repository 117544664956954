import React from 'react';

import sAction from 'sAction';

export default function sweetSpotHelp() {
    const rows = [];
    const commands = sAction.getEnumForSelect('sweetspot_commands_list');
    commands.forEach((command, key) => {
        rows.push(<tr key={key}><td>{command.value}</td><td>{command.label}</td></tr>);
    });
    const content = (
        <React.Fragment>
            {sAction.translate('LBL_SWEETSPOT_HELP_TEXT')}<br />
            {sAction.translate('LBL_SWEETSPOT_HELP_COMMAND_HEADER')}<br />
            <table className='simpleForm sweetspotHelp'>
                <tbody>
                    {rows}
                </tbody>
            </table>
            {sAction.translate('LBL_SWEETSPOT_HELP_FILTER')}<br />
        </React.Fragment>
    );
    const data = {
        content: content,
        header: sAction.translate('LBL_SWEETSPOT_HELP_HEADER'),
        selector: '.icon-help-circle',
        className: 'sweetspot',
    };
    setTimeout(() => {
        sAction.popper(data);
    }, 30);
}
