export default function repairSubpanelHeight(actTable){
    if(actTable == null) {
        return;
    }
    if (actTable.closest(".maxSize")) { // subpanel full screen view - vyska popupu
        return;
    }
    actTable = actTable.parentElement;
    const row = actTable.querySelector(".newListViewRow");
    if(row == null){
        return;
    }
    const subpanelLine = row.querySelector(".newListViewLine");
    var lineHeight = 0;
    if(subpanelLine !== null){
    lineHeight = row.querySelector(".newListViewLine").clientHeight;
    }
    const children = row.childNodes.length; // +1 protože ještě header
    
    const header = actTable.querySelector(".newListViewHeader");
    const headerHeight = header.clientHeight
    
    var height = children * lineHeight + headerHeight;
    if(height == headerHeight){
        height = headerHeight +20;
    }else{
        height += 15;
    }

    const tree = actTable.querySelector(".listViewTableTree");
    if(tree != null && height < 200){
        height = 200;
    }
    actTable.style.minHeight = height + "px";
}