import React from "react";

import PureComponent from "../pure";
import FilterVarchar from "./filterFieldsAdvanced/filterVarchar";
import FilterEnum from "./filterFieldsAdvanced/filterEnum";
import FilterRelate from "./filterFieldsAdvanced/filterRelate";
import FilterNumber from "./filterFieldsAdvanced/filterNumber";
import FilterActivity from "./filterFieldsAdvanced/filterActivity";
import FilterDate from "./filterFieldsAdvanced/filterDate";
import FilterParent from "./filterFieldsAdvanced/filterParent";
import ResizeAnimation from "../animation/ResizeAnimation";
import TranslateAnimation from "../animation/TranslateAnimation";

class ListViewFilterContainer extends PureComponent {
  constructor() {
    super();
    this.state = {
      left: window.innerWidth,
      triangleLeft: 100,
      width: 200,
    }
  }

  componentDidMount() {
    var actFilter = this.props.actFilter;
    if (actFilter == null || actFilter.actCustom == false) {
      this.normal();
    } else {
      this.advanced();
    }
  }
  componentDidUpdate() {
    const actFilter = this.props.actFilter;
    if (actFilter == null || actFilter.actCustom == false) {
      this.normal();
    } else {
      this.advanced();
    }
  }
  fixPosition() {
    var container = this.refs.container;
    var parent = this.refs.content.closest(".newListView");
    var parentOffset = parseInt(window.getComputedStyle(container.closest(".newListViewContainer"), null).getPropertyValue('padding-left')) || 0;
    let categoryOffset = 0;
    let categories = parent.parentNode.querySelector(".listViewTableTree");
    if (categories) {
      categoryOffset = parseInt(window.getComputedStyle(categories, null).getPropertyValue('width')) || 0
      parentOffset += categoryOffset;
    }
    var offset = this.props.offset + parentOffset;
    var newLeft = offset - this.state.width / 2;

    if (newLeft < 10) {
      newLeft = 10;
    }
    if (newLeft + this.state.width > parent.clientWidth) {
      newLeft = parent.clientWidth - this.state.width + categoryOffset;
    }
    const triangleLeft = offset - newLeft - 13;
    this.setState({left: newLeft, triangleLeft: triangleLeft});
  }
  advanced() {
    this.setState({width: 380});
    this.fixPosition();
  }
  normal() {
    this.setState({width: 200});
    this.fixPosition();
  }
  render() {
    const def = this.props.def;
    const open = this.props.open;
    const dbType = def.get("dbType");
    const type = def.get("type");
    const actFilter = this.props.actFilter;
    const prefix = this.props.prefix;
    const way = this.props.way;
    const index = this.props.index;
    const fieldRel = this.props.fieldRel;
    var content = null;


    const params = {
      prefix: prefix,
      way: way,
      index: index,
      actFilter: actFilter,
      data: def,
      fieldRel : fieldRel,
      parent: this,
    };

    if (dbType == "varchar" && type !== "AcmDynamicEnum" || type === "varchar") {
        content = (
          <FilterVarchar
            {...params}
          />
        );
    } else if (type === "enum" || type === "multienum" || type === "AcmDynamicEnum") {
      content = (
        <FilterEnum
          {...params}
        />
      );
    } else if (type == "relate") {
      content = (
        <FilterRelate
          {...params}
        />
      );
    } else if (type == "currency" || type ==  "int" || type ==  "decimal") {
      content = (
        <FilterNumber
          {...params}
        />
      );
    } else if (type == "icon") {
      content = <FilterActivity 
        {...params}
      />;
    } else if (type == "date" || type == "datetime" ||  type == "datetimecombo" ) {
      content = (
      <FilterDate
        {...params}
      />
      )
    } else if(type == "parent"){
      content = (
        <FilterParent
          {...params}
        />
      );
    }else {
      content = (
        <FilterVarchar
          {...params}
        />
      );
    }

    var containerClass = "listViewFilterContainer";
    if (open == false) {
      containerClass += " hidden";
    }

    return (
      <TranslateAnimation className={containerClass} left={this.state.left} top={40}>
        <div ref="container">
          <TranslateAnimation className="triangle" left={this.state.triangleLeft}>
            <div className="triangleInner" />
          </TranslateAnimation>
          <ResizeAnimation className="content">
            <div style={{width: `${this.state.width}px`}} ref="content" tabIndex="0">
              {content}
            </div>
          </ResizeAnimation>
        </div>
      </TranslateAnimation>
    );
  }
}
export default ListViewFilterContainer;
