export default function calToggleSharedView(){

    var state = !this.calEvents.sharedView;

    this.calEvents.sharedView = state;

    let calSettings = this.dataGet('calendar/setting').set('sharedView', state)
    this.saveCalSettings(calSettings, (data) => {
        // menime komplet nastaveni uzivatele, protoze jinak si to pri dalsim prenacteni prepiseme.
        // V budoucnu tam udelat nejakou docasnou picovinu{this.calEvents.sharedView}, ale nevim jak to vyuzit v calBody, aby se to bralo pri prerenderovani
        // neni nutne upgradovat, ale behalo by to rychleji s tim, ze by jsme si v pameti drzeli vice dat
    })
}
