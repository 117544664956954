import React from "react";
import PureComponent from "../pure";

import ListViewTable from "../list/listViewTable2";
import SubpanelHeader from "./subpanelHeader";
import  sAction  from "sAction";
import Loader from "../loader";
import DropdownAnimation from '../animation/DropdownAnimation';

export default class Subpanel extends PureComponent {
  constructor(props) {
    super(props);

    this.subpanel = React.createRef();
  }
  componentDidUpdate() {
    const subpanel = this.subpanel.current;
    const actTable = subpanel.querySelector(".newListView");
    const container = subpanel.closest(".subpanelContainer");
    if (!container.classList.contains("maxSize")) {
      sAction.repairSubpanelHeight(actTable);
    }
  }
  maxSizeClose(e) {
    const way = this.props.way;
    const target = e.target;
    
    if (target.classList && target.classList.contains("maxSize")) {
      target.classList.remove("maxSize");
      sAction.dataSet(way + "/rows/limit", 10);
      sAction.reloadList(0, 1, way + "/rows");
    }
  }
  subpanelLineMoreAction(e, val) {
    const data = this.props.data;
    const way = this.props.way;
    const prefix = this.props.prefix;
    if (val.action == "removeRel") {
      sAction.subppanelChangeRelate(val, data, prefix, way, 1)
    } else if (val.action === "removeRecord") {
      data.id = val.record.record
      sAction.deleteRecordSubpanel(val,data)
    }
  }

  render() {
    const data = this.props.data;
    const way = this.props.way;
    var records = 0;
    try {
      records = data.rows.rows.get(0).records.size;
    } catch (e) {}

    const page = data && data.rows && data.rows.page ? data.rows.page : 1;


    var openSubpanel = false;
    if(page != 1 || records > 0 ||
    data.rows.actFiltering == true || data.rows.listViewTree.module != null){
      openSubpanel = true;
    }
    
    if(data.def.get("open") === false){
      openSubpanel = false
    }

    if(data.def.get("load")) {
      openSubpanel = true;
    }

    const readonly = data.def.get("readonly");

    return (
      <div
        className="subpanelContainer"
        data-way={way}
        onClick={e => this.maxSizeClose(e)}
      >
        <div
          className="subpanel"
          ref={this.subpanel}
        >
          <SubpanelHeader
            data={data}
            way={way}
            prefix={this.props.prefix}
            records={records}
            iconColor={this.props.iconColor}
            acl={this.props.acl}
          />
          <DropdownAnimation className="subpanelBody" open={openSubpanel}>
            {data.def.get("load") ?
              <div className="subpanelLoader">
                <Loader />
              </div> :
              <ListViewTable
                data={data.rows}
                parent={this}
                prefix={way + "/rows"}
                acl={this.props.acl}
                readonly={readonly}
              />
            }
          </DropdownAnimation>
        </div>
      </div>
    );
  }
}
