import React from 'react';
import InputField from '../inputFieldClass';

import Select from '../../formElements/Select';
import InputText from '../../formElements/InputText';

import sAction from 'sAction';

export default class AcmDynamicEnum extends InputField {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            options: null,
            type: 'enum',
            inputError: false,
        };
        this.input = React.createRef();
    }
    componentDidMount() {
        sAction.load();
        const data = this.props.data;
        const actLang = sAction.dataGet('conf/language');
        const enumId = data.def.get?.('options');
        const beforeRequestEnum = sAction.getEnumForSelect(enumId).sort((a, b) => { // Číselník z překladů seřazený podle abecedy
            const valueA = a.value?.toUpperCase?.();
            const valueB = b.value?.toUpperCase?.();

            return (valueA < valueB) ? -1 : (valueA > valueB) ? 1 : 0;
        });

        this.setState({
            options: beforeRequestEnum,
            showForm: true,
        });
        sAction.openDynamicEnum(this.props.way, data, (returnData) => {
            const lines = returnData.lines;
            const linesOptions = [];
            lines.forEach((line, index) => {
                const value = line.language_json[actLang] ?? line.value;
                const option = {
                    value: line.value,
                    label: value,
                };
                value ? linesOptions.push(option) : linesOptions.unshift(option);
            })

            if (returnData.canAddNew == true) {
                linesOptions.unshift({
                    label: sAction.translate('LBL_ADD_NEW_VALUE'),
                    value: 'acm_add_new',
                    type: 'button',
                });
            }
            this.setState({
                options: linesOptions,
                showForm: true,
            });
            sAction.unLoad();
        });
    }

    // Stara verze s restovym volanim
    // componentDidMount() {
    //   sAction.load();
    //   const data = this.props.data;
    //   const actLang = sAction.dataGet("conf/language");
    //   sAction.openDynamicEnum(this.props.way, data, returnData => {
    //     const lines = returnDat a.lines;
    //
    //     var linesOptions = [];
    //     if (returnData.canAddNew == true) {
    //       linesOptions.push({ label: sAction.translate("LBL_ADD_NEW_VALUE"), value: "acm_add_new",type:"button" });
    //     }
    //     lines.forEach((line, index) => {
    //       const json = line.language_json;
    //       const lang = sAction.fromJSON(sAction.htmlDecode(json));
    //       var value = lang[actLang];
    //       if (value == undefined || value == "" || value == null) {
    //         value = line.get("value");
    //       }
    //       linesOptions.push({ value: line.value, label: value });
    //     });
    //     this.setState({
    //       options: linesOptions,
    //       showForm: true
    //     });
    //     sAction.unLoad();
    //   });
    // }
    selectItem(e) {
        const prefix = this.props.prefix;
        const data = this.props.data;
        const value = e.target.value;

        if (value === 'acm_add_new') {
            this.setState({
                type: 'input',
            });
        } else {
            const params = {
                way: this.props.way,
                name: this.props.data.name,
                value,
            };
            sAction.saveField(params, false);
            sAction.dataSet(this.props.way + '/actEdit', false);
            sAction.clearRelatedDynamicEnums(prefix, data.def.get('options'));
        }
    }
    saveInput(e) {
        const data = this.props.data;
        const value = this.input.current.value;
        const keyValue = sAction.normalizeString(value).replace(/ /g, '_').replace(/[^\w\s]/gi, '');

        const key = Object.keys?.(sAction.app_strings['dynamicEnum']?.[data.def?.get?.('options')] ?? [])?.find((key) => sAction.app_strings['dynamicEnum'][data.def.get('options')][key] === value);
        if (key !== undefined) {
            // Pokud jiz preklad existuje
            const params = {
                way: this.props.way,
                name: this.props.data.name,
                value: key,
            };
            sAction.saveField(params, true);
        } else {
            // Pokud se ma zalozit nove
            const updateData = {
                enum: data.def.get('options'),
                key: keyValue,
                value: value,
            };
            sAction.load();
            sAction.updateDynamicEnum(updateData, (returnData) => {
                sAction.unLoad();
                if (returnData.status) {
                    const params = {
                        way: this.props.way,
                        name: this.props.data.name,
                        value: returnData.message.data,
                    };
                    sAction.app_strings['dynamicEnum'][data.def.get('options')][returnData.message.data] = value;
                    sAction.saveField(params, true);
                }
            });
        }
    }
    keyUpCall(e) {
        const keyCode = e.keyCode;
        if (keyCode == 13) {
            this.saveInput(e);
        }
    }
    cancel(path, value) {
        if (path && value !== 'acm_add_new') {
            sAction.dataSet(path + '/actEdit', false);
        }
    }

    render() {
        const data = this.props.data;
        const key = data.value;
        let options = [];
        if (data.def?.get?.('options') && sAction.app_strings?.['dynamicEnum']?.[data.def.get('options')]) {
            options = sAction.app_strings['dynamicEnum'][data.def.get('options')];
        }
        const value = options[key];
        return (
            <React.Fragment>
                {this.state.showForm == true ? (
                    this.state.type == 'enum' ?
                        <Select
                            options={this.state.options}
                            open={true}
                            autoFocus={true}
                            onChange={(e) => this.selectItem(e)}
                            defaultValue={key}
                            // onClose={(e) => this.cancel(this.props.way, e.target.value)}
                        /> :
                        <>
                            <div className="inputContainer">
                                <InputText
                                    autoFocus={true}
                                    onKeyUp={(e) => this.keyUpCall(e)}
                                    //  onKeyDown={e => this.onKeyDown(e)}
                                    myRef={this.input}
                                    onBlur={(e) => this.saveInput(e)}
                                    error={this.state.inputError}
                                    //  defaultValue={value}
                                    id={data.name}
                                    name={data.name}
                                />
                            </div>
                            <div className="buttonContainer">
                                <div
                                    tabIndex="3"
                                    onClick={(e) => this.cancel(this.props.way, null)}
                                    className=" inputEditButton"
                                >
                                    <div className={'icon-detailCancel'} />
                                </div>
                            </div>
                        </>
                ) : (
                    <div className="valueContainer hover null">
                        <span id="dynamicenum_c">{value}</span>
                    </div>
                )}
            </React.Fragment>
        );
    }
}
