export default function quoteCreateOrder(data) {
  const params = { action: "quote_create_ord", record: data.id };

  this.load();
  this.rest.post("customAction", params, (resp) => {
    if (resp.status) {
      this.href("#detail/acm_orders/" + resp.message.data.record);
    } else {
      this.unLoad();
      this.alert(resp.errorMessage.text);
    }
  });

}
