/**
 *
 * @param object data => {action: "funkce ktera se ma na BE vykonat", data: "data, co se v POSTU posilaji na BE"}
 * @returns object {data: object, status: HTTP_CODE}
 */
export default function filesXhrSynchr(data) {
    let xhr = new XMLHttpRequest();
    let xdebug = !this.param.xdebugEnabled ? "" : "?XDEBUG_SESSION_START=1";
    xhr.open("POST", this.param.files + data.action + xdebug,false);
    xhr.setRequestHeader("sID", this.rest.getCookie("sID"));
    // xhr.responseType = "json";
    xhr.send(this.objectToFormdata(data.data));
    return {status: xhr.status,data: JSON.parse(xhr.response)}
}
