export default function sweetSpotSearch(str, module = '') {
    const self = this;
    this.rest.fetchData('sweetSpotSearch', 'POST', {str: str, module: module}).then((returnData) => {
        self.dsClear();

        const akce = {
            type: 'SWEETSPOTSEARCH',
            content: {data: returnData['data']},
            prefix: 'conf/popup/data',
        };
        self.dsAddCustom(akce);
        self.dsProcess();
    }).catch((error) => {
        self.error(error['text']);
    });
}
