import React, {useState} from 'react';
import Detail from '../detailView/detail';
import ActivitiesTimeline from './ActivitiesTimeline/ActivitiesTimeline';
import ViewPanelMenu from '../viewPanelMenu/viewPanelMenu';
import ProductSelector from './Quotes/productSelector';
import MassUpdateContent from './MassUpdate/MassUpdateContent';
import EmailDetailHeader from '../email/EmailDetailHeader';
import ListViewSearch from '../list/ListViewSearch.js';
import PropTypes from 'prop-types';
import Error from '../Errors/ErrorView';
import Subpanels from '../subpanel/Subpanels';
import Loader from '../loader';

import sAction from 'sAction';
import RightPanelImage from './RightPanelImage';

const EmailDetail = React.lazy(() => import('../email/EmailDetail'));

export default function RightPanel(props) {
    const [panel] = useState(React.createRef());
    const [isResizing, setIsResizing] = useState(false);
    const close = () => {
        const reloadOnClose = sAction.dataGet('rightPanel/reloadOnClose');
        const data = props.data.get('data');
        sAction.closeRightPanel();
        if (data.get('reloadList') != null && reloadOnClose === true) {
            const way = data.get('reloadList');
            sAction.reloadList(0, 1, way);
        }
    };
    const openRecord = (module, id) => {
        window.open('#detail/'+module+'/'+id, '_blank');
    };
    const handleMousedown = (e) => {
        e.preventDefault();
        if (isResizing) return;
        setIsResizing(true);
        document.body.addEventListener('mousemove', handleMousemove);
        document.addEventListener('mouseup', handleMouseup);
    };
    const handleMousemove = (e) => {
        if (isResizing) return;
        let newWidth = window.innerWidth - e.pageX - 10;
        if (newWidth < 400) {
            newWidth = 400;
        }
        document.querySelector('.rightPanelContainer').style.width = newWidth + 'px';
        if (props.data.get('content') === 'productView') {
            sAction.afterLoadListView(props.data.data.groupWay);
        }
    };
    const handleMouseup = (e) => {
        setIsResizing(false);
        document.body.removeEventListener('mousemove', handleMousemove);
        document.removeEventListener('mouseup', handleMouseup);
    };

    const data = props.data;
    let content = data.content;
    let headerContent = null;

    const customWidth = data.get('customWidth');

    switch (content) {
        case 'load':
            content = <Loader />;
            break;
        case 'detailView':
            content = (
                <Detail
                    key="detail"
                    a={props.action}
                    data={data.data.get('detail')}
                    way="rightPanel/data/detail"
                />
            );
            headerContent = <React.Fragment>
                <ViewPanelMenu data={data.data.get('detail')} prefix="rightPanel/data/detail" />
                <div
                    className="rightPanelClose icon-expandIcon"
                    onClick={() => openRecord(data.data.getIn(['detail', 'module']), data.data.getIn(['detail', 'id']))}
                />
            </React.Fragment>;
            break;
        case 'productView':
            headerContent = (
                <div className="productSelectorListViewSearchContainer">
                    <ListViewSearch
                        module="ProductTemplates"
                        prefix="rightPanel/data/listView"
                        data={data.data.listView.filter}
                    />
                </div>
            );

            content = (
                <ProductSelector data={data.data} detailWay="view" />
            );

            // Fixing columns width. Timeout is important due animation
            // TODO tohle je strasna picovina - opravit viz issue coripo/coripo#1937
            setTimeout(() => {
                sAction.afterLoadListView(props.data.data.groupWay);
            }, 150);
            setTimeout(() => {
                sAction.afterLoadListView(props.data.data.groupWay);
            }, 550);

            break;

        case 'emailRelations':
            content = (
                <div className="email__relations">
                    <Subpanels data={data.data.get('detail').subpanels} way="rightPanel/data/detail" />
                </div>
            );
            break;
        case 'emailView':
            content = (
                <div className="emailRightPanelContainer">
                    <React.Suspense fallback={<Loader />}><EmailDetail data={data.data} hideHeader /></React.Suspense>
                </div>
            );
            headerContent = (
                <React.Suspense fallback={<Loader />}><EmailDetailHeader data={data.data} /></React.Suspense>
            );
            break;
        case 'massUpdateView':
            content = (
                <MassUpdateContent data={data.data} />
            );
            break;
        case 'activitiesTimelineView':
            content = (<ActivitiesTimeline prefix={'rightPanel/data'} data={data.data}/>);
            break;
        case 'error':
            content = (<Error data={data.data}/>);
            break;
        case 'image':
            content = (<RightPanelImage data={data.data} />);
            break;
    }

    // pokud je aktivni prihlasit se jako je potreba posunout rightpanel niz
    const additionalHeight = {};
    if (document.querySelector('.headerAlertPanel')) {
        additionalHeight.marginTop = '64px';
    }
    if (customWidth !== null) {
        additionalHeight.width = customWidth;
    }
    return (
        <div className="rightPanelContainer" style={additionalHeight} ref={panel}>
            <div
                className='rightPanel_resizer'
                onMouseDown={handleMousedown}
            />
            <div className="rightPanelHeader">
                <div
                    className="icon-Close rightPanelClose"
                    onClick={() => close()}
                />
                {headerContent}
            </div>
            <div className="rightPanelContent">{content}</div>
        </div>
    );
}

RightPanel.propTypes = {
    data: PropTypes.object,
    action: PropTypes.string,
};
