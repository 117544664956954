import React from "react";

import InputField from "../inputFieldClass";
import CheckBox from "../../formElements/CheckBox";
import CustomTooltip from "ROOT/src/components/customTooltip/CustomTooltip";
import sAction from "sAction"
export default class Bool extends InputField {
  constructor() {
    super();
    this.state = {
      state: "fine",
      reg: /^[01]+$/
    };
  }
  componentDidMount() {}
  valueEdit() {
    this.save({ cancelEdit: false });
  }
  check() {
    return true;
  }
  getValue() {
    var value = parseInt(this.props.data.value);
    if(this.props.data.value == "" || this.props.data.value == null){
      value = 0
    }
    if (value === 1) {
      return 0;
    } else if (value === 0) {
      return 1;
    }
    return null;
  }

  getRenderValue() {
    var data = this.props.data;
    return data.value;
  }

  render() {
    const errorValue =  this.props.data.def.get("tooltip");
    const toolTipClass = this.props.data.def.get("tooltipClass");
    const data = this.props.data;
    
    var value = this.getRenderValue();
    if(value == "" || value == null){
      value = 0;
    }

    return (
        <div>
          <CheckBox
              className={"noHeight"}
              autoFocus={data.actEdit}
              onKeyDown={e => this.onKeyDown(e, null, false)}
              name={this.props.name}
              defaultValue={value}
              onChange={() => this.valueEdit()}
              readonly={this.props.readonly}
          />
          {errorValue && (
              <CustomTooltip
                  extraClass={" " + toolTipClass}
                  customStyle={{
                    backgroundColor: "red",
                  }}
                  direction={"bottom"}
                  content={errorValue}
                  onClose={()=>{
                    console.log("here")
                    sAction.dataSet(this.props.way + "/def/tooltip", null)
                  }}
              />
          )}
        </div>
    );
  }
}
