export default class rest {
    constructor(parent) {
        this.url = parent.param.server;
        this.token = null;
        this.parent = parent;
        this.xhr = null;
        this.controller = null;
    }

    post(action, data, complete, cancelPrevisouRequest = true) {
        this.call(
            action,
            'POST',
            data,
            function(msg) {
                complete(msg);
            },
            cancelPrevisouRequest,
        );
    }
    get(action, complete, cancelPrevisouRequest = true) {
        this.call(
            action,
            'GET',
            null,
            function(msg) {
                complete(msg);
            },
            cancelPrevisouRequest,
        );
    }
    delete(action, data, complete) {
        this.call(action, 'DELETE', data, function(msg) {
            complete(msg);
        });
    }
    call(action, method, data, complete, cancelPreviousRequest) {
        const self = this;
        const sId = this.getAuth();
        if (this.xhr !== null && cancelPreviousRequest === true) {
            this.controller.abort();
            this.controller = null;
        }
        this.controller = new AbortController();

        if (this.parent?.param?.xdebugEnabled) {
            action += (action?.includes('?') ? '&' : '?') + 'XDEBUG_SESSION_START=1';
        }

        const settings = {
            method: method,
            contentType: 'application/json',
            headers: new Headers({
                'content-type': 'application/json',
                'sId': sId.sID,
            }),
            signal: this.controller.signal,
        };
        if (method === 'POST' || method === 'DELETE') {
            settings.body = JSON.stringify(data);
        }

        this.xhr = fetch( self.url + '/' + action, settings)
            .then((response) => this.checkResponse(response, action, self)).then((data) => {
                if (typeof data === 'object' && data.status === 'error') {
                    if (data.reason != undefined) {
                        self.parent.dsClear();
                        if (self.parent.dataGet('rightPanel/show') == true) {
                            self.parent.dsAdd('set', 'rightPanel/content', 'error');
                            self.parent.dsAdd('set', 'conf/load', false);
                            self.parent.dsAdd('set', 'rightPanel/data', {
                                state: false,
                                type: data.reason,
                            });
                        } else {
                            self.parent.dsAdd('set', 'conf/view', 'error');
                            self.parent.dsAdd('set', 'conf/load', false);
                            self.parent.dsAdd('set', 'view', {
                                state: false,
                                type: data.reason,
                            });
                        }

                        self.parent.dsProcess();
                    } else {
                        self.parent.unLoad();
                        self.parent.error(self.parent.translate(data.msg || data.message));
                    }
                }
                complete(data);
                if (typeof data === 'object' && data.status === 'warning') {
                    setTimeout(function() {
                        self.parent.error(self.parent.translate(data.msg || data.message));
                    }, 2500);
                }
            }).catch((responseError) => {
                console.log(responseError);
            });
    }
    checkResponse(response, action, self) {
        if (
            ((response.status == '401' || response.status == 0) && response.statusText != 'abort') &&
        action !== 'getNotifyCount' && action !== 'recalcTopRecent'
        ) {
            if (!window.location.hash.startsWith('#login')) {
                if (response.status == '401') {
                    clearInterval(this.parent.notificationInterval);

                    self.parent.routeLogin();
                } else {
                    self.parent.error(self.parent.translate('LBL_SERVER_ERROR'));
                    self.parent.unLoad();
                }
            } else {
                clearInterval(this.parent.notificationInterval);

                self.parent.routeLogin();
            }
        }
        return response.json();
    }

    /**
     * @return {{sID: string}}
     */
    getAuth() {
        const token = this.getCookie('sID');
        const auth = {
            sID: token,
        };

        if (this.parent.deviceType != undefined) {
            auth['device'] = this.parent.deviceType;
        }

        return auth;
    }

    setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        const expires = 'expires=' + d.toUTCString();
        document.cookie = cname + '=' + cvalue + '; ' + expires;
    }

    getCookie(cname) {
        const name = cname + '=';
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1);
            if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
        }
        return null;
    }

    /**
     * Metoda pocita s formatem dat v coripo standardu, jinak nebude fungovat spravne.
     * @param {string} action
     * @param {string} method
     * @param {null|any} data
     * @param {boolean} cancelPreviousRequest
     * @return {Promise<any>}
     */
    async fetchData(action, method, data = null, cancelPreviousRequest = true) {
        const self = this;
        const sId = this.getAuth();
        let url = self.url + '/' + action;
        if (this.parent?.param?.xdebugEnabled) {
            url += (action?.includes('?') ? '&' : '?') + 'XDEBUG_SESSION_START=1';
        }

        if (this.controller && cancelPreviousRequest) {
            this.controller.abort();
        }

        this.controller = new AbortController();

        const fetchParams = {
            method: method,
            contentType: 'application/json',
            signal: this.controller.signal,
            headers: new Headers({
                'content-type': 'application/json',
                'sId': sId.sID,
            }),
        };

        if (method === 'POST' && data) {
            fetchParams.body = JSON.stringify(data);
        }

        const fetchedData = await fetch(url, fetchParams).then((response) => response.json()).then((result) => {
            return result;
        }).catch((responseError) => {
            switch (responseError.name) {
                case 'AbortError':
                    console.warn(`Request ${action} canceled`);
                    break;
                default:
                    this.parent.unLoad();
                    console.error('Error', responseError);
                    console.warn('You propably have error in your BE method returned data is not valid JSON');
            }
        });

        if (!fetchedData?.status) {
            throw fetchedData?.errorMessage;
        }

        return fetchedData.message;
    }
}
