export default function requiredFieldsCheckMsg(prefix, saveAttempt = true) {
    if (!prefix) {
        console.error("Neni vyplnen prefix");
    }
    if (!this.requiredFieldsCheck(prefix, saveAttempt)) {
        this.error(this.translate('LBL_REQUIRED_FIELDS_NOT_FILLED'));
        return false;
    }

    return true;
}
