import {List} from 'immutable';

export default function parseFile(params) {
    const prefix = params.prefix;
    const fileText = this.dataGet(prefix + "/fileContents");
    const columnDelimiter = this.dataGet(prefix + "/delimiters/columnDelimiter");
    let maxColumnCount = 0;
    let csvImport = [];
    this.parseCSVToArray(fileText, columnDelimiter, 20).forEach((line) => {
        maxColumnCount = Math.max(maxColumnCount, line.length);
        csvImport.push(List(line));
    });

    this.dsClear();
    this.dsAdd('set', prefix + '/import', []);
    this.dsAdd('set', prefix + '/firstRow', {});
    if (params.keepMapping !== true) {
        this.dsAdd('set', prefix + '/ignoreFirstRow', 0);
        this.dsAdd('set', prefix + '/useFirstAsMapping', 0);
        this.dsAdd('set', prefix + "/table", {});
    } else {
        const ignoreFirstRow = this.dataGet(prefix + '/ignoreFirstRow');
        const useFirstAsMapping = this.dataGet(prefix + '/useFirstAsMapping');
        if (useFirstAsMapping) {
            this.mapFromFirstRow(prefix + '/mapping/fileMapping', csvImport[0], this.dataGet(prefix + '/fields/fields'));
        }
        if (ignoreFirstRow || useFirstAsMapping) {
            this.dsAdd('set', prefix + '/firstRow', csvImport[0]);
            csvImport.shift();
        }
    }
    this.dsAdd('set', prefix + '/import', List(csvImport));
    this.dsAdd('set', prefix + '/columnCount', maxColumnCount);
    this.dsProcess();
}
