import {Map} from "immutable";

export default function handleFieldFunction(data,prefix){
  let storeFieldFunction = new Map({"parameters" :new Map({"parameters" : null})});
  const calculateFunction = data.fieldFunction?.function.toUpperCase();
  let header;
  if(calculateFunction === "COUNT"){
    header = this.translate("LBL_COUNT_NOT_NULL")
  } else {
    header = this.translate("LBL_"+ calculateFunction)
  }
  const field = data.fieldFunction.field;
  const realField = data.fieldFunction.realField;

  if(data.records.length >  1){   // nastane pouze pokud nekdo bude upravovat kod, ktery zasahne do teto funkcionality
   this.alert(this.translate("LBL_ERROR"));
  }
  this.popup("fieldFunction", {
    header,
    field,
    realField,
    calculateFunction,
    fieldLabel: data.def[field].vname,
    fieldType: data.def[field].type,
    functionResult: data.records[0][realField],  // vybere hodnotu funkce
    module: data.module,
  });
  this.dsClear();
  this.dsAdd('set',prefix + "/fieldFunction",storeFieldFunction); // vynulovani volani, aby se pri zmene pohledu, dalsim filtrovani nevolala
  this.dsAdd('set',prefix + "/openFilterData",null);    // uzavre dropdown pro vyber vypoctu
  this.dsProcess();
}
