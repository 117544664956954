export default function downloadImportCSV(params) {
    const token = this.dataGet(`${params.prefix}/customData/fileToken/token`);
    if (!token) {
        this.error(this.translate('LBL_IMPORT_CANNOT_DOWNLOAD_CSV'))
    } else {
        this.popup("fileDownload",{state:"load"});
        this.dsClear();
        const id = this.dataGet("conf/popup2/show") ? "2" : "";
        this.dsAdd("set",`conf/popup${id}/data/state`,"init")
        this.dsAdd("set",`conf/popup${id}/data/token`,token)
        this.dsProcess();
    }
}
