export default function reloadSubpanel(data){
    var paramData = {
        record: data.parentId,
        module: data.parentModule,
        subpanel: data.module,
        relName: data.relName,
        userPreferences: {category:data.parentModule+"_subpanel",way:"all/"+data.module+"/open",contents:true},
        rightPanel: data.prefix === 'rightPanel/data/detail',
    }

    this.rest.post("returnSubpanelData",paramData,returnData => {
        if(returnData.status === true){
            if (data.def?.get?.('readonly')) {
                returnData.message.data.readonly = true;
            }
            const action = {
                type:"LOADSUBPANEL",
                subpanel:returnData.message.data,
                parent: data.parentModule,
                parentId: data.parentId,
                prefix:data.prefix,
                way:data.way,
            }
            this.dataSetCustom(action);
            if (data.subpanelAction === "new_relate") {
                this.custom("subpanel", { module: data.parentModule, subpanelData: returnData });
            }
            this.unLoad();
        } else {
            this.error(this.translate(returnData.errorMessage.text));
            sAction.dsClear();
            sAction.dsAdd('set', data.way + '/def/open', false);
            sAction.dsAdd('set', data.way + '/def/load', false);
            sAction.dsProcess();
        }
    })
}
