export default function checkNewNotifications(){
  if (window.Cypress || this.getStorage("Cypress")) {
      return;
  }
  this.notificationInterval = setInterval(() => {
      if(document.hidden) return
      this.rest.get("getNotifyCount",data => {
          const status = data.status;
          const number = data.number;

          if (status === true && isNaN(number) === false){
              var count = this.dataGet("notifications/notice");
              if(count !== number){

                  this.dataSet("notifications/notice",number);

                  var toastLangMap = {
                      1: this.translate("LBL_NOTIFY_TOAST"),
                      2: this.translate("LBL_NOTIFY_TOAST"),
                      3: this.translate("LBL_NOTIFY_TOAST"),
                      4: this.translate("LBL_NOTIFY_TOAST"),
                      5: this.translate("LBL_NOTIFY_TOAST_MORE"),
                  }
                  var desc = toastLangMap[number];

                  if(desc === undefined){
                      desc = toastLangMap[5];
                  }
                  desc = desc.replace("{number}",number);
                  this.toast({
                      name:"Nová upozornění",
                      description:desc,
                      sAction: "openNotBase",
                  })
              }
          }
      },false)
  },15000)
}
