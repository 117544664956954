import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import InputField from '../inputFieldClass';
import InputText from '../../formElements/InputText';
import CustomTooltip from 'ROOT/src/components/customTooltip/CustomTooltip';
import Autocomplete from '../../formElements/Autocomplete';

import sAction from 'sAction';

export default class ApiPSC extends InputField {
    constructor(props) {
        super();
        this.state = {
            state: 'fine',
            reg: /^[0-9]{3} ?[0-9]{2}$/,
            resultOpen: (props.data.value !== null && props.data.value !== ''),
            searchText: props.data.value,
            error: false,
        };
        this.input = React.createRef();
        this.autocomplete = React.createRef();
    }

    /**
     * propisuje hodnoty do polí
     * @param {object|null} values hodnoty, které mají být propsány, nebo null pokud se mají načíst
     */
    writeValues = (values) => {
        const way = this.props.way;
        const prefix = this.props.prefix;

        sAction.load();
        if (values !== null) {
            this.cancel();

            const fieldPrefix = way.match(/([a-z_]*)address_postalcode$/)[1];
            const fields = [];
            values.forEachObject((val, key) => {
                if (key !== 'name') {
                    fields[fieldPrefix + key] = (key === 'address_postalcode')? `${val.slice(0, 3)} ${val.slice(3)}` : val;
                }
            });

            sAction.updateFields(fields, prefix);
        } else if (super.check()) {
            // uživatel nevybral obec pomocí autocomplete
            sAction.checkPSC(this.getValue(), prefix, way);
        } else {
            sAction.unLoad();
        }
    };

    keyUpCall(e) {
        const keyCode = e.keyCode;

        if (this.input?.current?.value.length < 3) {
            this.setState({resultOpen: false});
        } else {
            this.setState({searchText: this.input.current.value, resultOpen: true});
        }

        if (keyCode === 27) {
            this.cancel();
            return;
        }

        if (this.autocomplete?.current) {
            this.autocomplete.current.keyUpHandler(keyCode);
        } else if (keyCode === 13) {
            if (this.getValue()) {
                this.writeValues(null);
            } else {
                this.save();
            }
        }
    }

    render() {
        const data = this.props.data;
        const newRecord = this.props.newRecord;
        const error = this.state.error;
        const errorValue = this.props.data.def.get('error');
        const value = (data.value !== null)? data.value : '';

        return (
            <React.Fragment>
                <div className="inputEditContainer" data-fieldname={data.name}>
                    <div className="inputContainer">
                        <InputText
                            autoFocus={true}
                            onKeyUp={(e) => this.keyUpCall(e)}
                            onKeyDown={(e) => this.onKeyDown(e)}
                            myRef={this.input}
                            inputProps={{maxLength: this.props.data.def.get('len')}}
                            onBlur={(e) => this.onBlur(e)}
                            error={error}
                            defaultValue={value}
                            id={data.name}
                            name={data.name}
                            fieldtype={data.def.get('type')}
                        />
                        {this.state.resultOpen ? <Autocomplete
                            data={data}
                            resultOpen={this.state.resultOpen}
                            searchText={this.state.searchText}
                            searchMethod={'PSC'}
                            autocompleteReturnItem={this.writeValues}
                            ref={this.autocomplete}
                        /> : null}
                    </div>
                    {errorValue && (
                        <CustomTooltip
                            extraClass={'tooltipAlignRight'}
                            customStyle={{
                                backgroundColor: 'red',
                                transform: 'translateX(-103%)',
                                bottom: 'calc(calc(var(--tooltip-margin) * -1) + 10px)',
                            }}
                            direction={'bottom'}
                            content={errorValue}
                        />
                    )}
                    {!newRecord && (
                        <div className="buttonContainer">
                            {errorValue && (
                                <div
                                    tabIndex="-1"
                                    className="warningMessage inputEditButton"
                                >
                                    <div className={'icon-warning'}></div>
                                </div>
                            )}
                            <div
                                tabIndex="2"
                                onClick={() => this.save()}
                                className="inputEditButton"
                            >
                                <div className={'icon-detailConfrim'} />
                            </div>
                            <div
                                tabIndex="3"
                                onClick={() => this.cancel()}
                                className=" inputEditButton"
                            >
                                <div className={'icon-detailCancel'} />
                            </div>
                        </div>
                    )}
                </div>

            </React.Fragment>
        );
    }
}

ApiPSC.propTypes = {
    prefix: PropTypes.string.isRequired,
    rowWay: PropTypes.string,
    way: PropTypes.string.isRequired,
    module: PropTypes.string.isRequired,
    index: PropTypes.number,
    newRecord: PropTypes.bool.isRequired,
    attemptedSave: PropTypes.bool,
    data: PropTypes.shape({
        def: ImmutablePropTypes.map.isRequired,
        value: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,
};
