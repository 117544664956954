import React from "react";
import PureComponent from "../pure";
import sAction from "sAction";
import moment from "moment";

export default class OldRecordContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: true
        };
        this.content = React.createRef();
    }

    componentDidMount() {
        const body = this.content.current;
        const index = this.props.index;
        body.addEventListener("scroll", (e) => {
            let scrollTime = (new Date()).toLocaleString();
            if (sessionStorage.wheelScrollTime === scrollTime) {
                // uz se scrollovalo pres kolecko v nejakem okne, neresit
                return;
            }


            let scrollValue = this.content.current.scrollTop;
            const bodies = document.querySelectorAll(".oldMergeBody");
            bodies.forEach(body => {
                if (body.dataset.index != index && !body.classList.contains("active")) {
                    body.scrollTop = scrollValue;
                }
            });
        });
        body.addEventListener("wheel", (e) => {
            e.preventDefault();
            e.stopPropagation();

            let divs = e.currentTarget.querySelectorAll(".mergeRecordFieldLine");
            if (!divs.length) {
                return;
            }

            let initialOffset = divs[0].offsetTop;

            let div_id = 0;
            if (e.deltaY > 0) {
                for (let i = 0; i < divs.length; i++) {
                    if (divs[i].offsetTop - initialOffset > e.currentTarget.scrollTop + 5) {
                        e.currentTarget.scrollTop = divs[i].offsetTop - initialOffset;
                        div_id = i;
                        break;
                    }
                }
            } else if (e.deltaY < 0) {
                for (let i = divs.length-1; i >= 0; i--) {
                    if (divs[i].offsetTop - initialOffset < e.currentTarget.scrollTop) {
                        e.currentTarget.scrollTop = divs[i].offsetTop - initialOffset;
                        div_id = i;
                        break;
                    }
                }
            }

            const bodies = document.querySelectorAll(".oldMergeBody");
            bodies.forEach(body => {
                if (body.dataset.index != index && !body.classList.contains("active")) {

                    divs = body.querySelectorAll(".mergeRecordFieldLine");
                    if (!divs || !divs.length || !divs[div_id]) {
                        return;
                    }
                    initialOffset = divs[0].offsetTop;

                    body.scrollTop = divs[div_id].offsetTop - initialOffset;
                }
            });

            sessionStorage.wheelScrollTime = (new Date()).toLocaleString();
        });

    }

    mouseEnter() {
        let bodies = document.querySelectorAll(".oldMergeBody.active");
        bodies.forEach(body => {
            body.classList.remove("active");
        });

        this.content.current.classList.add("active");
    }

    mouseLeave() {
        this.content.current.classList.remove("active");
    }

    addToNewRecord(field, def) {
        const data = this.props.data;
        const value = data.get(field);
        const way = this.props.way;

        var paramData = [{way: way, field: field, value: value}];

        if (def.get("type") === "relate" && def.get("source") === "non-db") {
            paramData.push({
                way: way,
                field: def.get("id_name"),
                value: data.get(def.get("id_name"))
            });
            if (!paramData[paramData.length-1].field) {
                console.error("neni spravna vazba na field")
                debugger;
            }
        }
        sAction.mergeAddField(paramData);

        this.scrollIntoField(field, 2);
    }

    sortByLabel( a, b ) {
        const alabel = a && typeof a.label === "string" ? a.label : "";
        return alabel.localeCompare(b.label);
    }

    mergeSetAsDefault = (e) => {
        let index = e.target.dataset.index;

        sAction.mergeSetAsDefault(this.props.way, index);

        setTimeout(function () {
            let newRecordBodyFirstValue = document.querySelector('.newRecordContainer .mergeRecordBody .withValue');
            newRecordBodyFirstValue.scrollIntoView({behavior: "smooth", block: "start"});
        }, 500);
    }

    findValue = (e) => {
        let fieldName = e.target.dataset.source || e.target.parentElement.dataset.source;
        this.scrollIntoField(fieldName);
    }

    scrollIntoField(fieldName, highlight = 1) {
        let newRecordBody = document.querySelector('.newRecordContainer .mergeRecordBody');
        let field = newRecordBody.querySelector("div[data-field='" + fieldName + "']");
        field.scrollIntoView({behavior: "smooth", block: "center"});
        setTimeout(() => field.classList.add("highlighted" + highlight), 500);
        setTimeout(() => field.classList.remove("highlighted" + highlight), 1000);
        setTimeout(() => field.classList.add("highlighted" + highlight), 1500);
        setTimeout(() => field.classList.remove("highlighted" + highlight), 2000);
    }

    render() {
        const data = this.props.data;
        const def = this.props.def;
        const module = this.props.module;
        const index = this.props.index;
        const way = this.props.way;
        const check = this.props.isChecked;
        let assignedUserDiv = ''
        if (data.get("assigned_user_id")) {
            assignedUserDiv = <div>{sAction.translate("LBL_ASSIGNED_RECORD") + " "}
                <a href={'#detail/Users/' + data.get("assigned_user_id")}>{data.get("assigned_user_name")}</a>
            </div>
        }

        let linesData = [];
        def.entrySeq().forEach(fieldDef => {
            if (fieldDef[1]?.get("type") === "link") {
                return;
            }

            let label = sAction.translate(fieldDef[1].get("vname"), module);
            label = label.charAt(0).toUpperCase() + label.slice(1).replace(":", "");
            linesData.push({
                label,
                fieldDef
            });
        });

        linesData.sort(this.sortByLabel);

        let linesRender = [];
        let findTitle = sAction.translate("LBL_SEARCHED");
        linesData.forEach(line => {
            let fieldDef = line.fieldDef;
            const value = sAction.getValueOfMergeField(data.get(fieldDef[0]), fieldDef[1]);

            let valClass = this.props.sameValues.includes(fieldDef[0]) ? "sameValue" : "differentValue";
            if(check === true && valClass === "sameValue") {
                return;
            }

            linesRender.push(
                <div key={fieldDef[0]} className={"mergeRecordFieldLine " + valClass}>
                    <div className="mergeRecordText">
                        <div className="mergeRecordFieldHeader" data-source={fieldDef[0]} onClick={this.findValue} title={findTitle}>
                            {line.label}
                            <div className="reportWindowSelectFields">
                                {fieldDef[0] + " | " + sAction.findTypeTranslation(fieldDef[1]?.get("type"))}
                            </div>
                        </div>
                        <div className="mergeRecordLineText">
                            <div className="mergeRecordFieldValue">
                                {value ? value : ""}
                            </div>
                        </div>
                    </div>
                    <div className="mergeRecordButtons">
                        <div
                            className="icon-arrowRight mergeAddFieldButton"
                            title={sAction.translate("LBL_MERGE_RECORDS_COPY")}
                            onClick={() => this.addToNewRecord(fieldDef[0], fieldDef[1])}
                        />
                    </div>
                </div>
            );
        });

        return (
            <div
                className={
                    this.state.open
                        ? "recordContainer old"
                        : "recordContainer recordContainerClose old"
                }
            >
                <div className="mergeRecordHeader">
                    <div
                        className="mergeRecordHeaderText"
                        onClick={() => this.setState({open: !this.state.open})}>
                        <div className={this.state.open ? "icon-subpanelClose" : "icon-subpanelOpen"}
                             title={sAction.translate(this.state.open ? "LBL_MINIMIZE" : "LBL_MAXIMIZE")}/>
                    </div>
                    <div className="mergeRecordHeaderItems">
                        <div>
                            <div onClick={() => {
                                sAction.rightPanelDetail(module, data.get("id"), {}, () => {
                                    sAction.dataSet("rightPanel/data/detail/readonly", true);
                                });
                            }}
                                 className="icon-eye mergeViewFieldIconEye">
                            </div>
                            <a target="_blank" href={"#detail/" + module + "/" + data.get("id")} className="mergeRecordName">{data?.get("name")}</a>
                        </div>
                        {assignedUserDiv}
                        <div>{data?.get("date_entered") &&
                        sAction.translate("LBL_MULTIACTIVITY_OK") + " " + moment(data.get("date_entered")).format('DD. MM. YYYY')}
                        </div>
                    </div>
                    <div className="mergeRecordHeaderButtons">
                        <div
                            className="icon-arrowRight mergeAddFieldButton"
                            onClick={this.mergeSetAsDefault}
                            data-index={index}
                            title={sAction.translate("LBL_MERGE_RECORDS_COPY_ALL")}
                        />
                    </div>
                </div>
                <div
                    style={this.state.open ? null : {display: "none"}}
                    className="mergeRecordBody oldMergeBody"
                    data-index={index}
                    ref={this.content}
                    onMouseEnter={() => this.mouseEnter()}
                    onMouseLeave={() => this.mouseLeave()}
                >
                    {linesRender}
                </div>
            </div>
        );
    }
}
