import React from "react";
import PureComponent from "../pure";

import WaveEffect from "../formElements/WaveEffect";
import MoreOptions from "../formElements/MoreOptions";
import sAction from "sAction";
export default class SubpanelHeaderInv extends PureComponent {
    constructor(props) {
        super(props);
        this.header = React.createRef();
    }
    pagination(type) {
        const data = this.props.data;
        const listData = data.rows;
        const way = this.props.way;
        const paginationData = {
            offset: listData.offset,
            limit: listData.limit,
            rowCount: listData.rowCount,
            page: listData.page,
            prefix: way + "/rows",
            type: type,
        };
        sAction.listPagination(paginationData);
    }
    subpanelMaxSize() {
        var st = {};
        const way = this.props.way;
        let state = [];
        var container = this.header.current.closest(".subpanelContainer");
        if (container.classList.contains("maxSize")) {
            st.isOpen = "";
            this.setState(st);
            container.classList.remove("maxSize");
            this.state && (
                this.state["isMaxSize"] = false )
            sAction.dataSet(way + "/rows/limit", 10);
            sAction.reloadList(0, 1, way + "/rows");
        } else {
            container.classList.add("maxSize");
            state["isMaxSize"] = true;
            this.setState(state);
            sAction.dsClear();
            sAction.dsAdd("set",way + "/rows/limit", 100);
            sAction.dsAdd("set",way + "/rows/selected",[]);
            sAction.dsProcess();
            sAction.reloadList(0, 1, way + "/rows");
        }
    }
    openRelateListView() {
        const data = this.props.data;

        const defaultFilter = sAction.subpanelGenerateDefaultFilter(
            this.props.prefix,
            data
        );

        const paramData = {
            module: data.module,
            defaultFilter: defaultFilter,
        };
        sAction.openRelatePopup(paramData, (returnData) => {
            sAction.subppanelChangeRelate(
                returnData,
                data,
                this.props.prefix,
                this.props.way,
                0
            );
        });
    }
    openExportMenu() {
        const prefix = this.props.way + "/rows";
        const data = this.props.data;
        const params = {
            prefix: prefix,
            module: data.module,
        };
        if (data.module === "Activities" || data.module === "History") {
            sAction.exportActivitiesOrHistoryList();
        } else {
            sAction.exportList(params);
        }
    }
    closeMaxSize() {
        if(this.state) {
            var container = this.header.current.closest(".subpanelContainer");
            if (container.classList.contains("maxSize")) {
                container.classList.remove("maxSize");
            }
        }
    }

    subpanelNewRecord() {
        this.closeMaxSize();
        this.props.data.newContact(this.props.data.parentData);
    }

    optionsReloadSubpanel() {
        this.props.data.refreshSearch(this.props.data.parentData.self);
    }

    render() {
        const data = this.props.data;
        const records = this.props.records;
        const open = data.def.get("open");
        const readonly = data.def.get("readonly");
        const listData = data.rows;
        let icon;
        const module = data.module;
        const menuGroups = sAction.dataGet("menu/groups");
        for (let group of menuGroups) {
            icon = group.icon;
            if (icon !== module) {
                icon = group.buttons.find((button) => button.name === module);
                icon = icon === undefined ? "object-connection" : icon.name;
            }

            if (icon === module) break;
        }

        if (module === "Invitees") {
            icon = "acm_CampaignsAction";
        }

        var subpanelIconClass = "icon-subpanelClose";
        var subpanelIconClassTranslate = "LBL_MINIMIZE";
        if (open == false) {
            subpanelIconClass = "icon-subpanelOpen";
            subpanelIconClassTranslate = "LBL_MAXIMIZE";
        }
        var addOptions = [];

        let newRecordButton;
        if (readonly) {
            newRecordButton = null;
        } else {
            if (sAction.hasAccess(data.module, "edit") == true) {
                addOptions.push({
                    label: sAction.translate("LBL_CREATE_NEW"),
                    value: "subpanelNewRecord",
                    icon: "icon-addIcon",
                });
            }
        }

        var options = [];
        options.push({
            label: "Aktualizovat subpanel",
            value: "optionsReloadSubpanel",
        });

        const subpanelName = data.subpanelName;

        if (!subpanelName) {
            subpanelName = module ?? "";
            console.log("Chybí jméno subpanelu !");
        }
        return (
            <div className="subpanelHeader" ref={this.header}>
                <div>
                    <div
                        className="subpanelHeaderLeftContainer"
                        style={{ backgroundColor: this.props.iconColor }}
                    >
                        {/* <span className="moveHolder icon-Sortable" /> */}
                        <div className={"subpanelIconContainer"}>
                            <span className={"icon-" + icon + " subpanelIcon"} />
                        </div>
                    </div>
                    <h3>{subpanelName}</h3>
                    {sAction.getStorage("debug") && sAction.dataGet("conf/isAcmAdmin") &&
                    (<div
                            className="debugDbButton icon-database"
                            onClick={() => sAction.generateDbLink({ action: "subpanel_table", subpanel: data.dbLinkRelDef })}
                    />)
                    }
                </div>
                <div>
                    {data.def.get("open") === true &&
                        (listData.page != 1 || records !== 0) && data.allowMaxSize && (
                            <React.Fragment>
                                <div
                                    onClick={() => this.subpanelMaxSize()}
                                    className="subpanelHeaderRightButton icon-MaxSize"
                                ></div>
                            </React.Fragment>
                        )}
                    {data.module == "Invitees" && newRecordButton}
                    {addOptions.length != 0 && (
                        <MoreOptions
                            options={addOptions}
                            onRight={true}
                            fromRight={90}
                            onClick={(e, val) => this[val](e)}
                        >
                            <div className="subpnaelheaderNewRecord subpanelHeaderRightButton icon-addIcon">
                                <WaveEffect className="dark" />
                            </div>
                        </MoreOptions>
                    )}
                    {options.length !== 0 && <MoreOptions
                            options={options}
                            onRight={true}
                            fromRight={70}
                            onClick={(e, val) => this[val](e)}
                        >
                            <div className="subpanelHeaderRightButton icon-More" />
                        </MoreOptions> }
                    <div
                        onClick={() => data.def.set("open", !data.def.get("open"))}
                        isopen={this.state ? this.state.isOpen : ""} 
                        className={subpanelIconClass + " toogleSubpanelButton"}
                        title={sAction.translate(subpanelIconClassTranslate)}
                    />
                </div>
            </div>
        );
    }
}
