import React from "react";
import p from "prop-types";
import InputText from "./InputText";
import sAction from "sAction";
class MultiSelect extends React.Component {
  constructor(props) {
    super(props);

    var selected = null;
    if (props.open === true) {
      this.dataFrom = "state";
      try {
        if (typeof this.props.defaultValue === "string") {
            selected = sAction.parseMultienum(this.props.defaultValue);
        } else {
          selected = this.props.defaultValue;
        }
      } catch (e) {}
    } else {
      this.dataFrom = "props";
    }

    this.state = {
      open: props.open == true ? props.open : false,
      lineHeight: 28,
      limit: 100,
      allResult: false,
      values: selected,
      searchValue: null,
      arrowIndex: -1,
    };

    this.activeRowKey = null;
    this.searchInterval = null;
    this.input = React.createRef();
  }
  componentDidMount() {
    if (this.input.current != null) {
      this.input.current.focus();
    }
  }

  open() {
    if (this.props.readonly) {
      return;
    }
    if (this.state.open === false) {
      this.dataFrom = "state";
      var selected = [];
      if (this.props.valueFromProps === true) {
          if (this.props.defaultValue != null) {
              if (typeof this.props.defaultValue === "string") {
                  selected = sAction.parseMultienum(this.props.defaultValue);
              } else {
                  selected = this.props.defaultValue;
              }
          }
      } else {
          selected = this.state.values;
      }
      this.setState({
        values: selected,
      });
      this.toogle();
    }
  }
  toogle() {
    const container = this.refs.container;
    if (this.state.open === false) {
      const height = container.clientHeight;
      container.style.height = height + "px";
    } else {
      container.style.height = "auto";
    }
    this.setState({
      open: !this.state.open,
    });
  }
  select(e) {
    const value = e.target.dataset.value;
    var values = this.state.values;
    if (values == null) {
      values = [];
    }
    values.push(value);

    values = this.controlExistingValues(values);
    this.setState({
      values,
    });
    if (this.props.onChange !== undefined) {
      this.props.onChange(values);
    }
    this.input.current.focus();
  }
  valueDelete(e, value) {
    // Pridana podminka, aby neslo mazat kdyz neni otevreny
    if (this.state.open) {
      e.stopPropagation();
      var newValues = [];
      this.state.values.forEach((addedValue) => {
        if (value != addedValue) {
          newValues.push(addedValue);
        }
      });

      newValues = this.controlExistingValues(newValues);
      this.setState({
        values: newValues,
      });
      if (this.props.onChange !== undefined) {
        this.props.onChange(newValues);
      }
      this.input.current.focus();
    }
  }
  cancel(e) {
    if (this.props.readonly) {
      return;
    }
    const relTarget = e.relatedTarget;
    var close = true;
    if (relTarget !== null) {
      var parent = relTarget.closest(
        "div[data-fieldname='" + this.props.id + "']"
      );
      if (parent != null) {
        close = false;
      }
      if (relTarget.dataset.fieldname === this.props.id) {
        close = false;
      }
    }
    if (close == true) {
      const container = this.refs.container;
      container.style.height = "auto";
      this.dataFrom = "props";
      this.setState({
        open: false,
        allResult: false,
        searchValue: null,
      });
      if (this.props.onBlur !== undefined) {
        this.props.onBlur(this.state.values);
      }
    }
  }
  keyUp(event) {
    this.waitForSearch();
  }
  waitForSearch() {
    if (this.searchInterval != null) {
      clearInterval(this.searchInterval);
    }
    var self = this;
    this.searchInterval = setInterval(() => {
      clearInterval(self.searchInterval);
      self.search();
    }, 200);
  }
  search() {
    var value = null;
    try {
      value = this.input.current.value;
    } catch (e) {
      value = null;
    }
    if (value != null) {
      this.setState({
        searchValue: value,
      });
    }
  }
  allresults(e) {
    e.stopPropagation();
    this.setState({
      allResult: true,
    });
  }
  onKeyDown(e) {
    const keyCode = e.keyCode;
    if (keyCode === 40 || keyCode === 38) {
      this.changeArrowIndex(keyCode);
      e.preventDefault();
    }
    if (this.props.onKeyDown !== undefined) {
      this.props.onKeyDown(e, this.state.values);
    }
    if (e.keyCode === 13) {
      if (this.activeRowKey.selected == false) {
        var values = this.state.values;
        if (values == null) {
          values = [];
        }
        if (this.activeRowKey.index != null) {
          values.push(this.activeRowKey.index);
          this.setState({
            values,
          });
        }
      } else {
        var values = this.state.values;
        values.splice(this.activeRowKey.index, 1);
        this.setState({
          values,
        });
        this.activeRowKey.index = this.activeRowKey.index - 1;
      }
    }
  }
  changeArrowIndex(keyCode) {
    var arrowIndex = this.state.arrowIndex;
    if (keyCode === 40) {
      arrowIndex += 1;
    } else if (keyCode === 38) {
      arrowIndex -= 1;
    }

    if (arrowIndex < -1) {
      arrowIndex = -1;
      this.activeRowKey = null;
    }
    if (this.props.options != null) {
      var trueLength = this.props.options.length;
      try {
        if (this.state.values != null) {
          trueLength -= this.state.values.length;
        }
      } catch (e) {}
      if (arrowIndex >= trueLength) {
        arrowIndex = trueLength;
        this.activeRowKey = null;
      }
    }
    this.setState({ arrowIndex });
  }
  componentWillUnmount() {
    clearInterval(self.searchInterval);
  }
  controlExistingValues(values) {
    var correctValues = [];
    const options = this.props.options;
    values.forEach((value, index) => {
      var exist = false
      options.forEach((option, optionIndex) => {
        if(value == option.value){
          exist = true
        }
      });
      if(exist === true){
        correctValues.push(value)
      }
    });
    return correctValues
  }

  render() {
    var options = this.props.options;

    var listStyle = {};
    if (this.props.height != undefined) {
      listStyle["height"] = this.props.height;
    }
    if (this.state.open == true) {
      var pocetRadku = options.length;
      // + 1 radek pac prvni je prazdny a neni v seznamu a + 2 za border a vetsi input
      var height = (pocetRadku + 1) * this.state.lineHeight + 2;
      if (height > 142) {
        height = 142;
      }

      listStyle["height"] = height + "px";
      listStyle["display"] = "flex";
    } else {
      listStyle["height"] = "0px";
      listStyle["display"] = "none";
    }
    var optionsRender = [];
    var selectedRender = [];
    var renderCount = 0;
    var renderCountSelected = 0;

    var selected = this.state.values;

    if (this.props.valueFromProps === true && this.dataFrom === "props") {
      try {
        if (typeof this.props.defaultValue === "string") {
            selected = sAction.parseMultienum(this.props.defaultValue);
        } else {
          selected = this.props.defaultValue;
        }
      } catch (e) {}
    }
    if (selected == null) {
      selected = [];
    }
    options.forEach((element, index) => {
      if (selected.indexOf(element.value) != -1 && element.value != "") {
        var className = "acmMultipleSelectSelectBlock";
        if (this.state.arrowIndex === renderCountSelected) {
          className += " arrowSelect";
          this.activeRowKey = { index: renderCountSelected, selected: true };
        }

        selectedRender.push(
          <div tabIndex={index + 1} key={index} className={className}>
            <div
              className="acmMultiSelectDelete"
              onClick={(e) => this.valueDelete(e, element.value)}
            />
            <div title={element.label}>{element.label}</div>
          </div>
        );
        renderCountSelected += 1;
      } else {
        if (
          this.state.searchValue == null ||
          element.label
            .toLowerCase()
            .indexOf(this.state.searchValue.toLowerCase()) !== -1
        ) {
          if (
            renderCount <= this.state.limit ||
            this.state.allResult === true
          ) {
            var className = "acmMultiSelectListLine";
            if (renderCount + selected.length === this.state.arrowIndex) {
              className += " arrowSelect";
              this.activeRowKey = { index: element.value, selected: false };
            }

            optionsRender.push(
              <div
                title={element.label}
                key={index}
                className={className}
                style={{ height: this.state.lineHeight + "px" }}
              >
                <div data-value={element.value}>
                  {element.label}{" "}
                  {sAction.getStorage("debug") && "[" + element.value + "]"}
                </div>
              </div>
            );
          }
          renderCount += 1;
        }
      }
    });
    if (renderCount > this.state.limit && this.state.allResult == false) {
      optionsRender.push(
        <div
          onClick={(e) => this.allresults(e)}
          className="acmMultiSelectLastRow"
          key="last"
        >
          <b> Načíst všechny výsledky </b>
        </div>
      );
    }

    var searchPanel = (
      <div className="acmMultiSelectSearchPanel">
        <InputText
          className="acmMultiEnumInput"
          autoFocus={true}
          onBlur={(e) => this.cancel(e)}
          type="text"
          placeholder="Hledat..."
          myRef={this.input}
          tabIndex={100}
          onKeyUp={(event) => this.keyUp(event)}
        />
      </div>
    );

    var className = "acmMultiSelect";
    if (this.props.className !== undefined) {
      className += " " + this.props.className;
    }

    return (
      <div
        ref="container"
        className={className}
        data-fieldname={this.props.id}
        onBlur={(e) => this.cancel(e)}
        tabIndex={0}
        onKeyDown={(e) => this.onKeyDown(e)}
      >
        <div
          className={
            this.state.open == true
              ? "acmMultiSelectContainer open"
              : "acmMultiSelectContainer"
          }
        >
          <div
            className="acmMultiSelectLabelContainer"
            onClick={() => this.open()}
          >
            <div className="acmMultiSelectLabel">
              {selectedRender.length == 0 ? (
                <span className="acmMultiSelectEmpty">
                  Lze vybrat více možností
                </span>
              ) : (
                selectedRender
              )}
            </div>
          </div>
          {this.state.open === true && (
            <div className="acmMultiSelectListContainer" style={listStyle}>
              {searchPanel}
              <div
                onClick={(e) => this.select(e)}
                className="acmMultiSelectList"
              >
                {optionsRender}
              </div>
            </div>
          )}

          <input
            type="hidden"
            ref={this.props.myRef !== undefined ? this.props.myRef : ""}
            defaultValue={this.state.actValue}
          />
        </div>
      </div>
    );
  }
}

MultiSelect.propTypes = {
  defaultValue: p.oneOfType([p.string, p.array]),
  height: p.number,
  options: p.array.isRequired,
  id: p.string.isRequired,
  onBlur: p.func,
  onChange: p.func,
  onKeyDown: p.func,
};

export default MultiSelect;
