// import {calPlanReload} from "../../exportConfig"

export default function calSaveActivityDetailHelper(params, callback) {
  const prefix = params.prefix
  const prefixInv = params.prefixInv
  const canSave = this.dataGet(prefix + "/canSave")

  var id = this.dataGet(prefix + "/id")

  const module = this.dataGet(prefix + "/module")

  var fields = this.dataGet(prefix + "/fields")

  const editFields = this.dataGet(prefix + "/changes/fields")
  const invitees = this.dataGet(prefixInv + "/relatedInv").toJS()
  const selectedOrderLines = this.dataGet(prefix + "/customData/selectedOrderLines")
  const inviteesBackup = this.dataGet(prefixInv + "Backup/relatedInv").toJS()

  var self = this
  self.dsClear()

  let ignoredFields = ["id"]
  const visibleFields = []
  const tabs = this.dataGet(prefix + "/tabs")
  tabs.forEach((tab, index) => {
    tab.rows.forEach((row, index) => {
      row.forEach((line, index) => {
        if (tab.hidden) {
          ignoredFields.push(line)
        } else {
          visibleFields.push(line)
        }
      })
    })
  })

  const hiddenFields = this.dataGet(prefix + "/hiddenFields")
  hiddenFields.forEach(field => {
    ignoredFields.push(field)
  })

  if (canSave == true) {
    if (!this.custom("beforeSave", {})) {
    }

    var sendToCrm = true
    fields.valueSeq().forEach(data => {
      if (ignoredFields.includes(data.name)) {
        return
      }
      if (!visibleFields.includes(data.name)) {
        return
      }
      if (data.def) {
        if (data.def.get("required") == true && (data.value === "" || data.value === null)) {
          self.dsAdd("set", prefix + "/fields/" + data.name + "/def/isInvalid", true)
          self.dsAdd("set", prefix + "/fields/" + data.name + "/customClass", "error")
          sendToCrm = false
        } else {
          self.dsAdd("set", prefix + "/fields/" + data.name + "/def/isInvalid", false)
          self.dsAdd("set", prefix + "/fields/" + data.name + "/customClass", "")
        }
      }
    })

    self.dsProcess()

    if (sendToCrm == false) {

    } else {
      self.load()

      if (id == undefined) {
        id = ""
      }

      var pomEditFields = {
        fields: editFields.toJS(),
        invitees: invitees,
        selectedOrderLines: selectedOrderLines,
        orderID: this.dataGet(prefix + "/customData/oID")
      }
      this.rest.post("set/" + module + "/" + id, pomEditFields, msg => {
        const data = {
          state: true,
          data: msg
        }

        this.popupHide(this.clearCalDetailStore.bind(null, params.pomWay))
        // prenacteni dat po pridani
        if (prefix.indexOf("calendar") !== -1) {
          this.calGetEvents(true, (data) => {
            // prevent early reload
          })
        }

        this.calPlanReload('external')

        this.clearCalDetailStore()

        if (callback != undefined) {
          callback(data)
        }
      })

      this.removeChangesFromLS(module, this.dataGet('conf/user/id'))
      this.unLoad()

    }
  }

}
