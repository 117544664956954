/**
 * Kdyz se zmeni typ slevy %/ABS je potreba prepocitat cenikovou slevu
 * @param way pr. "view/customData/productData/groups/0"
 * @param newValue
 * @param group
 */
export default function changeDiscountSelect(way, newValue, group){
    this.dsClear()
    group.lines.forEach((line, lineIndex) => {
        this.dsAdd("set", `${way}/lines/${lineIndex}/discount_select`, newValue);
        if(group.discount_field_select === "discount_amount"){
            let addVal;
            if (parseInt(newValue)) {
                let discount = (this.parseNumber(line.price_after_discount) / 100) * line.discount_amount;
                let tmpTotalPrice = this.parseNumber(line.price_after_discount) - discount;
                addVal = 100 * Math.abs((tmpTotalPrice - line.list_price) / line.list_price)
            } else {
                addVal = line.list_price - (line.price_after_discount - line.discount_amount)
            }
            this.dsAdd("set", `${way}/lines/${lineIndex}/price_after_add_discount`, addVal);
        } else {
            this.dsAdd("set", `${way}/lines/${lineIndex}/price_after_add_discount`, group.deal_tot);
        }
    });
    this.dsProcess()
}