export default function userCancelReplacement(data){
    console.log(data);
    this.confrim(this.translate("LBL_CONFIRM_CANCELING_REPLACEMENT"), () => {
        const params = {action: "user_cancel_replacement"};
        this.popupHide();
        var self = this;
        this.rest.post("customAction", params, function(resp) {
            if(resp.status){
                self.route();
            }
            else{
                self.error(resp.errorMessage.text);
            }
            
        });
    });
}
