import React, {PureComponent} from "react"
import sAction from "sAction";
import InputText from "ROOT/src/components/formElements/InputText";

export default class EditViewField extends PureComponent {
    render() {
        const data = this.props.data

        let customClass = data.def.get("customClass")
        if(!customClass){
            customClass = ""
        }

        return (
            <div className={`detailViwRow ${customClass}`} style={this.props.extraStyle}>
                <div>
                    <div className={data.selectForEdit ? "editChangeContainer select" : "editChangeContainer"}>
                        {data.selectForEdit ? (
                            <div
                                title={sAction.translate("LBL_MOVE_FIELD_SELECT")}
                                className="icon-editDetailFieldPosition editDetailFieldPosition"
                                onClick={() =>
                                    sAction.editFieldPosition(
                                        false,
                                        this.props.way,
                                        this.props.rowWay + "/" + this.props.index,
                                        this.props.prefix
                                    )
                                }
                            />
                        ) : (
                            <div
                                title={sAction.translate("LBL_MOVE_FIELD")}
                                className="icon-editDetailFieldPosition editDetailFieldPosition"
                                onClick={() =>
                                    sAction.editFieldPosition(
                                        true,
                                        this.props.way,
                                        this.props.rowWay + "/" + this.props.index,
                                        this.props.prefix
                                    )
                                }
                            />
                        )}
                        <InputText defaultValue={this.props.labelValue} key={this.props.vname} onChange={(e) => this.changeFieldLabel(e)}/>
                        <span>{"(" + data.def.get("name") + "|" + data.def.get("type") + ")"}</span>
                        <div title={sAction.translate("LBL_EDIT_FIELD")}
                             className="icon-EditField editDetailFieldPosition"
                             onClick={() => sAction.editFieldToEdit(this.props.prefix, this.props.module, data.def)}/>
                    </div>
                </div>
            </div>
        )
    }
}