export default function downloadFile(data) {
    if (!data.id) {
        console.error('Chybí id souboru/záznamu');
    // return; //vyjimka email
    }
    if (!data.module) {
        console.error('Chybí modul souboru/záznamu');
    // return; //vyjimka email
    }
    this.popup('fileDownload', {state: 'load'});

    this.filesXhr({
        action: 'downloadFileRequest',
        data: data,
        success: (ret) => {
            if (ret.status == true) {
                this.dsClear();
                const id = this.getPopupId();
                this.dsAdd('set', 'conf/popup'+id+'/data/state', 'init');
                this.dsAdd('set', 'conf/popup'+id+'/data/token', ret.token);
                this.dsProcess();
            } else {
                this.popupHide();
                this.error(this.translate(ret.message));
            }
        },
    });
}
