import React from "react";
import PureComponent from "../pure";
import ImportFileTable from "./ImportFileTable";
import ImportFileSelect from "./ImportFileSelect";
import ViewPanelMenu from "../viewPanelMenu/viewPanelMenu";
import ListViewTable2 from "../list/listViewTable2";
import sAction from "sAction";
import Button from "../formElements/Button";
import CheckBox from '../formElements/CheckBox';
import RadioGroup from '../formElements/RadioGroup';
import Select from '../formElements/Select';

export default class Import extends PureComponent {
    constructor(props) {
        super(props);

        this.delimiters = [
            {
                value: ",",
                label: <span className="importViewSelectorOptions">,</span>,
            },
            {
                value: ";",
                label: <span className="importViewSelectorOptions">;</span>,
            },
            {
                value: "\\.",
                label: <span className="importViewSelectorOptions">.</span>,
            }
        ];

        this.encodingOptions = [
            {
                value: "UTF-8",
                label: (
                    <span className="importViewSelectorOptions">
                        <small>UTF-8</small>
                    </span>
                ),
            },
            {
                value: "Windows-1250",
                label: (
                    <span className="importViewSelectorOptions">
                        <small>Windows-1250</small>
                    </span>
                ),
            },
            {
                value: "ISO-8859-2",
                label: (
                    <span className="importViewSelectorOptions">
                        <small>ISO 8859-2</small>
                    </span>
                ),
            }
        ];
    }

  componentDidMount() {
    if (sessionStorage.getItem("listImported") === "true") {
      return;
    }

    sAction.raiseFileUpload({ prefix: this.props.way });
  }

    getFieldsOptions(ignoreOption = false, returnRelates = true) {
        const fields = this.props.data.get('fields')?.get?.('fields')?.toJS?.();
        const relates = this.props.data.get('fields')?.get?.('relate')?.toJS?.();
        const mappedRelates = sAction.dataGet('view/mappedRelates');
        let fieldsArray = {
            [sAction.translate("LBL_REPORT_SEARCH_FIELDS")]: fields,
        };
        if (returnRelates) {
            fieldsArray[sAction.translate("LBL_REPORT_SEARCH_RELATE")] = relates;
        }

        let options = {};
        // const ignoredFields = [
        //     'id',
        // ];

        fieldsArray.forEachObject((fieldArray, key) => {
            options[key] = [];
            fieldArray?.sort?.((a, b) => {
                const aName = sAction.translate(a["vname"]);
                const bName = sAction.translate(b["vname"]);

                if (aName < bName) return -1;
                if (aName > bName) return 1;
                return 0;
            });

            if (ignoreOption === true) {
                options[key].push({
                    value: false,
                    label: "-- " + sAction.translate("LBL_IGNORE_FIELD") + " --",
                });
            }

            let i = 0;
            fieldArray?.forEach((field, index) => {
                // if ((ignoredFields.includes(field.name) || mappedRelates.includes(field.name) || mappedRelates.includes(field.id_name)) ||
                if ((mappedRelates.includes(field.name) || mappedRelates.includes(field.id_name)) ||
                    (field.type === 'link')) {
                    return;
                }
                options[key].push({
                    label: sAction.translate(field.vname, this.props.data.get('module')),
                    index: i++,
                    value: field.type === 'relate' ? field.id_name : field.name,
                    ...field,
                });
            });
        });

        return options
    };

  changeLineDelimiter = (delimiter) => {
    sAction.dsClear();
    sAction.dsAdd(
      "set",
      this.props.way + "/delimiters/lineDelimiter",
      delimiter
    );
    sAction.dsProcess();
    sAction.parseFile({ prefix: this.props.way });
  };

  changeColumnDelimiter = (delimiter) => {
    sAction.dsClear();
    sAction.dsAdd(
      "set",
      this.props.way + "/delimiters/columnDelimiter",
      delimiter
    );
    sAction.dsProcess();
    sAction.parseFile({ prefix: this.props.way });
  };

  changeEncoding = (encoding) => {
    sAction.dsClear();
    sAction.dsAdd("set", this.props.way + "/fileEncoding", encoding);
    sAction.dsProcess();
    sAction.readFile({ prefix: this.props.way, encoding: encoding, keepMapping: true });
  };

  addColumn = () => {
    const relates = this.props.data.get?.('mapping')?.get?.("additionalData");
    const index = relates.size;
    const way = this.props.way + "/mapping/additionalData/" + index;

    const module = this.props.data.get("module");
    const data = {
        options: this.getFieldsOptions(),
        module: module,
        way: way,
        prefix: this.props.way,
        index: index,
    };
    sAction.popup("MapAdditionalData", data);
  };

  modifyFirstRow = () => {
    let ignoreFirstRow = this.props.data.get('ignoreFirstRow') === 0 ? 1 : 0;
    let rows = this.props.data.get("import");
    sAction.dsClear();

    if (ignoreFirstRow) {
      rows = this.deleteFirstRow(rows);
    }  else {
      rows = this.addFirstRow(rows);
    }

    sAction.dsAdd('set', this.props.way + '/import', rows);
    sAction.dsAdd('set', this.props.way + '/ignoreFirstRow', ignoreFirstRow);
    sAction.dsProcess();
  }

  deleteFirstRow = (rows) => {
    sAction.dsAdd('set', this.props.way + '/firstRow', rows.get(0));
    return rows.delete(0);
  }

  addFirstRow = (rows) => {
    const firstRow = sAction.dataGet(this.props.way + '/firstRow');
    return rows.insert(0, firstRow);
  }

  mapFromFirstRow = () => {
    let useFirstAsMapping = this.props.data.get('useFirstAsMapping') === 0 ? 1 : 0;
    let rows = this.props.data.get("import");
    if (useFirstAsMapping) {
      sAction.dsClear();
      let first = rows.get(0);
      sAction.mapFromFirstRow(this.props.way + '/mapping/fileMapping', first, this.props.data.get('fields').get('fields').toJS(), this.props.data.get("module"));
      rows = this.deleteFirstRow(rows);
    } else {
      rows = this.addFirstRow(rows);
      sAction.dsAdd('set', this.props.way + '/mapping/fileMapping', {});
    }


    sAction.dsAdd('set', this.props.way + '/import', rows);
    sAction.dsAdd('set', this.props.way + '/useFirstAsMapping', useFirstAsMapping);
    sAction.dsProcess();
  }
    render() {
        const data = this.props.data;
        const prefix = this.props.way;
        // Chceme vypsat listview po importu
        if (data.get("listViewTree")) {
            return (
                <div className="pageContainer ListViewContainer">
                    {data.get("buttons") && <ViewPanelMenu data={data} prefix={prefix}/>}
                    <div className='ListViewContainerBody' id={'importView'}>
                        <div className="listViewheaderBox">
                            <div className="listViewheaderBoxContainer">
                                <div className="listViewActions">
                                    <div className="listViewLooksContainer ">
                                        <div className="listViewLooksLabel">
                                            {sAction.translate('LBL_IMPORT_IMPORTED_ROWS')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ListViewTable2
                            data={data}
                            parent={this}
                            forceRightPanel={true}
                            prefix={prefix}
                            key="importedList"
                        />
                    </div>
                </div>
            );
        }

        // Znamena, ze nemame nacteny soubor od uzivatele
        if (!data.get('import')) {
            return (
                <div className="importViewMainContainer">
                    {data.get("buttons") && <ViewPanelMenu data={data} prefix={prefix}/>}
                    <div className="importViewContainer" id={"importView"}/>
                </div>
            );
        }

        let mappedOptions = [{label: '', value: ''}];
        this.props.data?.get?.('mapping')?.get?.('fileMapping')?.forEach((value) => {
            if (value !== false) {
                mappedOptions.push(value.toJS?.());
            }
        });
        if (this.props.data.get('duplicates/field') && !mappedOptions.some((value) => {return value.value === this.props.data.get('duplicates/field');})) {
            sAction.dataSet(prefix + '/duplicates/field', '');
        }
        const radioOptions = {
            'ignore': sAction.translate('LBL_IMPORT_SKIP_DUPLICATES'),
            'overwrite': sAction.translate('LBL_IMPORT_OVERWRITE_DUPLICATES'),
        };

        return (
            <div className="importViewMainContainer">
                {data.get("buttons") && <ViewPanelMenu data={data} prefix={prefix}/>}

                <div className="importViewContainer" id={"importView"}>
                    <div className="importViewSelectorContainer" key="delimiterSelects">
                            <ImportFileSelect
                                options={this.delimiters}
                                label={sAction.translate("LBL_COLUMN_DELIMITER")}
                                key="columnDelimiter"
                                value={data.toJS()["delimiters"]["columnDelimiter"]}
                                onChange={this.changeColumnDelimiter}
                            />
                            <ImportFileSelect
                                options={this.encodingOptions}
                                label={sAction.translate("LBL_FILE_ENCODING")}
                                key="fileEncoding"
                                value={data.toJS()["fileEncoding"]}
                                onChange={this.changeEncoding}
                            />
                        </div>
                    <div className="importViewSelectorContainer" key="checkboxSelects">
                        <div className="ImportViewCheckbox"><CheckBox
                            defaultValue={this.props.data.get('ignoreFirstRow')}
                            readonly={this.props.data.get('useFirstAsMapping') === 1} onChange={this.modifyFirstRow}/>
                            {sAction.translate('LBL_IMPORT_IGNORE_FIRST_ROW')}
                        </div>
                        <div className="ImportViewCheckbox"><CheckBox
                            defaultValue={this.props.data.get('useFirstAsMapping')}
                            readonly={this.props.data.get('ignoreFirstRow') === 1} onChange={this.mapFromFirstRow}/>
                            {sAction.translate('LBL_IMPORT_MAP_BY_FIRST_ROW')}
                        </div>
                        <div className="importFlr">
                            <Button className={"stillHover"} onClick={this.addColumn}>
                                <span className={"icon icon-addIcon"}/>
                                {sAction.translate('LBL_IMPORT_ADD_COLUMN')}
                            </Button>
                        </div>
                    </div>
                    <div className='importViewSelectorContainer duplicatesRow'>
                        <ImportFileSelect
                            label={sAction.translate('LBL_IMPORT_FIND_DUPLICATES_BY')}
                            options={mappedOptions}
                            ref='input'
                            open={false}
                            onChange={element => sAction.dataSet(prefix + '/duplicates/field', element?.target?.value)}
                        />
                        <RadioGroup
                            value={data.value}
                            options={radioOptions}
                            style={{flexDirection: 'row'}}
                            onChange={element => sAction.dataSet(prefix + '/duplicates/action', element.target.value)}
                            defaultValue='ignore'
                        />
                    </div>

                    <ImportFileTable
                        way={prefix}
                        data={data}
                        key="mapping"
                        options={this.getFieldsOptions(true)}
                    />
                </div>
            </div>
        );
    }
}
