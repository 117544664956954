export default function editFieldSaveEnum(params){
    this.load()
    this.rest.post("saveEnum", params, ret => {
        this.unLoad()
        if(ret.status){
            
            this.app_strings[params["enum"]] = params["options"]
            this.temporaryData.optionsList.push({ label: params["enum"], value: params["enum"] })
            if(params.forceClose === true){
                this.popupHide()
            }else{
                this.dataSet("conf/popup/data/view","newField")
            }
            
        }
    })
}
