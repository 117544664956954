import detailDefault from '../../detailDefault';
export default class detailCurrencies extends detailDefault {
    load(sAction, data) {
        sAction.dataSet(`${data.prefix}/fields/status/def/customOptions`, sAction.getEnumForSelect('currency_status_dom').map((el) => {
            if (el.value === 'System') {
                el.type = 'header';
            }
            return el;
        }));
        if (sAction.dataGet(`${data.prefix}/fields/id/value`) === '-99') {
            sAction.dsClear();
            sAction.dsAdd('set', `${data.prefix}/fields/name/def/readonly`, true);
            sAction.dsAdd('set', `${data.prefix}/fields/symbol/def/readonly`, true);
            sAction.dsAdd('set', `${data.prefix}/fields/iso4217/def/readonly`, true);
            sAction.dsAdd('set', `${data.prefix}/fields/conversion_rate/def/readonly`, true);
            sAction.dsAdd('set', `${data.prefix}/fields/status/def/readonly`, true);
            sAction.dsAdd('set', `${data.prefix}/fields/status/value`, 'System');
            sAction.dsProcess();
        }
    }
    update(sAction, data) {
        if (data.field === 'status' && data.value === 'Inactive') {
            sAction.popup('InactiveCurPopup', null, () => {
                sAction.popupHide();
            });
        }
    }
}
