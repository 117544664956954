export default function quoteNewVersion(attrs){
    this.load();
    const params = {action: "quote_new_version", record : attrs.id, type: attrs.type};
    //var self = this;

    this.rest.post("customAction", params, (resp) => {
        if(resp.status){
            this.href("#detail/Quotes/"+resp.message.data.record);
        }
        else{
            this.unLoad();
            this.alert(resp.errorMessage.text);
        }
    });
    
   
}
