import React from "react";
import PureComponent from "../pure";

import DropdownAnimation from '../animation/DropdownAnimation';

import sAction from "sAction";

export default class viewPanelMenuOthers extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };
  }
  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    const target = e.target;
    if(!target.classList.contains("actionButtonOthersButton")){
      this.setState({open:false})
    }
  }
  render() {
    const buttons = this.props.buttons;
    const buttonLimit = this.props.buttonLimit;

    var contentClass = "actionButtonOthersContent";
    if(buttonLimit > -1){
        contentClass += " right";
    }

    return (
      <div className="actionButtonOtherContainer" onClick={(e) => this.handleClickOutside}>
        <div
          className={this.state.open ? "icon-More actionButtonOthersButton open" : "icon-More actionButtonOthersButton"}
          onClick={() => this.setState({ open: !this.state.open })}
        />
        <DropdownAnimation className={contentClass} open={this.state.open && buttons.length !== 0}>
          <div className='actionButtonOthersPadding'>
            {buttons}
          </div>
        </DropdownAnimation>
      </div>
    );
  }
}
