import detailDefault from "../../detailDefault";

export default class detailDocuments extends detailDefault {
    load(sAction, data) {
        let customData = sAction.dataGet("view/customData").toJS();
        sAction.dataSet("view/customData", customData, "EmailDetail")
    }

    update(sAction, data) {

    }

}
