export default {
    fields: [
        //Poctem se nasobi celkova cena
        {
            name: "quantity",
            type: "number",
            lbl: "LBL_LIST_QUANTITY",
            lblMod : "Quotes",
            visible: true,
            disabled: false,
            width: 6
        },
        {
            name: "name",
            type: "relateProd",
            lbl: "LBL_LIST_PRODUCT_NAME",
            lblMod : "Quotes",
            visible: true,
            disabled: false,
            width: 26
        },
        //Kod
        {
            name: "mft_part_num",
            type: "text",
            lbl: "LBL_LIST_LBL_MFT_PART_NUM",
            lblMod : "Products",
            visible: true,
            disabled: false,
            width: 10
        },
        //merna jednotka
        {
            name: "mj",
            type: "mj_select",
            lbl: "LBL_LIST_LBL_MJ",
            lblMod : "Products",
            visible: true,
            disabled: false,
            width: 8
        },
        //Nakladova cena nepocita se s ni zadna dalsi cena
        {
            name: "cost_price",
            type: "price",
            lbl: "LBL_LIST_LBL_PRICE_MJ",
            lblMod : "Products",
            visible: true,
            disabled: true,
            width: 12
        },
        //Cenikova cena bere se z katalogu produktu
        {
            name: "list_price",
            type: "price",
            lbl: "LBL_LIST_LIST_PRICE",
            lblMod : "Quotes",
            visible: true,
            disabled: true,
            width: 12
        },
        //Cenikova sleva Bere se z ceniku pripadne se k ni pripocita dodatecna sleva
        {
            name: "price_after_add_discount",
            type: "price",
            lbl: "LBL_PRICE_AFTER_ADD_DISCOUNT",
            lblMod : "Quotes",
            visible: true,
            disabled: false,
            width: 12,
        },
        //Cena z ceniku bere se z ceniku nejde menit, pokud NSD je zaskrtle nebo ne aplikuje na ni firemni sleva
        {
            name: "price_after_discount",
            type: "price",
            lbl: "LBL_PRICE_AFTER_DISCOUNT",
            lblMod : "Quotes",
            visible: true,
            disabled: true,
            showPricingMap: true,
            width: 12
        },
        //Dodatecna sleva aplikuje se na cenu z ceniku
        {
            name: "discount_amount",
            type: "price",
            lbl: "LBL_LIST_DEAL_TOT",
            lblMod : "Quotes",
            visible: true,
            disabled: false,
            width: 12
        },
        {
            name: "discount_select",
            type: "discount_select",
            lbl: "",
            lblMod : "Products",
            visible: true,
            disabled: false,
            width: 6,
        },
        {
            name: "margin_check",
            type: "semaphore",
            lbl: "LBL_LIST_LBL_MARGIN_CHECK",
            lblMod : "Products",
            visible: true,
            disabled: true,
            width: 4
        },
        //Zlevnena cena za MJ nasobi se price_mj2 nebo price_mj dle vyberu
        {
            name: "discount_price",
            type: "price",
            lbl: "LBL_LIST_DISCOUNT_PRICE",
            lblMod : "Quotes",
            visible: true,
            disabled: true,
            width: 12,
            competence: true
        },
        //Celkem
        {
            name: "total_price",
            type: "price",
            lbl: "LBL_LIST_TOTAL_PRICE",
            lblMod : "Quotes",
            visible: true,
            disabled: true,
            width: 12
        },
        //Nepouziva se
        {
            name: "opce_c",
            type: "checkbox",
            lbl: "LBL_LIST_OPCE",
            lblMod : "Quotes",
            visible: false,
            disabled: false,
            width: 5
        },
    ],
    sums : [
        {
            type: "addBtn",
            visible: true,
            width: "41%",
        },
        {
            type: "space",
            visible: true,
            width: "20px",
        },
        {
            name: "total_cost_price",
            type: "price",
            lbl: "LBL_TOTAL_COST_PRICE",
            lblMod: "Quotes",
            visible: true,
            width: "9%"
        },
        {
            name: "total_list_price",
            type: "price",
            lbl: "LBL_SUBTOTAL",
            lblMod: "Quotes",
            visible: true,
            width: "8%"
        },
        {
            name: "total_price_after_add_discount",
            type: "price",
            lbl: "LBL_TOTAL_PRICE_AFTER_ADD_DISCOUNT",
            lblMod: "Quotes",
            visible: true,
            width: "9%"
        },
        {
            name: "total_price_after_discount",
            type: "price",
            lbl: "LBL_LIST_TOTAL_PRICE_AFTER_DISCOUNT",
            lblMod: "Quotes",
            visible: true,
            width: "9%"
        },
        {
            type: "space",
            visible: true,
            width: "15%",
        },
        {
            name: "total_discount_price",
            type: "price",
            lbl: "LBL_NEW_SUB",
            lblMod: "Quotes",
            visible: true,
            width: "10%"
        }
    ]
};
