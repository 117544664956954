import moment from "moment";

export default function clickToCall(prefix,value){

    const data = this.dataGet(prefix)


    const params = {
        value:value,
        module: data.get("module"),
        record: data.get("id"),
    }
    this.load();
    this.rest.post("clickToCall",params,ret => {
        if(ret.state){
            this.unLoad();
            if(params.module == "Calls"){
                this.dsClear();
                const today = moment().format("YYYY-MM-DD HH:mm:ss");
                this.dsAdd("set", prefix+"/fields/status/value", "Held");
                this.dsAdd("set", prefix+"/changes/fields/status", "Held");
                this.dsAdd("set", prefix+"/fields/date_start/value", today);
                this.dsAdd("set", prefix+"/changes/fields/date_start", today);
                this.dsProcess();
                //this.route();
            }
            else{
                this.popupDetail({module:"Calls",record:ret.call_id,saveCallback: data => {
                    this.route();
                }})
            }
            
        }else{
            this.unLoad();
            this.alert(this.translate(ret.message))
        }
    })
}