import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PureComponent from '../pure';
import Text from '../formElements/InputText';
import sAction from 'sAction';
import Loader from '../loader';
import ListViewSearchHistory from './ListViewSearchHistory';

class ListViewSearch extends PureComponent {
    constructor(props) {
        super(props);
        this.input = React.createRef();
        this.inputText = React.createRef();
        this.state = {
            inputVal: '',
            searchedInputVal: '',

            historyOpen: false,
            resultOpen: false,
            loaded: false,
            searchContextCnt: 0,
        };
        this.moreOptions = {};
        const prefix = this.props.prefix;
        // kdyz je popup listview -> promazat ulozene hledani
        if (prefix === 'conf/popup/data/listView' || prefix === 'rightPanel/data/listView') {
            sAction.clearSearchContext(this.props.module, this.props.prefix);
        }
        this.isPopup = prefix.split('/')[1] === 'popup';

        // pass subpanel moreOptions fo listview
        const moreOptions = sAction.dataGet(prefix + '/moreOptions');
        if (moreOptions) {
            this.moreOptions = moreOptions.toJS();
        }

        // if its popup from relate Quotes disable option create new
        // because it is not possible to fill custom lines in popup
        if (this.isPopup && this.props?.module === 'Quotes') {
            this.moreOptions.create_new = false;
        }
    }

    search() {
        this.setState({searchedInputVal: this.input.current.value});
        if (sAction.dataGet(`${this.props.prefix}/selected`).size !== 0 &&
            sAction.dataGet('conf/popup/filePath') !== 'listview/filterChange.js') {
            sAction.dynPopup('listview/filterChange.js', {prefix: this.props.prefix});
        }

        if (this.input.current.value && this.input.current.value.trim() !== '') {
            sAction.addSearchContext(this.input.current.value.trim(), this.props.module, this.props.prefix);
        }

        const searchData = this.searchContext();
        sAction.listGlobalSearch(searchData, this.props.prefix, () => {
            this.onInputChange('');
        });
        this.onInputChange('');
    }

    searchContext() {
        const prefix = this.props.prefix;
        const module = sAction.dataGet(prefix + '/modul');
        const view = this.props.prefix.split('/').slice(-1)[0];
        const searchContext = sAction.getStorage('searchContext');
        let searchContextArr = [];

        if (searchContext && searchContext[`${module}_${view}`]) {
            searchContextArr = searchContext[`${module}_${view}`].searchData;
        }

        return searchContextArr;
    }

    dropFromContextSearch(index) {
        const prefix = this.props.prefix;
        const module = sAction.dataGet(prefix + '/modul');
        const view = this.props.prefix.split('/').slice(-1)[0];
        const searchContext = sAction.getStorage('searchContext');

        const contextArr = [];
        searchContext[`${module}_${view}`].searchData.forEach((item) => {
            if (item.key !== index) contextArr.push(item);
        });

        if (searchContext[`${module}_${view}`]) {
            searchContext[`${module}_${view}`] = {
                searchData: contextArr,
            };
        }
        sAction.setStorage('searchContext', searchContext);
        this.search();
        this.setState({inputVal: '', searchedInputVal: ''});

        if (this.state.loaded) this.forceUpdate();
    }

    newRecord() {
        const params = {
            module: this.props.module,
            way: this.props.prefix,
        };
        sAction.detailNewRecord(params);
    }

    componentDidMount() {
        this.setState({loaded: true});
        this.setDefaultValue();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.module !== prevProps.module || this.props.data !== prevProps.data) {
            this.setDefaultValue();
        }
    }

    clearInputValue = () => {
        this.setState({inputVal: ''});
    };

    setDefaultValue = () => {
        const defaultValue = '';
        // const data = this.props.data;

        // this will renew search after reload dont use now
        // if (data.toJS().length > 0) {
        //     data.forEach((filter, index) => {
        //         if (filter.field === "*" && filter.filters) {
        //             defaultValue = filter.filters.toJS()[0].value;
        //         }
        //     });
        // }

        this.setState({inputVal: defaultValue, searchedInputVal: defaultValue});
    };

    onInputChange = (e) => {
        this.setState({inputVal: e});
    };

    onClearButton = () => {
        this.clearInputValue();
        sAction.clearSearchContext(this.props.module, this.props.prefix);
        this.search();
    };

    getSearchContext() {
        const searchContext = this.searchContext();
        if (!searchContext || searchContext.length === 0) {
            this.setState({...this.state, resultOpen: false, searchContextCnt: 0});
            return null;
        }
        this.setState({...this.state, resultOpen: true, searchContextCnt: searchContext.length});
        if (this.state.loaded) {
            const resultList = [];
            const searchContextReverse = searchContext.reverse();

            searchContextReverse.forEach((item, i) => {
                const lineClass = 'searchContextResultLine';
                resultList.push(
                    <div tabIndex={i} key={i} className={lineClass} >
                        {sAction.decodeHTMLEntities(item.val)}
                        <span
                            className="icon-closeIconLight clearButton"
                            onClick={() => this.dropFromContextSearch(item.key)}
                        />
                    </div>,
                );
            });

            return <div className="searchContextResult" >{resultList}</div>;
        }
        return (
            <div className="searchContextResult">
                <div style={{height: '70%', top: '25px'}} className="searchContextNoRecords">
                    {<Loader />}
                </div>
            </div>
        );
    }

    toggleSearchHistory() {
        if (this.state.historyOpen) {
            this.setState({historyOpen: false});
        } else {
            const prefix = this.props.prefix;
            const module = sAction.dataGet(prefix + '/modul');
            this.setState({historyOpen: true, loaded: false});
            sAction.loadSearchHistory(module, 20, false, () => {
                this.setState({loaded: true});
            });
        }
    }

    /**
     * Decide, if button for adding new record should be displayed
     *
     * @return {JSX.Element|null}
     */
    returnAddButton = () => {
        // must be create_new === false, could be empty value, which doesn't forbids displaying button
        if (this.moreOptions?.create_new === false || sAction.hasAccess(this.props.module, 'newRecord')) {
            return null;
        }

        return (
            <div className='listViewSearchButtonAdd' onClick={() => this.newRecord()}>
                <div className='icon-addIcon'/>
            </div>
        );
    };

    render() {
        const searchResult = this.getSearchContext();
        const addButton = this.returnAddButton();
        return (
            <div className="flexInLine flexVerticalCenter">
                <div className="listViewSearchWrapper">
                    <div className={'listViewSearch' + (this.state.resultOpen || this.state.historyOpen ? ' shadow' : '')}>
                        <div>
                            <div
                                onClick={() => this.toggleSearchHistory()}
                                className="icon-history listViewSearchHistoryIcon"
                            />
                        </div>
                        <div>
                            <div
                                onClick={() => this.search()}
                                className="icon-search listViewSearchIcon"
                            />
                            {this.state.resultOpen &&
                            <div className="activeBadge" onClick={() => this.search()}><p>{this.state.searchContextCnt}</p></div>}
                        </div>
                        <Text
                            myRef={this.input}
                            onKeyUp={(event) => {
                                if (event.keyCode === 13) this.search();
                                if (event.keyCode === 27) this.onClearButton(event);
                            }}
                            placeholder={sAction.translate('LBL_LIST_SEARCH_GLOBAL_DESC')}
                            autoFocus={true}
                            clearButton={true}
                            onChange={(e) => {
                                this.onInputChange(e.target.value);
                            }}
                            value={this.state.inputVal}
                            onClearButton={this.onClearButton}
                        />
                        <div className="listViewSearchButton" onClick={() => this.search()}>
                            {sAction.translate('LBL_LIST_SEARCH_GLOBAL')}
                        </div>
                    </div>
                    {searchResult}
                    {this.state.historyOpen &&
                        <ListViewSearchHistory
                            module={this.props.module}
                            prefix={this.props.prefix}
                            search={this.search}
                            close={() => this.setState({historyOpen: false})}
                        />}
                </div>
                {addButton}
            </div>
        );
    }
}

ListViewSearch.propTypes = {
    data: ImmutablePropTypes.list,
    module: PropTypes.string,
    prefix: PropTypes.string,
};

export default ListViewSearch;
