

export default function loadTranslate(complete){

  // Pravdepodobne nefunguje, ale nikde se to stejne nepouziva
  // Kdybyste to nekdo chtel pouzit, overte, ze to funguje. Dle myho to nahradi cely preklady jednim modulem,
  // ale netestoval jsem to
  var module = this.getModuleName();
  var self = this;
  this.rest.get("trns/"+module,function(data){

    self.moduleTranslate = data;
    self.rest.get("trns",function(data){

      self.translate = data;
      complete();
    });
  });


}
