import React from 'react';
import sAction from 'sAction';

import PureComponent from '../../pure';
import DetailApiButtons from '../../detailViewFields/Api/DetailApiButtons';
import DetailViewSpanValue from '../DetailViewSpanValue';

export default class DetailViewField extends PureComponent {
    show(e, data) {
        e.stopPropagation();
        const prefix = this.props.prefix;
        const type = sAction.dataGet(prefix + '/type');
        if (type !== 'rightPanel') {
            sAction.rightPanelDetail(data.module, data.id);
        }
    }

    showInfo(selector, help, header) {
        sAction.popper({
            selector: selector,
            content: help,
            header: header,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            },
            transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
            },
        });
    }
    changeFieldLabel(e) {
        const data = this.props.data;
        const module = this.props.module;
        const prefix = this.props.prefix;
        const def = data.get('def');
        const vname = def.get('vname');
        const value = e.target.value;

        sAction.moduleTranslate[module][vname] = value;

        sAction.dataSet(prefix+'/editedFieldsVname/'+vname, value);
    }

    render() {
        const data = this.props.data;
        if (!data || !data.def) { // zamezi padu app
            console.error('DetailViewField.js - !data.def');
            return null;
        }
        const module = this.props.module;
        const readonly = this.props.readonly;
        const labelValue = this.props.labelValue;
        const id = this.props.id;
        const newRecord = false;

        let errorMsg = null;
        if (data.def.get('isInvalid')) {
            errorMsg = (
                <span className="errorMsg">
                    {sAction.translate('LBL_REQUIRED_FIELD_EMPTY', 'Calendar')}
                </span>
            );
        }

        // nezobrazuje skryte fieldy
        if (data.def.get('visible') === false) {
            return '';
        }

        let value;
        let type = data.def.get('type');
        if (type == undefined) {
            type = 'varchar';
        }
        let fieldType = type.charAt(0).toUpperCase() + type.slice(1);

        let showDetailApi = data.def.get('showDetailApi');
        if (showDetailApi == undefined && type == 'phone') {
            showDetailApi = 'phoneApi';
        }
        let showApiButtons = showDetailApi && showDetailApi.indexOf('Api') !== -1;
        if (this.props.acl && this.props.acl.get('edit') == false) {
            showApiButtons = false;
        }

        if (data.actEdit === false && newRecord == false) {
            value = (
                <DetailViewSpanValue
                    prefix={this.props.prefix}
                    way={this.props.way}
                    rowWay={this.props.rowWay}
                    index={this.props.index}
                    edit={() =>
                        sAction.fieldToEdit({
                            way: this.props.way,
                            name: this.props.data.name,
                            prefix: this.props.prefix,
                            type: this.props.data.def.get('type'),
                        })
                    }
                    show={(e, data) => this.show(e, data)}
                    data={data}
                    fieldType={fieldType}
                    newRecord={newRecord}
                    boolField={sAction.fields['Bool']}
                    readonly={readonly}
                    acl={this.props.acl}
                    attemptedSave={this.props.attemptedSave}
                    module={module}
                />
            );
            if (fieldType === 'Files' || fieldType === 'RichText' || fieldType === 'HTMLText') {
                return value;
            }
        } else {
            if (showApiButtons) {
                if (showDetailApi !== 'ApiStreet' && showDetailApi !== 'phoneApi') {
                    fieldType = showDetailApi;
                }
            }
            let F = sAction.fields[fieldType];
            if (!F) {
                console.log('Field \'' + fieldType + '\' se nenašel');
                F = sAction.fields['Varchar'];
            }
            value = (
                <F
                    prefix={this.props.prefix}
                    way={this.props.way}
                    data={data}
                    newRecord={newRecord}
                    rowWay={this.props.rowWay}
                    index={this.props.index}
                    module={this.props.module}
                />
            );
        }

        let customClass = data.def.get('customClass');
        if (!customClass) {
            customClass = '';
        }

        const help = data.def.get('help') ?? false;

        return data.def.get('displayIfNew') === false && id == null ? null : (
            <div className={`detailViwRow ${customClass}`} style={this.props.extraStyle}>
                <div>
                    <div className="DetailviewLabel">
                        <label dangerouslySetInnerHTML={{__html: labelValue}} />
                        {data.def.get('required') && <span className="required">* </span>}
                        {help && (<div id={data.name + '_help'}
                            className="icon-info detailviewInfoIcon"
                            onClick={(e) => this.showInfo('#' + data.name + '_help', help, labelValue)}/>
                        )}
                    </div>
                    <div className={data.edit ? 'DetailViewValue pointerClass' : 'DetailViewValue'}>
                        {showApiButtons ? (
                            <div className="detailApi__field">
                                {value}
                                {errorMsg}
                                <DetailApiButtons
                                    type={showDetailApi}
                                    prefix={this.props.prefix}
                                    name={data.name}
                                    value={data.value}
                                />
                            </div>
                        ) : (
                            <>
                                {value}
                                {errorMsg}
                            </>
                        )}
                        <input type="hidden" id={data.name + '_hidden'} value={data.value == null ? '' : data.value}/>
                    </div>
                </div>
            </div>
        );
    }
}
