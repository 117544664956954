export default function closeRightPanel(){
    const way = this.dataGet('rightPanel/data/reloadList');
    const reloadOnClose = this.dataGet("rightPanel/reloadOnClose");

    if(way && reloadOnClose){
        let offset = this.dataGet(way)?.offset || 0;
        let page = this.dataGet(way)?.page || 1;

        this.reloadList(offset, page, way)
    } else if (!way && reloadOnClose){
        if (this.dataGet('view/mode') !== 'detail') {
            this.route()
        } else {
            this.unLoad();
        }
    }

    this.dataSet("rightPanel", {}, "RightPanel");
}
