import React, {useRef, useEffect} from 'react';
import {useSpring, animated} from 'react-spring';
import PropTypes from 'prop-types';

const DropdownAnimation = (props) => {
    const ref = useRef(null);
    const [style, animate] = useSpring(() => ({height: `${props.startingHeight}px`}), []);

    const open = props.open;

    useEffect(() => {
        animate({
            height: (open ? ref.current.offsetHeight : 0) + 'px',
        });
    }, [style, open]);

    return (
        <animated.div className={props.className} style={{overflow: 'hidden', ...style}}>
            <div style={{width: '100%'}} ref={ref}>
                {props.children}
            </div>
        </animated.div>
    );
};

DropdownAnimation.defaultProps = {
    startingHeight: 0,
    className: '',
};

DropdownAnimation.propTypes = {
    open: PropTypes.bool.isRequired,
    startingHeight: PropTypes.number,
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default DropdownAnimation;
