
export default function listGlobalSearch(searchData, prefix, callback = null) {
    console.log('listGlobalSearch', searchData, prefix);
    let actFilter = null;
    if (searchData && searchData.length !== 0) {
        actFilter = {
            'field': '*',
            'operator': 'and',
            'operands': [],
        };
    }

    searchData.forEach((val) => {
        if (val?.val != '' && val?.val != null && val?.val != undefined) {
            actFilter.operands.push({
                'field': '*',
                'type': 'cont',
                'value': val.val,
            });
        }
    });

    const akce = {
        type: 'SETFILTER',
        listViewPrefix: prefix,
        filter: actFilter,
    };
    this.dataSetCustom(akce);
    this.dsClear();
    this.dsAdd('set', prefix + '/actFiltering', false);
    this.dsProcess();

    this.reloadList(0, 1, prefix);

    if (callback !== null) {
        callback();
    }
}
