import React, {PureComponent} from "react"
import DetailViewField from "ROOT/src/components/detailView/DetailViewFieldsManager/DetailViewField";
import EditViewField from "ROOT/src/components/detailView/DetailViewFieldsManager/EditViewField";
import ReportViewField from "ROOT/src/components/detailView/DetailViewFieldsManager/ReportViewField";
import sAction from "sAction";

export default class DetailViewFieldsManager extends PureComponent {
    constructor(props) {
        super(props);
    }

    getLabelValue() {
        let labelValue = sAction.translate(this.props.data.def.get("vname"), this.props.module);
        if (sAction.getStorage("debug")) {
            //nazev fieldu
            const id_name = this.props.data.def.get("id_name");
            if (id_name) {
                //pokud je relace, zobrazi se nazev vazby
                labelValue += " <small style='color: orange'>" + id_name + "</small> ";
            } else {
                labelValue += " <small style='color: gold'>" + this.props.data.name + "</small> ";
            }

            //nazev pripadneho ciselniku
            const options = this.props.data.def.get("options") || this.props.data.def.get("ext1");
            if (options) {
                labelValue +=
                    " <small style='color: lightblue'>" + options + "</small> ";
            }
        }
        return labelValue;
    }

    switchRenderFields(mode, extraProps) {
        switch (mode) {
            case "detail":
                return <DetailViewField {...this.props} {...extraProps} />
            case "edit":
                return <EditViewField {...this.props} {...extraProps} />
            case "report":
                return <ReportViewField {...this.props} {...extraProps} />
            default:
                return <DetailViewField {...this.props} {...extraProps} />
        }
    }

    render() {
        const data = this.props.data
        const labelValue = this.getLabelValue()
        const extraStyle = {width: `${this.props.percent}%`};

        return (this.switchRenderFields(this.props.mode, {extraStyle, data, labelValue}))
    }
}