import React from "react";
import PropTypes from 'prop-types';


/**
 *  Props:
 *  direction={"bottom"} nebo top/left/right, default je top
 *  content={"obsah tooltip okna"}
 *  customStyle={{backgroundColor: "red"}}, default barva je RED
 */
export default function CustomTooltip(props) {
    let direction = props.direction || "top";
    let extraClass = props.extraClass || "";
    let directionBorderColor = `border${direction.charAt(0).toUpperCase() + direction.slice(1)}Color`;
    let customStyle = props.customStyle ?? {};
    customStyle.backgroundColor = customStyle.backgroundColor || "red"; // defaultni barva, pokud se neposlou custom styly
    customStyle[directionBorderColor] = customStyle.backgroundColor;    //nastavujeme border color, aby sipka mohla zdedit barvu a mela tak stejnou barvu jako tooltip okno

    return (
        <div className={`Tooltip-Wrapper ${extraClass}`}>
            {props.children}
                <div className={`Tooltip-Tip ${direction}`} style={customStyle} >
                    {props.content}
                    {props.onClose && (
                        <div className="icon-Close" onClick={props.onClose} style={{pointerEvents: "all"}}>

                        </div>
                    )}
                </div>
        </div>
    );
}

CustomTooltip.propTypes = {
    direction: PropTypes.string,
    colorBackground: PropTypes.string,
    backgroundColor: PropTypes.string,
    content: PropTypes.string,
    extraClass: PropTypes.string,
    onClose: PropTypes.func,
}

