import React, { Component } from "react";

import InputField from "../inputFieldClass";


export default class Text extends InputField {
  constructor() {
    super();

    this.input = React.createRef();
  }
  componentDidMount(){
    const newRecord = this.props.newRecord;
    if (!newRecord) {
      let el = this.input.current;
      if (!el) {
        return;
      }
      // posunuti kurzoru na konec
      let elemLen = el.value.length;
      el.selectionStart = elemLen;
      el.selectionEnd = elemLen;
      el.focus();
    }
  }

  setHeight = (event) => {
    event.target.style.height = "auto";
    event.target.style.height = (event.target.scrollHeight) +'px';
  }
  render() {
    const data = this.props.data;
    const newRecord = this.props.newRecord;
    const ok = this.state.state;
    var inputClass = "form-control form-control-sm";
    var containerClass = "textareaEditContainer";
    if (ok === false) {
      inputClass += " wrongInput";
    }
    var value = data.value;
    if (newRecord && (value == "" || value == undefined)) {
      value = "";
    }
    return (
      <div className={containerClass}>
        <div className="inputContainer">
          <textarea
            onKeyUp={event => event.keyCode == 27 && this.cancel()}
            onChange={this.setHeight}
            onFocus={this.setHeight}
            ref={this.input}
            onBlur={() => this.save()}
            onKeyDown={(e) => this.onKeyDown(e)}
            className={inputClass}
            id={data.name}
            defaultValue={value}
            rows={(!value) ? 2 : undefined}
          />
        </div>
      </div>
    );
  }
}
